import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../constant/constant";
import Loader from "../../library/common/Loader";
import SequenceSamplingLog from "../component/sequenceSamplingLogs";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import { useLocation } from "react-router-dom";

const SequenceSamplingLogContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const projectNumber = location?.state?.projectNumber;
    const projectName = location?.state?.projectName;
    const clientName = location?.state?.clientname;
    const projectId = location?.state?.id;
    const samplingLogData = useSelector(
        (state) => state.sequenceLandingSamplingLogs
    );

    const getSequenceSamplingLogs = (data) => {
        dispatch(sequenceLandingAction.getSequenceLandingSamplingLogs(data));
    };
    // const projectId = location?.state?.id;

    useEffect(() => {
        const data = { projectId: projectId, page: 1, pageSize: PAGE_SIZE };
        getSequenceSamplingLogs(data);
    }, []);

    return (
        <>
            {samplingLogData?.isLoading && <Loader />}
            <SequenceSamplingLog
                samplingLogData={samplingLogData}
                getSequenceSamplingLogs={getSequenceSamplingLogs}
                projectId={projectId}
                clientName={clientName}
                projectName={projectName}
                projectNumber={projectNumber}
            />
        </>
    );
};

export default SequenceSamplingLogContainer;
