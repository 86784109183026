import React, { useEffect, useState } from "react";
import SequenceInspectionView from "../component/sequenceInspectionView";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import { useSelector } from "react-redux";
import { API_STATUS } from "../../constant/constant";
import Loader from "../../library/common/Loader";

function SequenceInspectionViewContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [createdOnDate, setCreatedOnDate] = useState();
  const inspectionTypeId = location?.state?.inspectionData?.inspectionTypeId;
  const projectId = location?.state?.inspectionData?.projectId;
  const inspectionReportId = location?.state?.inspectionData?.reportId;

  const qrInspectionReportDetailsData = useSelector(
    (state) => state.qrSequenceLandingInspectionDetailsData
  );

  const getInTyFeByInTyList = useSelector(
    (state) => state.qrSequenceLandingInspectionTypeFieldData
  );
  const getInTyFeByInTyListData = (data) => {
    dispatch(sequenceLandingAction.getSequenceLandingInspectionFieldType(data));
  };

  const qrInspectionReportDetails = (data) => {
    dispatch(sequenceLandingAction.getSequenceLandingInspectionDetails(data));
  };

  const showLoader = useSelector(
    (state) =>
      state.qrSequenceLandingInspectionDetailsData?.isLoading ||
      state.qrSequenceLandingInspectionTypeFieldData?.isLoading
  );

  useEffect(() => {
    if (inspectionReportId) {
      const data = {
        InspectionId: inspectionReportId,
      };
      qrInspectionReportDetails(data);
    }
  }, [inspectionReportId]);

  useEffect(() => {
    if (qrInspectionReportDetailsData?.status === API_STATUS?.SUCCESS) {
      const data = {
        InspectionTypeId: inspectionTypeId,
        projectId: projectId,
      };
      getInTyFeByInTyListData(data);

      setCreatedOnDate(qrInspectionReportDetailsData?.data?.createdOn);
    }
  }, [qrInspectionReportDetailsData]);

  return (
    <>
      {showLoader && <Loader />}

      <SequenceInspectionView
        inspectionDetail={showLoader ? [] : qrInspectionReportDetailsData?.data}
        getInTyFeByInTyList={
          getInTyFeByInTyList?.data?.listInspectionTypeFields
        }
        inspectionId={inspectionReportId}
        createdOnDate={createdOnDate}
      />
    </>
  );
}

export default SequenceInspectionViewContainer;
