import React from "react";
import "./seqHeader.scss";
import { Grid } from "@mui/material";
import { SequenceLogos } from "../../assets";
const SeqHeader = ({ clientName, ProjectName, ProjectNumber }) => {
  return (
    <div className="overrideHeader1">
      <Grid container className="seq_header_main" spacing={1}>
        <Grid item className="logo_img" lg={3} xs={12} md={4}>
          <img
            src={SequenceLogos?.SequenceLogo}
            className="logoResponsive"
            alt="no logo"
          />
        </Grid>
        <Grid item className="header_info" lg={3} xs={12} md={4}>
          <div className="headerclient">
            <div className="qrtitle">Client Name:</div>
            <div>{clientName}</div>
          </div>
        </Grid>
        <Grid item className="header_info" lg={3} xs={12} md={4}>
          <div className="headerclient">
            <div className="qrtitle">Project Name:</div>
            <div>{ProjectName}</div>
          </div>
        </Grid>
        <Grid item className="header_info" lg={3} xs={12} md={4}>
          <div className="headerclient">
            <div className="qrtitle">Project Number:</div>
            <div>{ProjectNumber}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SeqHeader;
