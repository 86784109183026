import React, { useEffect } from "react";
import SequenceInspectionReport from "../component/sequenceInspectionReport";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PAGE_SIZE } from "../../constant/constant";
import { useSelector } from "react-redux";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import Loader from "../../library/common/Loader";

const SequenceInspectionReportContainer = () => {
  const location = useLocation();
  const projectNumber = location?.state?.projectNumber;
  const projectName = location?.state?.projectName;
  const clientName = location?.state?.clientname;
  const projectId = location?.state?.id;
  const dispatch = useDispatch();
  const QrInspectionListData = useSelector(
    (state) => state.qrSequenceLandingInspectionData?.data
  );
  const QrInspectionListDataLoading = useSelector(
    (state) => state.qrSequenceLandingInspectionData?.isLoading
  );
  const getSequenceLandingQrInpection = (data) => {
    dispatch(sequenceLandingAction.getSequenceLandingInspection(data));
  };

  useEffect(() => {
    const data = {
      search: "",
      projectId: projectId,
      page: 1,
      pageSize: PAGE_SIZE,
    };
    getSequenceLandingQrInpection(data);
  }, []);

  return (
    <>
      {QrInspectionListDataLoading && <Loader />}
      <SequenceInspectionReport
        projectNumber={projectNumber}
        projectName={projectName}
        clientName={clientName}
        QrInspectionListData={QrInspectionListData}
        getSequenceLandingQrInpection={getSequenceLandingQrInpection}
        isLoading={QrInspectionListDataLoading}
        projectId={projectId}
      />
    </>
  );
};

export default SequenceInspectionReportContainer;
