import { Button, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReportDetail from "./reportDetail";
import "./oshaViewReport.scss";
import DownloadIcon from "@mui/icons-material/Download";
import {
  downloadFullPDF,
  getEstablishmentTypeValue,
  downloadExcel,
} from "../../../utils/helper";
import Loader from "../../../library/common/Loader";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { OSHA_VIEW_REPORT_LIST } from "../../../constant/routeContant";
import Buttons from "../../../library/custom/button/Button";
import { useReactToPrint } from "react-to-print";
import OshaFormPdf from "./oshaFormPdf";
import { ESTABLISHMENT_TYPE, ORG_NAME } from "../../../constant/constant";

const OshaViewReport = (props) => {
  const [pdfclick, setpdfclick] = useState(false);

  const dataBack = {
    title: "Back to View OSHA Reports",
    route: OSHA_VIEW_REPORT_LIST,
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "OSHA Form 300 and related pages.",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });
  const data = [
    {
      establishment_name: props?.reportData?.establishmentName,
      company_name: ORG_NAME(),
      street_address: props?.establishmentDetail?.street,
      city: props?.establishmentDetail?.city,
      state: props?.establishmentDetail?.state,
      zip: props?.establishmentDetail?.zipCode,
      naics_code: props?.establishmentDetail?.naics,
      industry_description: props?.establishmentDetail?.industryDescription,
      size: props?.establishmentDetail?.empSize,
      establishment_type: getEstablishmentTypeValue(
        props?.establishmentDetail?.establishmentType,
        ESTABLISHMENT_TYPE
      ),
      year_filing_for: props?.reportData?.year,
      annual_average_employees: props?.reportData?.annualAvgNumberOfEmployee,
      total_hours_worked: props?.reportData?.totalEmployeeHoursWorked,
      no_injuries_illnesses:
        props?.reportData?.injuries > 0 || props?.reportData?.otherIllnesses > 0
          ? 1
          : 2,
      total_deaths: props?.reportData?.caseNumberOfDeath,
      total_dafw_cases: props?.reportData?.caseNumberOfDaysAway,
      total_djtr_cases: props?.reportData?.caseNumberOfTransfer,
      total_other_cases: props?.reportData?.caseNumberOtherRecordable,
      total_dafw_days: props?.reportData?.numberOfDaysAway,
      total_djtr_days: props?.reportData?.numberOfTransfer,
      total_injuries: props?.reportData?.injuries,
      total_skin_disorders: props?.reportData?.skinDisorder,
      total_respiratory_conditions: props?.reportData?.respiratoryCondition,
      total_poisonings: props?.reportData?.poisoning,
      total_hearing_loss: props?.reportData?.hearingLoss,
      total_other_illnesses: props?.reportData?.otherIllnesses,
      change_reason: "",
    },
  ];
  const downdownClick = () => {
    setpdfclick(true);
  };
  useEffect(() => {
    if (pdfclick) {
      downloadFullPDF("pdf-content", "OSHA-301.pdf", ["first-page"]);
    }
    setpdfclick(false);
  }, [pdfclick]);

  return (
    <>
      <div id="pdf-loader" class="loader pdf-loader">
        <Loader />
      </div>
      <Grid container className="overrideViewReports" id="pdf-content">
        <div className="w-100" id="first-page">
          <Grid item md={12} xs={12}>
            <div className="titleDownloadHeader">
              <div className="viewReports">Historical OSHA Records</div>
              <div className="hideinpdf">
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon className="downloadIcon" />}
                  className="downloadpdf sw marginRemove float_right"
                  onClick={downdownClick}
                >
                  Download as PDF
                </Button>

              </div>
            </div>
          </Grid>
          <Grid item md={3} xs={12} style={{ float: "right" }}>
            <div className="hideinpdf">
              {props?.reportData?.oshaAddId > 0 &&
                props?.reportData?.oshaSubmitId > 0 ? (
                " "
              ) : (
                <>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon className="downloadIcon" />}
                    className="downloadcsv"
                    onClick={() => downloadExcel(data, `${"OSHA-301"}.csv`)}
                  >
                    Download as Csv
                  </Button>
                </>
              )}

              <Grid item mt={3.5}><Buttons
                varientContained={true}
                label={"Download 300A"}
                onClick={handlePrint}
              /></Grid>

            </div>
          </Grid>

          <Grid item md={pdfclick ? 12 : 9} xs={12}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="viewReportHeaderContainerTop">
                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem titleReport">
                        Report ID:
                        <span>{props?.reportData?.submitOshaDetailsId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Establishments:
                        <span>{props?.reportData?.establishmentName}</span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Year: <span>{props?.reportData?.year}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox border-none">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Hours Worked:
                        <span>
                          {props?.reportData?.totalManHours}
                        </span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Number of Employees:
                        <span>
                          {props?.reportData?.avgNumberOfEmployees}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="viewReportHeaderContainerBottom">
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Validate Establishment Details
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Establishment:
                        <span>
                          {props?.establishmentDetail?.establishmentName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Street:<span>{props?.establishmentDetail?.street}</span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        City: <span> {props?.establishmentDetail?.city}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox border-none">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        State:<span>{props?.establishmentDetail?.state}</span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Zip Code:
                        <span>{props?.establishmentDetail?.zipCode}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Industry Description:
                        <span>
                          {props?.establishmentDetail?.industryDescription}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        EIN:
                        <span>{props?.establishmentDetail?.ein}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        Establishment Type:
                        <span>
                          {getEstablishmentTypeValue(
                            props?.establishmentDetail?.establishmentType,
                            ESTABLISHMENT_TYPE
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        Standard Industrial Classification (SIC):
                        <span>{props?.establishmentDetail?.sic}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox border-none">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        North American Industrial Classification (NAICS):
                        <span>{props?.establishmentDetail?.naics}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <ReportDetail
                  reportShow={props?.reportShow}
                  reportData={props?.reportData}
                />
              </Grid>
              <Grid style={{ display: "none" }}>
                <div ref={componentRef}>
                  <OshaFormPdf
                    oshaEstDetails={props?.establishmentDetail}
                    oshaDetail={props?.reportData}
                  />
                </div>
              </Grid>
              <div className="hideinpdf">
                <Grid item md={12} xs={12} mt={2}>
                  <div className="insBackBtn">
                    <BackBtn dataBack={dataBack} />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default OshaViewReport;
