import { call, put, takeLatest } from "redux-saga/effects";
import { incpectionType } from "../constants/incpectionType";
import * as incpectionAction from "../actions/incpectionAction";

import {
  getQestSet,
  getReferenceData,
  getQestSetList,
  postQestSetList,
  getInspectionReportListService,
  deleteInspectionService,
  getInspectionDetailsService,
  updateQestSetList,
  adminAddUpdateSubcategory,
  addUpdateQuestions,
  adminUpdateInspection,
  adminAddUpdateInspection,
  deleteQuestion,
  getCategoriesList,
  getSubcategoriesList,
  getQuestionsList,
  getQuestionSetList,
  cloneQuestionSet,
  adminAddUpdateCategory,
  getAsset,
  getQestSetDetail,
  updateInspectionItemLogClose,
  getInspectionItemLogDetails,
  getInspectionViolationCounts,
  getItemListService,
  updateReprimandToClose,
  getInspectionAcknowledgement,
  updateInspectionAcknowledgement,
  getExportItemListService,
  getInspectionReportAssetDetails,
  getInspectionReport,
  getOpenItemPhotos,
  getInspectionOpenItemCount,
  inspecionFieldsByQuestionSet,
  UpdateInspectionFieldsMap,
} from "../../services/incpectionService";
import { apiError } from "../../utils/helper";

export function* getQuesSetSaga({ payload }) {
  try {
    const { data, status, error } = yield call(getQestSet, payload);
    if (!data && status !== 200) throw apiError(error);
    const { questionsSets } = data;
    yield put({
      type: incpectionType.GET_QUESTION_SET_SUCCESS,
      data: questionsSets,
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.GET_QUESTION_SET_FAILED,
      error: errorData,
    });
  }
}

// get reference data
export function* getReferenceDataSaga() {
  try {
    const { data, status, error } = yield call(getReferenceData);
    if (!data && status !== 200) throw apiError(error);
    const { weather } = data;
    yield put({
      type: incpectionType.GET_WEATHER_REF_SUCCESS,
      data: weather,
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.GET_WEATHER_REF_FAILED,
      error: errorData,
    });
  }
}

export function* getQuesDynamicFieldSaga({ payload }) {
  try {
    const { data, status, error } = yield call(getQestSetList, payload);
    if (!data && status !== 200) throw apiError(error);
    const { categories, fieldsInfoResponse, signoffMethod, certifyStatementId } = data;
    let counter = 1;
    const result = categories.map((x) => {
      x.subcategories.forEach((y) => {
        y.questions.map((z) => {
          z.index = counter;
          counter++;
          return z;
        });
        return y;
      });
      return x;
    });
    yield put({
      type: incpectionType.GET_QUEST_DYNAMIC_FIELD_SUCCESS,
      payload: categories,
      dynamicFeild: fieldsInfoResponse,
      signoffMethod: signoffMethod,
      certifyStatementId: certifyStatementId,
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.GET_QUEST_DYNAMIC_FIELD_FAILED,
      error: errorData,
    });
  }
}

// post inspection data
export function* postQuestSetSaga({ payload }) {
  console.log("payload", payload);
  try {
    const { data, status, error } = yield call(postQestSetList, payload);
    if (!data && status !== 200) throw apiError(error);
    const { responseMessages, inspectionId, insAddEditFlag } = data;
    yield put({
      type: incpectionType.POST_INSPECTION_SUCCESS,
      payload: responseMessages?.responseMessage ?? {},
      successData: {
        inspectionId,
        status: payload?.status === 1 ? "Draft" : "Submitted",
        insAddEditFlag,
      },
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.POST_INSPECTION_FAILED,
      error: errorData,
    });
  }
}
// update
export function* updateInpectionDataSaga({ payload }) {
  try {
    const { data, status, error } = yield call(updateQestSetList, payload);
    if (!data && status !== 200) throw apiError(error);
    const { responseMessage } = data;
    yield put({
      type: incpectionType.UPDATE_INSPECTION_SUCCESS,
      payload: responseMessage ?? "",
      successData: {
        inspectionId: payload?.inspectionId ?? "N/A",
        status: payload?.status === 1 ? "Updated Draft" : "Updated",
      },
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.UPDATE_INSPECTION_FAILED,
      error: errorData,
    });
  }
}

export function* getInspectionRportListSaga({ payload }) {
  try {
    const { data, status, error } = yield call(
      getInspectionReportListService,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const { inspectionReports, recordsCount } = data;
    const res =
      inspectionReports &&
      inspectionReports.map((item) => {
        const items = { ...item };
        items.dateOfEntry = item.dateOfEntry ?? "N/A";
        items.inspectionBy = item.inspectionBy ?? "N/A";
        items.inspectionType = item.inspectionType ?? "N/A";
        items.reportId = item.reportId ?? "N/A";
        items.jobSiteAddress = item.jobSiteAddress ?? "N/A";
        return items;
      });
    yield put({
      type: incpectionType.GET_INSPECTION_VIEW_LIST_SUCCESS,
      payload: {
        inspectionReports: res,
        recordsCount: recordsCount,
      },
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Incpection Qestion Set Failed" };
    yield put({
      type: incpectionType.GET_INSPECTION_VIEW_LIST_FAILED,
      error: errorData,
    });
  }
}

export function* deleteInspectionSaga(payload) {
  try {
    const { data, status } = yield call(deleteInspectionService, payload);
    const casePage = incpectionType.DELETE_PAGE;

    yield put(
      incpectionAction.deleteInspectionSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get CONTACT List Failed" };
    yield put(incpectionAction.deleteInspectionFailure(data));
  }
}

export function* getInspectionDetailsSaga({ payload }) {
  try {
    const { data, status, error } = yield call(
      getInspectionDetailsService,
      payload
    );
    if (!data && status !== 200) throw apiError(error);

    yield put({
      type: incpectionType.GET_INSPECTION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorData =
      error && error.message
        ? error
        : { message: error || "Get Inc  pection Qestion Set Failed" };
    yield put({
      type: incpectionType.GET_INSPECTION_DETAILS_FAILED,
      error: errorData,
    });
  }
}

export function* adminAddUpdateSubcategorySaga(params) {
  console.debug("adminAddUpdateSubcategorySaga");
  try {
    const { data, status, error } = yield call(
      adminAddUpdateSubcategory,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.SUBCATEGORY_PAGE;
    yield put(
      incpectionAction.adminAddUpdateSubcategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "add Update SubCategory Failed" };
    yield put(incpectionAction.adminAddUpdateSubcategoryFailure(data));
  }
}

export function* adminAddUpdateCategorySaga(params) {
  console.debug("adminAddUpdateCategorySaga");
  try {
    const { data, status, error } = yield call(adminAddUpdateCategory, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.CATEGORY_PAGE;
    yield put(
      incpectionAction.adminAddUpdateCategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "add Update Category Failed" };
    yield put(incpectionAction.adminAddUpdateCategoryFailure(data));
  }
}

export function* addUpdateQuestionsSaga(params) {
  console.debug("addUpdateQuestionsSaga");
  try {
    const { data, status, error } = yield call(addUpdateQuestions, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.ADDUPDATEQUESTIONS_PAGE;
    yield put(
      incpectionAction.addUpdateQuestionsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "add Update Questions Failed" };
    yield put(incpectionAction.addUpdateQuestionsFailure(data));
  }
}

export function* adminUpdateInspectionSaga(params) {
  console.debug("adminUpdateInspectionSaga");
  try {
    const { data, status, error } = yield call(adminUpdateInspection, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.ADMINUPDATEINSPECTION_PAGE;
    yield put(
      incpectionAction.adminUpdateInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "add admin Update Inspection Failed" };
    yield put(incpectionAction.adminUpdateInspectionFailure(data));
  }
}

export function* adminAddUpdateInspectionSaga(params) {
  console.debug("adminAddUpdateInspectionSaga");
  try {
    const { data, status, error } = yield call(
      adminAddUpdateInspection,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.ADMINADDUPDATEINSPECTION;
    yield put(
      incpectionAction.adminAddUpdateInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "add admin Update Inspection Failed" };
    yield put(incpectionAction.adminAddUpdateInspectionFailure(data));
  }
}

function* deleteQuestionSaga(payload) {
  console.debug("deleteQuestionSaga");
  try {
    const { data, status, error } = yield call(deleteQuestion, payload);
    if (!data && status !== 200) throw apiError(error);
    const Page = incpectionType.ADDUPDATEQUESTIONS_PAGE;
    yield put(
      incpectionAction.deleteQuestionSuccess({
        Page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete Question Failed" };
    yield put(incpectionAction.deleteQuestionFailure(data));
  }
}

export function* getCategoriesListSaga(params) {
  console.debug("getCategoriesListSaga");
  try {
    const { data, status, error } = yield call(getCategoriesList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.CATEGORIES_PAGE;
    yield put(
      incpectionAction.getCategoriesListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Categories List Failed" };
    yield put(incpectionAction.getCategoriesListFailure(data));
  }
}

export function* getSubCategoriesListSaga(params) {
  console.debug("getSubCategoriesListSaga");
  try {
    const { data, status, error } = yield call(getSubcategoriesList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.SUB_CATEGORIES_PAGE;
    yield put(
      incpectionAction.getSubCategoriesListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Sub Categories List Failed" };
    yield put(incpectionAction.getSubCategoriesListFailure(data));
  }
}

export function* getQuestionsListSaga(params) {
  console.debug("getQuestionsListSaga");
  try {
    const { data, status, error } = yield call(getQuestionsList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.QUESTIONS_PAGE;
    yield put(
      incpectionAction.getQuestionsListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Questions List Failed" };
    yield put(incpectionAction.getQuestionsListFailure(data));
  }
}

export function* getAssetSaga(params) {
  console.debug("getAssetSaga");
  try {
    const { data, status, error } = yield call(getAsset, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_ASSET_LIST_PAGE;
    yield put(
      incpectionAction.getAssetListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Asset List Failed" };
    yield put(incpectionAction.getAssetListFailure(data));
  }
}

export function* getQuestionSetListSaga(params) {
  console.debug("getQuestionSetListSaga");
  try {
    const { data, status, error } = yield call(getQuestionSetList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_QUESTIONS_SET_LIST_PAGE;
    yield put(
      incpectionAction.getQuestionSetListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Question Set List Failed" };
    yield put(incpectionAction.getQuestionSetListFailure(data));
  }
}

export function* cloneQuestionSetSaga(params) {
  console.debug("cloneQuestionSetSaga");
  try {
    const { data, status, error } = yield call(cloneQuestionSet, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.CLONE_QUESTION_SET_PAGE;
    yield put(
      incpectionAction.cloneQuestionSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "clone Question Set Failed" };
    yield put(incpectionAction.cloneQuestionSetFailure(data));
  }
}

export function* qestSetDetailSaga(params) {
  console.debug("qestSetDetailSaga");
  try {
    const { data, status, error } = yield call(getQestSetDetail, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.QEST_SET_Detail_PAGE;
    yield put(
      incpectionAction.qestSetDetailSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "qestSet Detail Set Failed" };
    yield put(incpectionAction.qestSetDetailSetFailure(data));
  }
}

export function* closeInspectionOpenItem(params) {
  console.debug("closeInspectionOpenItem");
  try {
    const { data, status, error } = yield call(
      updateInspectionItemLogClose,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.UPDATE_INSPECTION_OPENITEM_SUCCESS;
    yield put(
      incpectionAction.CloseInspectionOpenItemSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update inspection open item Failed" };
    yield put(incpectionAction.CloseInspectionOpenItemFailure(data));
  }
}

export function* getInspectionOpenItemDetailSaga(params) {
  console.debug("getInspectionOpenItemDetailSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionItemLogDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_INSPECTION_OPENITEM_DETAIL;
    yield put(
      incpectionAction.getInspectionOpenItemDetailSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Inspection OpenItem Detail Failed" };
    yield put(incpectionAction.getInspectionOpenItemDetailFailure(data));
  }
}

export function* getInspectionViolationCountsSaga(params) {
  console.debug("getInspectionViolationCountsSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionViolationCounts,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_INSPECTION_VIOLATION_COUNTS_PAGE;
    yield put(
      incpectionAction.getInspectionViolationCountsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Violation Counts Failed" };
    yield put(incpectionAction.getInspectionViolationCountsFailure(data));
  }
}

export function* getItemListSaga(params) {
  console.debug("getItemListSaga");
  try {
    const { data, status, error } = yield call(getItemListService, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_ITEM_LIST_SUCCESS;
    yield put(
      incpectionAction.getItemListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Item list Failed" };
    yield put(incpectionAction.getItemListFailure(data));
  }
}

export function* updateReprimandToCloseSaga(params) {
  console.debug("updateReprimandToCloseSaga");
  try {
    const { data, status, error } = yield call(updateReprimandToClose, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.POST_REPRIMAND_TO_CLOSE;
    yield put(
      incpectionAction.postReprimandToCloseSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "close reprimand Failed" };
    yield put(incpectionAction.postReprimandToCloseFailure(data));
  }
}

export function* getInspectionAcknowledgementSaga(params) {
  console.debug("getInspectionAcknowledgementSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionAcknowledgement,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.INSPECTION_ACKNOWLEDGEMENT;
    yield put(
      incpectionAction.getInspectionAcknowledgementSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Acknowledgement Failure Failed" };
    yield put(incpectionAction.getInspectionAcknowledgementFailure(data));
  }
}

export function* updateInspectionAcknowledgementSaga(params) {
  console.debug("updateInspectionAcknowledgementSaga");
  try {
    const { data, status, error } = yield call(
      updateInspectionAcknowledgement,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.INSPECTION_ACKNOWLEDGEMENT;
    yield put(
      incpectionAction.updateInspectionAcknowledgementSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message:
              error || "update Inspection Acknowledgement Failure Failed",
          };
    yield put(incpectionAction.updateInspectionAcknowledgementFailure(data));
  }
}

export function* getExportItemListSaga(params) {
  console.debug("getExportItemListSaga");
  try {
    const { data, status, error } = yield call(
      getExportItemListService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_EXPORT_ITEM_LIST_SUCCESS;
    yield put(
      incpectionAction.getExportItemListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Export Item list Failed" };
    yield put(incpectionAction.getExportItemListFailure(data));
  }
}
export function* getInspectionReportAssetDetailsSaga(params) {
  console.debug("getInspectionReportAssetDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionReportAssetDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS_SUCCESS;
    yield put(
      incpectionAction.getInspectionReportAssetDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Report Asset Details Failure" };
    yield put(incpectionAction.getInspectionReportAssetDetailsFailure(data));
  }
}

export function* getInspecionReportSaga(params) {
  console.debug("getInspecionReportSaga");
  try {
    const { data, status, error } = yield call(getInspectionReport, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_INSPECTION_REPORT_SUCCESS;
    yield put(
      incpectionAction.getInspecionReportSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Report Failure" };
    yield put(incpectionAction.getInspecionReportFailure(data));
  }
}
export function* getOpenItemPhotosSaga(params) {
  console.debug("getOpenItemPhotosSaga");
  try {
    const { data, status, error } = yield call(getOpenItemPhotos, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_OPEN_ITEM_PHOTOS_SUCCESS;
    yield put(
      incpectionAction.getOpenItemPhotosSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Open Item Photos Failure" };
    yield put(incpectionAction.getOpenItemPhotosFailure(data));
  }
}

export function* getInspectionOpenItemCountSaga(params) {
  try {
    const { data, status, error } = yield call(
      getInspectionOpenItemCount,
      params.data
    );

    if (!data && status !== 200) throw apiError(error);

    yield put(
      incpectionAction.getInspectionOpenItemCountsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getInspectionOpenItemCount Failed" };
    yield put(incpectionAction.getInspectionOpenItemCountsFailure(data));
  }
}

export function* getInspectionFieldsMapSaga(params) {
  console.debug("getInspectionFieldsMapSaga");
  try {
    const { data, status, error } = yield call(
      inspecionFieldsByQuestionSet,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.GET_EXPORT_ITEM_LIST_SUCCESS;
    yield put(
      incpectionAction.getInspecionFieldsByQuestionSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspecion Fields By QuestionSet Failed" };
    yield put(incpectionAction.getInspecionFieldsByQuestionSetFailure(data));
  }
}

export function* updateInspectionFieldsMapSaga(params) {
  console.debug("updateInspectionFieldsMapSaga");
  try {
    const { data, status, error } = yield call(
      UpdateInspectionFieldsMap,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incpectionType.INSPECTION_ACKNOWLEDGEMENT;
    yield put(
      incpectionAction.inspectionFieldsMapSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "update inspection Fields Map Failed",
          };
    yield put(incpectionAction.inspectionFieldsMapFailure(data));
  }
}

const inspectionSaga = [
  takeLatest(incpectionType.GET_QUESTION_SET_PENDING, getQuesSetSaga),
  takeLatest(incpectionType.GET_WEATHER_REF_PENDING, getReferenceDataSaga),
  takeLatest(
    incpectionType.GET_QUEST_DYNAMIC_FIELD_PENDING,
    getQuesDynamicFieldSaga
  ),
  takeLatest(incpectionType.POST_INSPECTION_PENDING, postQuestSetSaga),
  takeLatest(incpectionType.UPDATE_INSPECTION_PENDING, updateInpectionDataSaga),
  takeLatest(
    incpectionType.GET_INSPECTION_VIEW_LIST_PENDING,
    getInspectionRportListSaga
  ),
  takeLatest(incpectionType.DEELETE_INSPECTION_PENDING, deleteInspectionSaga),
  takeLatest(
    incpectionType.GET_INSPECTION_DETAILS_PENDING,
    getInspectionDetailsSaga
  ),
  takeLatest(incpectionType.POST_CATEGORY_PENDING, adminAddUpdateCategorySaga),
  takeLatest(
    incpectionType.POST_SUBCATEGORY_PENDING,
    adminAddUpdateSubcategorySaga
  ),
  takeLatest(
    incpectionType.POST_ADDUPDATEQUESTIONS_PENDING,
    addUpdateQuestionsSaga
  ),
  takeLatest(
    incpectionType.POST_ADMINUPDATEINSPECTION_PENDING,
    adminUpdateInspectionSaga
  ),
  takeLatest(
    incpectionType.POST_ADMINADDUPDATEINSPECTION_PENDING,
    adminAddUpdateInspectionSaga
  ),
  takeLatest(incpectionType.DELETE_QUESTIONS, deleteQuestionSaga),
  takeLatest(incpectionType.GET_CATEGORIES_LIST, getCategoriesListSaga),
  takeLatest(incpectionType.GET_SUB_CATEGORIES_LIST, getSubCategoriesListSaga),
  takeLatest(incpectionType.GET_QUESTIONS_LIST, getQuestionsListSaga),
  takeLatest(incpectionType.GET_QUESTIONS_SET_LIST, getQuestionSetListSaga),
  takeLatest(incpectionType.CLONE_QUESTION_SET, cloneQuestionSetSaga),
  takeLatest(incpectionType.QEST_SET_DETAIL, qestSetDetailSaga),
  takeLatest(incpectionType.GET_ASSET_LIST, getAssetSaga),
  takeLatest(
    incpectionType.UPDATE_INSPECTION_OPENITEM,
    closeInspectionOpenItem
  ),
  takeLatest(
    incpectionType.GET_INSPECTION_OPENITEM_DETAIL,
    getInspectionOpenItemDetailSaga
  ),
  takeLatest(
    incpectionType.GET_INSPECTION_VIOLATION_COUNTS,
    getInspectionViolationCountsSaga
  ),
  takeLatest(incpectionType.GET_ITEM_LIST, getItemListSaga),
  takeLatest(
    incpectionType.POST_REPRIMAND_TO_CLOSE,
    updateReprimandToCloseSaga
  ),
  takeLatest(
    incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT,
    getInspectionAcknowledgementSaga
  ),
  takeLatest(
    incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_PENDING,
    updateInspectionAcknowledgementSaga
  ),
  takeLatest(incpectionType.GET_EXPORT_ITEM_LIST, getExportItemListSaga),
  takeLatest(
    incpectionType.POST_INSPECTION_FIELDS_MAP_PENDING,
    updateInspectionFieldsMapSaga
  ),
  takeLatest(
    incpectionType.GET_INSPECTION_FIELDS_BY_QUESTIONSET,
    getInspectionFieldsMapSaga
  ),
  takeLatest(
    incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS,
    getInspectionReportAssetDetailsSaga
  ),
  takeLatest(incpectionType.GET_INSPECTION_REPORT, getInspecionReportSaga),
  takeLatest(incpectionType.GET_OPEN_ITEM_PHOTOS, getOpenItemPhotosSaga),
  takeLatest(
    incpectionType.GET_INSPECTION_OPEN_ITEM_COUNT_PENDING,
    getInspectionOpenItemCountSaga
  ),
];

export default inspectionSaga;
