import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import commonImages from "../../../../assets";
import Buttons from "../../../../library/custom/button/Button";
import "./addNewCategory.scss";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { ORG_ID, USER_ID } from "../../../../constant/constant";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "../../../../library/checkbox/checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const AddNewCategory = (props) => {
  const {
    getSubCategoriesList,
    cancelCategory,
    cancelSubCategory,
    singleCategoryes,
    addSubCategory,
    handleOnChangeCategoryForm,
    handleQuestionCheck,
    onSubCategorySubmit,
    handleQuestionAdd,
    submitQuestionData,
    selectSubCategory,
    selectSubCategoryMsg,
    handleAllQuestionCheck,
  } = props;

  const [addSubCategoryBox, setAddSubCategoryBox] = useState(false);
  const handleOnclickAddSubCategory = () => {
    setAddSubCategoryBox(true);
  };

  const [isSubCategorySubmit, setIsSubCategorySubmit] = useState(false);
  const intialState = {
    subCategoriesValue: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isSubCategoryName, setIsSubCategoryName] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  function mappedData() {
    let errors = false;
    if (formValue?.subCategoriesValue.trim() === "") {
      setFormValue({
        ...formValue,
        subCategoriesValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsSubCategoryName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = getSubCategoriesList?.findIndex(
      (object) =>
        object.value.toUpperCase() ===
        formValue?.subCategoriesValue.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const subcategories = [
        {
          key: 0,
          value: formValue?.subCategoriesValue,
          isActive: true,
          createdBy: parseInt(USER_ID()),
        },
      ];
      return { subcategories, organisationId: ORG_ID()};
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Sub Category Name already added");
    }
  }

  const submitSubCategoryData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setFormValue({
        ...formValue,
        subCategoriesValue: "",
      });
      setIsSubCategorySubmit(true);
      onSubCategorySubmit(data);
    }
  };

  const handleOnDragEnd = (result, type) => {
    if (!result.destination) return;

    if (type === "subcategories") {
      const items = Array.from(singleCategoryes.subcategories);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      singleCategoryes.subcategories = items;

      handleOnChangeCategoryForm(
        singleCategoryes.subcategories,
        singleCategoryes,
        null,
        "subcategories"
      );
    } else if (type === "questions") {
      const subCatIndex = result?.source?.droppableId?.split("-")[1];
      const subCategory = singleCategoryes?.subcategories[subCatIndex];
      const items = Array.from(subCategory?.questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      subCategory.questions = items;
      handleOnChangeCategoryForm(
        singleCategoryes.subcategories,
        singleCategoryes,
        null,
        "subcategories"
      );
    }
  };

  return (
    <>
      <Grid container item>
        <Grid container item mt={3}>
          <Grid md={12} item className="categoryTitle">
            <Grid className="categoryTitleValue">
              <span className="dragIcon">
                <DragIndicatorIcon />
              </span>
              {singleCategoryes?.value}
            </Grid>
            <Grid container className="skippedBox">
              <Grid item className="skipped">
                <Checkbox
                  checked={singleCategoryes?.isSkippable}
                  onChange={(e) =>
                    handleOnChangeCategoryForm(
                      e,
                      singleCategoryes,
                      null,
                      "skippedCategory"
                    )
                  }
                  label={"Skippable"}
                />
              </Grid>
              <Grid
                item
                className="close"
                onClick={() => cancelCategory(singleCategoryes)}
              >
                X
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={6} item mt={3} px={3}>
              <FormControl fullWidth>
                <FormLabels label="Add Sub Category" />

                <MultiSelectBox
                  placeholder={"Please Select"}
                  listData={getSubCategoriesList}
                  onChange={(e) =>
                    handleOnChangeCategoryForm(
                      e,
                      singleCategoryes,
                      null,
                      "subCategorySetValue"
                    )
                  }
                  value={singleCategoryes?.subCategorySetValue ?? []}
                />
              </FormControl>
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={2}
              xs={3}
              ml={2}
              mt={7}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={() => addSubCategory(singleCategoryes)}
              ></Buttons>
            </Grid>
            <Grid
              item
              lg={3}
              md={2}
              xs={3}
              ml={1}
              mt={7}
              className="variantOutlineBorderSmall"
            >
              <Buttons
                aria-describedby="dd"
                variant="variantOutlineBorder"
                type="button"
                name="btn"
                label={"Create New"}
                id="btnAddEquipment"
                variantOutlineBorder={true}
                onClick={handleOnclickAddSubCategory}
              ></Buttons>
            </Grid>
            <Grid item md={12} px={3}>
              {selectSubCategory && (
                <div className="errorMsg">{selectSubCategoryMsg}</div>
              )}
            </Grid>
          </Grid>
          {addSubCategoryBox && (
            <Grid item container md={12} className="CategoriesBox">
              <Grid ml={3} item md={5} xs={8} mb={2}>
                <InputField
                  type="text"
                  isRequired={true}
                  value={formValue?.subCategoriesValue}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      subCategoriesValue: e.target.value,
                    })
                  }
                  label="Create Sub Category"
                  placeholder="Create Sub Category Here..."
                  isError={isSubCategoryName}
                  errorMsg={
                    isSubCategoryName ? "Sub Category Name is Required" : ""
                  }
                />
                {errorsMsgShowActive && (
                  <div className="errorMsg">{errorsMsgShow}</div>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.6}
                md={3}
                xs={3}
                ml={3}
                mt={4}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Create"}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                  disabled={false}
                  onClick={submitSubCategoryData}
                ></Buttons>
              </Grid>
            </Grid>
          )}
          <DragDropContext
            onDragEnd={(result) => handleOnDragEnd(result, "subcategories")}
          >
            <Droppable droppableId="subcategories">
              {(provided) => (
                <Grid
                  container
                  mt={2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="w-100"
                >
                  {singleCategoryes.subcategories.map(
                    (singlesubcategories, subIndex) => (
                      <Draggable
                        key={subIndex}
                        draggableId={`subcategory-${subIndex}`}
                        index={subIndex}
                      >
                        {(provided) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="w-100"
                          >
                            <Grid
                              container
                              px={3}
                              mt={3}
                              id={singlesubcategories?.key}
                            >
                              <Grid md={12} item className="subCategoryTitle">
                                <Grid className="subCategoryTitleValue">
                                  <div className="dragIcon">
                                    <DragIndicatorIcon />
                                  </div>
                                  {singlesubcategories?.value}
                                </Grid>
                                <Grid className="selectAll">
                                  <FormControlLabel
                                    control={
                                      <img
                                        className="filterCheckbox"
                                        title={
                                          singlesubcategories.allSelect
                                            ? "tickCheckbox"
                                            : "untickCheckbox"
                                        }
                                        alt=""
                                        src={
                                          singlesubcategories.allSelect
                                            ? commonImages.check
                                            : commonImages.noCheck
                                        }
                                        onClick={() =>
                                          handleAllQuestionCheck(
                                            singleCategoryes,
                                            singlesubcategories
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      singlesubcategories.allSelect
                                        ? "Deselect All"
                                        : "Select All"
                                    }
                                  />
                                </Grid>

                                <Grid container className="skippedBox">
                                  <Grid>
                                    <Checkbox
                                      checked={singlesubcategories?.isSkippable}
                                      onChange={(e) =>
                                        handleOnChangeCategoryForm(
                                          e,
                                          singleCategoryes,
                                          singlesubcategories,
                                          "skippedValue"
                                        )
                                      }
                                      label={"Skippable"}
                                    />
                                  </Grid>
                                  <Grid
                                    className="close"
                                    onClick={() =>
                                      cancelSubCategory(
                                        singleCategoryes,
                                        singlesubcategories
                                      )
                                    }
                                  >
                                    X
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid md={12} item className="questionBox">
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    handleOnDragEnd(result, "questions")
                                  }
                                >
                                  <Droppable
                                    droppableId={`questions-${subIndex}`}
                                  >
                                    {(provided) => (
                                      <Grid
                                        container
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="w-100 questionBox"
                                      >
                                        {singlesubcategories?.questions?.map(
                                          (quesItem, qIndex) => (
                                            <Draggable
                                              key={qIndex}
                                              draggableId={`question-${qIndex}`}
                                              index={qIndex}
                                            >
                                              {(provided) => (
                                                <Grid
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className="w-100 questionItem"
                                                >
                                                  <div className="dragIcon">
                                                    <DragIndicatorIcon />
                                                  </div>
                                                  <FormControlLabel
                                                    control={
                                                      <img
                                                        className="filterCheckbox"
                                                        title={
                                                          quesItem?.isSelected
                                                            ? "tickCheckbox"
                                                            : "untickCheckbox"
                                                        }
                                                        alt=""
                                                        src={
                                                          quesItem?.isSelected
                                                            ? commonImages.check
                                                            : commonImages.noCheck
                                                        }
                                                        onClick={() =>
                                                          handleQuestionCheck(
                                                            singleCategoryes,
                                                            singlesubcategories,
                                                            quesItem
                                                          )
                                                        }
                                                      />
                                                    }
                                                    label={quesItem?.value}
                                                  />
                                                </Grid>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </Grid>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </Grid>

                              {singlesubcategories?.show && (
                                <>
                                  <Grid
                                    container
                                    className="addQuestionBox"
                                    item
                                    md={12}
                                  >
                                    <Grid
                                      className="addQuestionInput"
                                      item
                                      mt={3}
                                      lg={12}
                                    >
                                      <InputField
                                        type="text"
                                        isRequired={true}
                                        value={
                                          singlesubcategories?.questionValue
                                        }
                                        label={false}
                                        placeholder="Add Question Text Here..."
                                        onChange={(e) =>
                                          handleOnChangeCategoryForm(
                                            e,
                                            singleCategoryes,
                                            singlesubcategories,
                                            "questionValue"
                                          )
                                        }
                                      />
                                    </Grid>
                                    <div>
                                      {singlesubcategories?.errorTextFieldRequired &&
                                        !singlesubcategories?.questionValue && (
                                          <p className="error">
                                            Please add question
                                          </p>
                                        )}
                                    </div>
                                    <Grid
                                      item
                                      md={12}
                                      sm={12}
                                      className="QuestionBoxRes"
                                    >
                                      <div className="responseTypeBoxRes">
                                        <div className="responseType">
                                          Response Type
                                        </div>
                                        <div className="responseTypeRight">
                                          <div
                                            onClick={(e) =>
                                              handleOnChangeCategoryForm(
                                                1,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "responsetype"
                                              )
                                            }
                                            className={
                                              singlesubcategories?.responsetype ===
                                              1
                                                ? "responseTypeBtn active"
                                                : "responseTypeBtn"
                                            }
                                          >
                                            Acceptable/Not Acceptable/NA
                                          </div>
                                          <div
                                            onClick={(e) =>
                                              handleOnChangeCategoryForm(
                                                2,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "responsetype"
                                              )
                                            }
                                            className={
                                              singlesubcategories?.responsetype ===
                                              2
                                                ? "responseTypeBtn active"
                                                : "responseTypeBtn"
                                            }
                                          >
                                            Text Box
                                          </div>
                                          <div
                                            onClick={(e) =>
                                              handleOnChangeCategoryForm(
                                                3,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "responsetype"
                                              )
                                            }
                                            className={
                                              singlesubcategories?.responsetype ===
                                              3
                                                ? "responseTypeBtn active"
                                                : "responseTypeBtn"
                                            }
                                          >
                                            Single Select Dropdown
                                          </div>

                                          <div
                                            onClick={(e) =>
                                              handleOnChangeCategoryForm(
                                                4,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "responsetype"
                                              )
                                            }
                                            className={
                                              singlesubcategories?.responsetype ===
                                              4
                                                ? "responseTypeBtn active"
                                                : "responseTypeBtn"
                                            }
                                          >
                                            Multiple Select Dropdown
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>

                                    {singlesubcategories?.responsetype ===
                                      1 && (
                                      <>
                                        <Grid
                                          container
                                          mt={2}
                                          item
                                          md={12}
                                          xs={12}
                                          className="addQuestionBox"
                                        >
                                          <Grid item md={12} xs={12} pl={3}>
                                            <FormControl fullWidth>
                                              <FormLabels
                                                label={"Involved Party Type"}
                                                isRequired={true}
                                              />
                                              <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className="radioGroup"
                                              >
                                                <RadioButton
                                                  checked={
                                                    singlesubcategories?.involvedPartType ===
                                                    1
                                                  }
                                                  value="1"
                                                  label="Yes"
                                                  onChange={(e) =>
                                                    handleOnChangeCategoryForm(
                                                      1,
                                                      singleCategoryes,
                                                      singlesubcategories,
                                                      "involvedPartType"
                                                    )
                                                  }
                                                ></RadioButton>

                                                <RadioButton
                                                  checked={
                                                    singlesubcategories?.involvedPartType ===
                                                    2
                                                  }
                                                  value="2"
                                                  label="No"
                                                  onChange={(e) =>
                                                    handleOnChangeCategoryForm(
                                                      2,
                                                      singleCategoryes,
                                                      singlesubcategories,
                                                      "involvedPartType"
                                                    )
                                                  }
                                                ></RadioButton>
                                              </RadioGroup>
                                            </FormControl>
                                          </Grid>
                                        </Grid>

                                        <Grid>
                                          {singlesubcategories?.involvedPartTypeError &&
                                            singlesubcategories?.involvedPartType ===
                                              null && (
                                              <p className="error">
                                                Please select involved party
                                                type
                                              </p>
                                            )}
                                        </Grid>
                                      </>
                                    )}

                                    {(singlesubcategories?.responsetype === 3 ||
                                      singlesubcategories?.responsetype ===
                                        4) && (
                                      <Grid
                                        container
                                        mt={2}
                                        item
                                        md={12}
                                        xs={12}
                                        className="addQuestionBox"
                                      >
                                        <Grid
                                          item
                                          md={7}
                                          xs={12}
                                          className="addQuestionInput"
                                        >
                                          <InputField
                                            type="text"
                                            isRequired={true}
                                            value={
                                              singlesubcategories?.optionValue
                                            }
                                            label={false}
                                            placeholder="Add Option Text Here..."
                                            onChange={(e) =>
                                              handleOnChangeCategoryForm(
                                                e,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "optionValue"
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          pl={2}
                                          md={5}
                                          xs={12}
                                          className="meduimAddBtn"
                                        >
                                          <Buttons
                                            aria-describedby="dd"
                                            variant="contained"
                                            type="button"
                                            name="btn"
                                            label={"Add Option"}
                                            id="btnAddEquipment"
                                            varientAddIconBlue={true}
                                            // onClick={submitOptionData}
                                            onClick={(e) =>
                                              handleOnChangeCategoryForm(
                                                singlesubcategories?.optionValue,
                                                singleCategoryes,
                                                singlesubcategories,
                                                "addOption"
                                              )
                                            }
                                          ></Buttons>
                                        </Grid>
                                        <Grid>
                                          {singlesubcategories?.responsetypeSelectError && (
                                            <p className="error">
                                              Please add option
                                            </p>
                                          )}
                                          {singlesubcategories?.optionRequired && (
                                            <p className="error">
                                              Please add option
                                            </p>
                                          )}
                                        </Grid>

                                        <Grid>
                                          {singlesubcategories?.duplicateError && (
                                            <p className="error">
                                              Please add different option
                                            </p>
                                          )}
                                        </Grid>

                                        <Grid
                                          item
                                          md="{12}"
                                          className="CategoriesBox w-100"
                                          mt="{3}"
                                        >
                                          <Grid className="title">
                                            Option List
                                          </Grid>
                                          <Grid
                                            className="questionBox mainQuestion setQuestion"
                                            mt={3}
                                          >
                                            {singlesubcategories?.optionList?.map(
                                              (option, optionIndex) => {
                                                return (
                                                  <Grid
                                                    className="catListBox small"
                                                    key={optionIndex}
                                                  >
                                                    {singlesubcategories.editOptionIndex ===
                                                    optionIndex ? (
                                                      <>
                                                        <Grid container>
                                                          <Grid md={6} xs={12}>
                                                            <InputField
                                                              type="text"
                                                              isRequired={true}
                                                              value={
                                                                singlesubcategories.editOptionIndexCurrent ??
                                                                option?.value
                                                              }
                                                              label={false}
                                                              placeholder="Add Option Text Here..."
                                                              onChange={(e) =>
                                                                handleOnChangeCategoryForm(
                                                                  e,
                                                                  singleCategoryes,
                                                                  singlesubcategories,
                                                                  "optionEditValue"
                                                                )
                                                              }
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            md={6}
                                                            xs={12}
                                                            container
                                                          >
                                                            <Grid
                                                              item
                                                              lg={2}
                                                              xs={12}
                                                              sm={3}
                                                              md={3}
                                                              ml={2}
                                                            >
                                                              <Buttons
                                                                varientContained={
                                                                  true
                                                                }
                                                                label={"Update"}
                                                                onClick={(e) =>
                                                                  handleOnChangeCategoryForm(
                                                                    e,
                                                                    singleCategoryes,
                                                                    singlesubcategories,
                                                                    "optionEditValueSave"
                                                                  )
                                                                }
                                                              />
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              lg={2}
                                                              xs={12}
                                                              sm={3}
                                                              md={3}
                                                              ml={2}
                                                            >
                                                              <Buttons
                                                                varientText={
                                                                  true
                                                                }
                                                                label="Cancel"
                                                                onClick={(e) =>
                                                                  handleOnChangeCategoryForm(
                                                                    e,
                                                                    singleCategoryes,
                                                                    singlesubcategories,
                                                                    "optionEditValueCancel"
                                                                  )
                                                                }
                                                              />
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Grid className="catListItem">
                                                          {option?.value}
                                                        </Grid>

                                                        <Grid className="editIcon">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            onClick={() =>
                                                              handleOnChangeCategoryForm(
                                                                optionIndex,
                                                                singleCategoryes,
                                                                singlesubcategories,
                                                                "optionEdit"
                                                              )
                                                            }
                                                          >
                                                            <path
                                                              d="M14.2892 5.54197L10.5359 1.81197L11.5632 0.791966C11.9127 0.444632 12.3287 0.270966 12.811 0.270966C13.2933 0.270966 13.7089 0.444632 14.0577 0.791966L15.3156 2.04197C15.651 2.3753 15.8187 2.7883 15.8187 3.28097C15.8187 3.7743 15.651 4.18763 15.3156 4.52097L14.2892 5.54197ZM13.0515 6.77097L4.26793 15.5H0.515625V11.771L9.29918 3.04197L13.0515 6.77097Z"
                                                              fill="#1B436E"
                                                            />
                                                          </svg>
                                                        </Grid>
                                                        <Grid
                                                          className="closeIcon"
                                                          onClick={() =>
                                                            handleOnChangeCategoryForm(
                                                              optionIndex,
                                                              singleCategoryes,
                                                              singlesubcategories,
                                                              "optionDelete"
                                                            )
                                                          }
                                                        >
                                                          <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root deleteIcon"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="DeleteIcon"
                                                          >
                                                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"></path>
                                                          </svg>
                                                        </Grid>
                                                      </>
                                                    )}
                                                  </Grid>
                                                );
                                              }
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}
                                    <Grid
                                      className="smallAddBtn"
                                      item
                                      lg={12}
                                      mt={3}
                                    >
                                      <Buttons
                                        aria-describedby="dd"
                                        variant="contained"
                                        type="button"
                                        name="btn"
                                        label={"Create"}
                                        id="btnAddEquipment"
                                        varientAddIconBlue={true}
                                        // disabled={isSubCategorySubmit ? true : false}
                                        onClick={() =>
                                          submitQuestionData(
                                            singlesubcategories,
                                            singleCategoryes
                                          )
                                        }
                                      ></Buttons>
                                    </Grid>
                                  </Grid>
                                  {singlesubcategories?.errorTextField ? (
                                    <Grid md={12} item>
                                      <p className="errorMsg">
                                        {"Question Already added."}
                                      </p>
                                    </Grid>
                                  ) : null}
                                </>
                              )}

                              <Grid container>
                                <Grid
                                  item
                                  lg={3}
                                  md={2}
                                  xs={3}
                                  ml={1}
                                  mt={3}
                                  className="variantOutlineBorderSmall"
                                >
                                  <Buttons
                                    aria-describedby="dd"
                                    variant="variantOutlineBorder"
                                    type="button"
                                    name="btn"
                                    label={"Add Question"}
                                    id="btnAddEquipment"
                                    iconToggle={singlesubcategories?.show}
                                    variantOutlineBorder={true}
                                    onClick={() =>
                                      handleQuestionAdd(
                                        singleCategoryes,
                                        singlesubcategories
                                      )
                                    }
                                  ></Buttons>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewCategory;
