import { Chip, Grid } from "@mui/material";
import "./UsersChip.scss";
import React, { useState } from "react";

const DeletableChip = ({
  userList,
  handleDelete,
  isCountChip = false,
  isCount,
  isiconDel = true,
}) => {
  const [showAll, setshowAll] = useState(false);

  const filteredUserList = userList && userList?.filter(
    (item) => item?.key !== "" && item?.value !== ""
  );

  const itemsToRender = showAll
    ? filteredUserList
    : filteredUserList?.slice(0, 2);

  if (filteredUserList?.length === 0) {
    return null;
  }

  return (
    <Grid container gap={2} className="overrideDeletableChip">
      {itemsToRender &&
        itemsToRender.length > 0 &&
        itemsToRender?.map((e, i) => (
          <div className="countChip" key={i}>
            <Chip
              className="chip"
              key={e.key}
              label={e.value}
              onDelete={isiconDel ? () => handleDelete(e.key) : false}
            />
            {isCountChip && (
              <div className="pastVioltion">
                Previous Corrective: {isCount[i]?.count}
              </div>
            )}
          </div>
        ))}
      {filteredUserList?.length > 2 && (
        <Chip
          className="chip"
          label={
            showAll ? "Show Less" : `+ ${filteredUserList?.length - 2} more`
          }
          onClick={() => setshowAll(!showAll)}
        />
      )}
    </Grid>
  );
};

export default DeletableChip;
