import React, { useEffect, useState } from "react";
import "./qrcodelanding.scss";
import Card from "../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { QR_LANDING_CARD } from "../../constant/constant";
import SeqHeader from "./seqHeader";

const SequenceLanding = ({ onClickHandler }) => {
  const queryParams = new URLSearchParams(window.location.search);
  // Retrieve the 'data' parameter from the query string
  const data = queryParams.get("data");
  const decodedData = decodeURIComponent(data); // Decodes the URI encoded JSON string
  const parsedData = JSON.parse(decodedData);
  const id = parsedData?.id;
  const projectNumber = parsedData?.projectNumber;
  const projectName = parsedData?.projectName;
  const clientName = parsedData?.clientname;

  return (
    <>
      <div className="seqPage">
        <SeqHeader
          clientName={clientName}
          ProjectName={projectName}
          ProjectNumber={projectNumber}
        />
        <Grid container className="maincon" mt={10}>
          <div className="qrcodeLanding">
            <Grid container className="CardListContanerLanding">
              <Card
                iconShow={false}
                onClick={(id) => onClickHandler(id, parsedData)}
                imgShow={true}
                cardDetail={QR_LANDING_CARD[0]}
              />
              <Card
                iconShow={false}
                onClick={(id) => onClickHandler(id, parsedData)}
                imgShow={true}
                cardDetail={QR_LANDING_CARD[1]}
              />
              <Card
                iconShow={false}
                onClick={(id) => onClickHandler(id, parsedData)}
                imgShow={true}
                cardDetail={QR_LANDING_CARD[2]}
              />
              <Card
                iconShow={false}
                onClick={(id) => onClickHandler(id, parsedData)}
                imgShow={true}
                cardDetail={QR_LANDING_CARD[3]}
              />
            </Grid>
          </div>
        </Grid>
        <Grid container>
          <Grid lg={12}></Grid>
        </Grid>
      </div>

      <Grid container>
        <Grid lg={12} mt={10}></Grid>
      </Grid>
    </>
  );
};

export default SequenceLanding;
