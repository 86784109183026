import React, { useEffect } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import ViewStatementMapping from "../component/inspection/ViewStatementMapping";
import { useLocation } from "react-router-dom";

const ViewStatementMappingContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const acknowledgment = location?.state?.acknowledgment;
  const defaultQuestionSetData = {
    organisationId: ORG_ID(),
    assetTypeId: "",
    SubClassId: "",
    certifyStatementId: acknowledgment?.key,
    isActive: true,
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    allowPagination: true,
  };

  const questionSetList = useSelector((state) => state.getQuestionSetList);
  const questionSetListData = (data) => {
    dispatch(incpectionAction.getQuestionSetList(data));
  };

  const showLoader = useSelector(
    (state) => state.getQuestionSetList?.isLoading
  );

  useEffect(() => {
    if (acknowledgment) {
      questionSetListData(defaultQuestionSetData);
    }
  }, [acknowledgment]);


  return (
    <>
      <ViewStatementMapping
        questionSetList={questionSetList?.data?.questionsSets}
        questionSetListData={questionSetListData}
        count={questionSetList?.data?.count}
        isLoading={showLoader}
        acknowledgment={acknowledgment}
      />
    </>
  );
};

export default ViewStatementMappingContainer;
