import "./dashboard.scss";
import React, { useState, useEffect, Suspense, lazy } from "react";
import locaibrary from "../../assets/card/assignment.svg";
import {
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  API_STATUS,
  SERVICE_TYPE,
} from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import * as swInspectionAction from "../../redux/storm-water/actions/swInspectionAction";
import {
  DASHBOARD,
  SW_INSPECTION_ADD,
  SW_OPEN_ITEMS,
  SW_INSPECTION_VIEW,
  LANDING_PAGE,
  ADMIN_ADD_NEWS,
  ADMIN_LIST_NEWS,
  SW_INSPECTION_PROOF,
  SW_DAILY_REPORT,
} from "../../constant/routeContant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Button from "../../library/custom/button/Button";
import GridTable from "../../library/custom/gridTable/GridTable";
import SettingTable from "../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../incidents/incidentList/ReportRowManipulte";
import { TableLoader } from "../../library/common/Loader";
import NORow from "../../library/custom/noRow/noRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextArea from "../../library/custom/textArea/TextArea";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import { convertToISODate, getCurrentDate } from "../../utils/helper";
import { checkPrivileges } from "../../utils/rolesHelper";
import tableImg from "../../assets/humidity_mid.svg";
import SiteProjectHeader from "../storm-water/admin/components/siteProjectHeader";
import { PRODUCT_ID } from "../../constant/storm-water/constant";
import * as swCommonAction from "../../redux/storm-water/actions/swCommonAction";
import * as newsAction from "../../redux/actions/newsAction";
import customizeDashIcon from "../../assets/info.svg";
import Loader from "../../library/common/Loader";

const NewsList = lazy(() => import("../admin/component/news/listNews"));

const SwDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [cart, setCart] = React.useState(1);
  const [ismissedOrDiffer, setismissedOrDiffer] = useState("");
  const [isReasondiff, setisReasondiff] = useState(false);
  const [isteam, setisteam] = useState(false);
  const [isteamval, setisteamval] = useState(1);

  const [deffswInsData, setDeffswInsData] = useState({
    inspectionId: "",
    isMissedDeferral: "",
    deferralReasonId: "",
    comment: "",
    userId: 0,
  });

  const [otherDroupdownKey, setOtherDroupdownKey] = useState("");
  const adminView = checkPrivileges([12]);
  const areaView = checkPrivileges([136, 137]);
  // for Service Type code
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const getSwReference = useSelector((state) => state.getSwReference);
  const getNewsList = useSelector((state) => state?.getNewsList);

  const NewsData = getNewsList?.data?.news;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const defaultData = {
      page: 1,
      pageSize: 2,
    };
    dispatch(newsAction.getNewsList(defaultData));
  }, []);

  useEffect(() => {
    if (PRODUCT_ID() == 1) {
      navigate(DASHBOARD);
    }

    if (SERVICE_TYPE() == null && data?.length > 1) {
      navigate(LANDING_PAGE);
    }
  }, []);

  const getDashboardcarddetails = useSelector(
    (state) => state.swDashboardcardDetails
  );
  const DashboardcarddetailsData = getDashboardcarddetails?.data;

  const deferredSwInspectionData = useSelector(
    (state) => state.deferredSwInspection
  );
  const deffInspecrtion_status = deferredSwInspectionData?.status;
  const deffInspecrtion_Isloading = deferredSwInspectionData?.isLoading;

  const todayInspectionCount = DashboardcarddetailsData?.todayInspectionCount;
  const pastDueinspectionCount =
    DashboardcarddetailsData?.pastDueinspectionCount;
  const draftCount = DashboardcarddetailsData?.draftCount;

  const openItemCount = DashboardcarddetailsData?.openItemCount;
  const IsLoadings = getDashboardcarddetails?.isLoading;

  const defaulydata1 = {
    // userId: adminView ? "" : parseInt(USER_ID()),
    userId: areaView
      ? parseInt(USER_ID())
      : adminView
      ? ""
      : parseInt(USER_ID()),
    organizationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    cartType: cart,
    isTeamTask: isteam,
  };
  useEffect(() => {
    if (deffInspecrtion_status == API_STATUS.SUCCESS) {
      swDashboardcardDetail(defaulydata1);
      setOpen(false);
      setisReasondiff(false);
    }
  }, [deferredSwInspectionData]);

  useEffect(() => {
    const defaulydata2 = {
      userId: areaView
        ? parseInt(USER_ID())
        : adminView
        ? ""
        : parseInt(USER_ID()),
      organizationId: ORG_ID(),
      page: 1,
      pageSize: PAGE_SIZE,
      cartType: cart,
      isTeamTask: isteam,
    };
    swDashboardcardDetail(defaulydata2);
  }, [cart, isteam]);
  function swDashboardcardDetail(defaulydata1) {
    dispatch(
      swInspectionAction.getSwDashboardCardDetailsInspection(defaulydata1)
    );
  }
  const swDeferredInspection = (data) => {
    dispatch(swInspectionAction.deferredSwInspection(data));
  };

  const DeferInspectionOnClick = (rows, val) => {
    setismissedOrDiffer(val);
    setisReasondiff(false);
    setOpen(true);
    setDeffswInsData({
      ...deffswInsData,
      inspectionId: rows?.row?.reportId,
      isMissedDeferral: val === 2 ? false : true,
      deferralReasonId: "",
      comment: "",
      userId: USER_ID(),
    });
  };

  const deferralSwInspectionSubmit = (data) => {
    let errors = false;

    if (deffswInsData?.deferralReasonId == "") {
      setisReasondiff(true);
      errors = true;
    }

    if (!errors) {
      swDeferredInspection(deffswInsData);
    }
  };
  const refData = {
    flags: [7],
  };
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };

  useEffect(() => {
    getSwReferenceData(refData);
  }, []);
  const regAgencyData = getSwReference?.data?.reasonForDeferralList;

  useEffect(() => {
    const updateList = regAgencyData?.filter(
      (list) => list?.value?.toLowerCase() === "other"
    );
    if (updateList?.length > 0) {
      setOtherDroupdownKey(updateList[0]?.key);
    }
  }, [regAgencyData]);

  const handleEditOnClick = (row) => {
    navigate(SW_INSPECTION_ADD, {
      state: {
        row: row,
        editMode: true,
      },
    });
  };
  const handleViewOnClick = (row) => {
    navigate(SW_INSPECTION_VIEW, {
      state: {
        inspectionId: row?.reportId,
      },
    });
  };

  const conductExternalOnClick = (row) => {
    window.open(row?.externalFormUrl, "_blank")?.focus();
  };
  const addProofOnClick = (row) => {
    navigate(SW_INSPECTION_PROOF, {
      state: {
        inspectionId: row?.reportId,
      },
    });
  };

  const handleAddOnClick = (row) => {
    if (row?.questionSetId === 0 && !row?.isExternal) {
      navigate(SW_DAILY_REPORT, {
        state: {
          row: row,
        },
      });
    } else {
      navigate(SW_INSPECTION_ADD, {
        state: {
          row: row,
          addMode: true,
          addDirectInspection: false,
        },
      });
    }
  };
  const columns = [
    {
      field: "projectNumber",
      headerName: "Project No.",
      flex: 0.7,
      hide: false,
    },
    {
      field: "customer",
      headerName: "Client",
      flex: 1.5,
      hide: false,
      renderCell: (cellValues) => {
        return (
          <>
            <div className="swdashCustomer">
              <div className="sw-customer">{cellValues?.value}</div>
            </div>
          </>
        );
      },
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1.5,
      hide: false,
      renderCell: (cellValues) => {
        return (
          <>
            <div className="swdashCustomer">
              <div className="sw-sample-icon">
                {cellValues?.row?.sampleRequired ? (
                  <div>
                    {" "}
                    <img src={tableImg} alt="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="sw-customer">{cellValues?.value}</div>
            </div>
          </>
        );
      },
    },
    {
      field: "dateSchedule",
      headerName: "Date Scheduled",
      flex: 0.9,
      hide: false,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1.5,
      hide: false,
      renderCell: (rows) => {
        return (
          <>
            <div>
              {rows?.row?.questionSetId === 0 && !rows?.row?.isExternal ? (
                "Daily Report"
              ) : (
                <>
                  {rows?.row?.frequency}{" "}
                  {rows?.row?.frequencyId === 5 &&
                  rows?.row?.isFluid &&
                  rows?.row?.insTerm
                    ? `(${rows?.row?.insTerm})`
                    : ""}
                  {rows?.row?.frequencyId === 7 ? (
                    <span
                      className="frequencyInDays"
                      title={rows?.row?.frequencyInDays}
                    >
                      {"- " + rows?.row?.frequencyInDays}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </>
        );
      },
    },
    { field: "openItems", headerName: "Open Items", flex: 0.8, hide: false },  
    {
      field: "assignedInspectorName",
      headerName: "Inspector Name",
      flex: 0.8,
      hide: true,
    }, 
    {
      field: "inspectionType",
      headerName: "Inspection Type",
      flex: 0.9,
      hide: false,
    },
    { field: "rainfall", headerName: "Rainfall", flex: 0.7, hide: false },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={DashboardcarddetailsData?.listDetails?.length ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={DashboardcarddetailsData?.listDetails ?? []}
          fileNameXL={"SwInspectionList"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        const currentDate = new Date(getCurrentDate());
        const row = rows && rows.row;

        const dateScheduleString = row?.dateSchedule;
        const formattedDateSchedule = dateScheduleString ? convertToISODate(dateScheduleString) : null;
        const dateSchedule = new Date(formattedDateSchedule);

        const isDeferVisible =
          row?.isFluid && row?.deferredCount >= 2
            ? false
            : dateSchedule <= currentDate &&
              row?.statusId !== 1 &&
              row?.frequencyId !== 1 &&
              (row?.frequencyId !== 5 || !row?.insTerm) &&
              checkPrivileges([12, 136, 137]);

        const isMissedVisible =
          row?.isFluid && row?.deferredCount >= 2
            ? false
            : dateSchedule < currentDate &&
              row?.statusId !== 1 &&
              row?.frequencyId !== 1 &&
              (row?.frequencyId !== 5 || !row?.insTerm) &&
              checkPrivileges([12, 136, 137]);

        const isAddButtonVisible =
          !row?.isExternal &&
          row?.statusId === 8 &&
          dateSchedule <= currentDate;

        const addButtonLabel =
          row?.questionSetId === 0 && !row?.isExternal
            ? "Add Report"
            : "Add Inspection";

        const isEditVisible = !row?.isExternal && row?.statusId === 1;
        const isViewVisible = !row?.isExternal && row?.statusId === 1;

        const canConductExternal = row?.isExternal && row?.statusId === 8;

        return (
          <ReportRowManipulte
            rows={rows}
            defer={isDeferVisible}
            deferOnClick={() => DeferInspectionOnClick(rows, 2)}
            deferLabel="Defer Inspection"
            missed={isMissedVisible}
            missedOnClick={() => DeferInspectionOnClick(rows, 1)}
            missedLabel="Missed Deferral"
            addBtn={isAddButtonVisible}
            addBtnLabel={addButtonLabel}
            addForm={() => handleAddOnClick(row)}
            edit={isEditVisible}
            editLabel="Edit Inspection"
            editOnClick={() => handleEditOnClick(row)}
            view={isViewVisible}
            viewLabel="View Inspection"
            viewOnClick={() => handleViewOnClick(row)}
            conductExternal={canConductExternal}
            conductExternalOnClick={() => conductExternalOnClick(row)}
            conductExternalLabel="Conduct Inspection"
            addProof={canConductExternal}
            addProofOnClick={() => addProofOnClick(row)}
            addProofLabel="Certify Completion"
          />
        );
      },
    },
  ];
  if (cart === 2) {
    columns.find((column) => column.field === "openItems").hide = true;
    columns.find((column) => column.field === "rainfall").hide = true;
  }
  if (cart === 3) {
    columns.find((column) => column.field === "openItems").hide = true;
    columns.find((column) => column.field === "rainfall").hide = true;
  }
  if (checkPrivileges([136, 137]) && isteamval === 2) {
    columns.find(
      (column) => column.field === "assignedInspectorName"
    ).hide = false;
  }

  const inspectionduedashboard = (id) => {
    setCart(id);
  };

  const getRowClassName = (params) => {
    const currentDate = getCurrentDate();
    const scheduledDate = new Date(params.row?.dateSchedule);
    const statusId = params.row?.statusId;
    if (
      params.row?.isFluid &&
      params.row?.frequencyId === 5 &&
      params.row?.insTerm === "1" &&
      scheduledDate < new Date(currentDate) &&
      new Date(currentDate).getDay() === 5
    ) {
      return "highlight-post-missedInspection";
    }
    if (
      params.row?.isFluid &&
      params.row?.frequencyId === 5 &&
      scheduledDate < new Date(currentDate) &&
      new Date(currentDate).getDay() === 6
    ) {
      return "highlight-post-missedInspection";
    }
    if (
      scheduledDate < new Date(currentDate) &&
      statusId !== 1 &&
      (!params.row?.isFluid ||
        (params.row?.frequencyId !== 5 && params.row?.isFluid))
    ) {
      return "highlight-post-missedInspection";
    } else if (statusId === 1) {
      return "highlight-post-Draft";
    }

    return "";
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);

    const data = {
      userId: areaView
        ? parseInt(USER_ID())
        : adminView
        ? ""
        : parseInt(USER_ID()),
      organizationId: ORG_ID(),
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      cartType: cart,
      isTeamTask: isteam,
    };
    swDashboardcardDetail(data);
  };
  const cancelinspection = (e) => {
    setOpen(false);
  };

  const createNews = () => {
    navigate(ADMIN_ADD_NEWS);
  };
  const listNews = () => {
    navigate(ADMIN_LIST_NEWS);
  };
  const swTeamtabclick = (val) => {
    setisteamval("");
    setisteam(false);
    if (val === 2) {
      setisteam(true);
      setisteamval(2);
    } else {
      setisteam(false);
      setisteamval(1);
    }
  };
  const dataHeader = {
    isdashboard: true,
    menu: [
      {
        text: "My Tasks",
        isshow: checkPrivileges([136, 137]) ? true : false,
        id: 1,
      },
      {
        text: "Team Tasks",
        isshow: checkPrivileges([136, 137]) ? true : false,
        id: 2,
      },
    ],
  };

  return (
    <>
      {/* {IsLoadings && <Loader />} */}
      <section className="grid_main_sec">
        <div className="grid_main_body ">
          <div className="commingsoon">
            <div className="headingcustomige">
              <h1 className="heading">Dashboard</h1>
            </div>
            <Grid container className="swdashboard-header">
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <SiteProjectHeader
                  dataHeader={dataHeader}
                  swTeamtabclickFun={swTeamtabclick}
                  isteam={isteam}
                  isteamval={isteamval}
                />
              </Grid>
            </Grid>

            <Grid container mt={5} className="todayInspection">
              {" "}
              <h1 className="heading">Today’s Inspections</h1>
            </Grid>

            <Dialog onClose={handleClose} open={open}>
              <div className="diologSwDashboard">
                <DialogContent lg={6} xs={12} sm={6} md={4}>
                  <div className="desc_box row_uniqSwDash">
                    <div className="table_style_ui_swDash">
                      <Grid item lg={6} xs={12} sm={12} md={6} mt={1}>
                        <FormControl fullWidth>
                          <FormLabels
                            label={
                              ismissedOrDiffer === 1
                                ? "Reason for Missed Deferral"
                                : "Reason for Deferral"
                            }
                            isRequired={false}
                          />
                          <SelectMenu
                            listData={regAgencyData || []}
                            value={deffswInsData?.deferralReasonId}
                            onchangehandler={(e) => {
                              setDeffswInsData({
                                ...deffswInsData,
                                deferralReasonId: parseInt(e.target.value),
                                comment: "",
                              });
                              setisReasondiff(false);
                            }}
                            placeholder="Please Select"
                            isError={isReasondiff}
                            errorMsg={
                              isReasondiff
                                ? "Selection is required for this field "
                                : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                      {deffswInsData?.deferralReasonId == otherDroupdownKey && (
                        <Grid mt={3} spacing={2} lg={12} md={4}>
                          <FormLabels isRequired={false} />

                          <TextArea
                            name={"issuefound"}
                            value={deffswInsData?.comment}
                            onChange={(e) => {
                              setDeffswInsData({
                                ...deffswInsData,
                                comment: e.target.value,
                              });
                            }}
                            isError={false}
                          />
                        </Grid>
                      )}

                      <Grid container mt={3}>
                        <Grid item lg={6}>
                          <div className="swcancel">
                            <Button
                              varientText={true}
                              label="Cancel"
                              onClick={(e) => cancelinspection(e)}
                            />
                          </div>
                        </Grid>

                        <Grid item lg={6}>
                          <div className="swsave">
                            <Button
                              varientContained={true}
                              label={"Save"}
                              onClick={() => deferralSwInspectionSubmit()}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </DialogContent>
              </div>
            </Dialog>

            <Grid
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="swdashboardBody"
            >
              <Grid item lg={2.7} xs={5.7} sm={5.7} md={2.7}>
                <div
                  className={`dashboardCardBody  ${
                    cart === 1 ? "selected" : ""
                  }`}
                  onClick={() => {
                    inspectionduedashboard(1);
                  }}
                >
                  <div className="icon">
                    <img src={locaibrary} alt="Inspections Due"></img>
                  </div>
                  <div className="text">Inspections Due</div>
                  <div className="number">
                    {todayInspectionCount ? todayInspectionCount : 0}
                  </div>
                </div>
              </Grid>
              <Grid item lg={2.7} xs={5.7} sm={5.7} md={2.7}>
                <div
                  className={`dashboardCardBody  ${
                    cart === 2 ? "selected" : ""
                  }`}
                  onClick={() => {
                    inspectionduedashboard(2);
                  }}
                >
                  <div className="icon">
                    <img src={locaibrary} alt="Past Due Inspections"></img>
                  </div>
                  <div className="text">Past Due Inspections</div>
                  <div className="number resourse">
                    {pastDueinspectionCount ? pastDueinspectionCount : 0}
                  </div>
                </div>
              </Grid>
              <Grid item lg={2.7} xs={5.7} sm={5.7} md={2.7}>
                <div
                  className={`dashboardCardBody  ${
                    cart === 3 ? "selected" : ""
                  }`}
                  onClick={() => {
                    inspectionduedashboard(3);
                  }}
                >
                  <div className="icon">
                    <img src={locaibrary} alt="Draft"></img>
                  </div>
                  <div className="text">Drafts</div>
                  <div className="number draft">
                    {draftCount ? draftCount : 0}
                  </div>
                </div>
              </Grid>
              <Grid item lg={2.7} xs={5.7} sm={5.7} md={2.7}>
                <div
                  className="dashboardCardBody"
                  onClick={() => {
                    navigate(SW_OPEN_ITEMS);
                  }}
                >
                  <div className="icon">
                    <img src={locaibrary} alt="Open Items"></img>
                  </div>
                  <div className="text">Open Items</div>
                  <div className="number openitem">
                    {openItemCount ? openItemCount : 0}
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="desc_box row_uniq ">
              <div className="table_style_ui ">
                <GridTable
                  getRowId={(r) => r?.reportId}
                  rows={DashboardcarddetailsData?.listDetails ?? []}
                  rowCount={
                    cart === 1
                      ? todayInspectionCount
                      : cart === 2
                      ? pastDueinspectionCount
                      : cart === 3
                      ? draftCount
                      : openItemCount
                  }
                  columns={columns}
                  loading={deffInspecrtion_Isloading || IsLoadings}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  RowClassName={getRowClassName}
                />
              </div>
            </div>
          </div>
        </div>
        <Grid mt={3}>
          <div className="headingcustomigeCreateNews">
            {checkPrivileges([12, 87, 88]) && (
              <>
                <h1 className="heading1">Trending News</h1>

                <div onClick={createNews} className="customizedash">
                  <p>Create News/Announcement</p>
                  <img src={customizeDashIcon} alt="Customize Icon"></img>
                </div>
              </>
            )}
          </div>
          {checkPrivileges([12, 87, 88, 89]) && (
            <Suspense fallback={<div>please wait.....</div>}>
              <NewsList NewsData={NewsData} />
            </Suspense>
          )}

          {checkPrivileges([12, 87, 88, 89]) && (
            <Grid container item justifyContent="right" lg={12} mt={5}>
              <div className="seeall" onClick={listNews}>
                <p>See All</p>
              </div>
            </Grid>
          )}
        </Grid>
      </section>
    </>
  );
};

export default SwDashboard;
