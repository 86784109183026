import React from "react";
import "./reportDetail.scss";

const ReportDetail = (props) => {
  const reportShow = props?.reportShow ?? false;

  return (
    <>
      <div className="oshaOtherDetailContainer">
        <div className="oshaOtherList">
          <div className="oshaOtherListTitle">Number of Cases</div>
          <div className="oshaOtherListBottom">
            <div className="oshaOtherListBottomBox">
              <div className="oshaOtherListBottomPara">
                Total number of deaths:
                <span>{props?.reportData?.caseNumberOfDeath}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Total number of cases with days away from work:
                <span>{props?.reportData?.caseNumberOfDaysAway}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Total number of cases with job transfer or restrictions:
                <span>{props?.reportData?.caseNumberOfTransfer}</span>
              </div>

              {/* {reportShow && ( */}
              <div className="oshaOtherListBottomPara">
                Total number of other recordable cases:
                <span>{props?.reportData?.caseNumberOtherRecordable}</span>
              </div>
              <div className="oshaOtherListBottomPara highlight">
                Total recordable cases:
                <span>
                  {props?.reportData?.caseNumberOfDeath +
                    props?.reportData?.caseNumberOfDaysAway +
                    props?.reportData?.caseNumberOfTransfer +
                    props?.reportData?.caseNumberOtherRecordable}
                </span>
              </div>
              {/* )} */}
            </div>

          </div>
        </div>
        <div className="oshaOtherList">
          <div className="oshaOtherListTitle">Number of Days</div>
          <div className="oshaOtherListBottom">
            <div className="oshaOtherListBottomBox">
              <div className="oshaOtherListBottomPara">
                Total number of days away from work:
                <span>{props?.reportData?.numberOfDaysAway}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Total number of days of job transfer or restriction:
                <span>{props?.reportData?.numberOfTransfer}</span>
              </div>
              {/* {reportShow && ( */}
              <div className="oshaOtherListBottomPara highlight">
                Total number of days:
                <span>
                  {props?.reportData?.numberOfTransfer +
                    props?.reportData?.numberOfDaysAway}
                </span>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="oshaOtherList">
          <div className="oshaOtherListTitle">Injury and Illness Type</div>
          <div className="oshaOtherListBottom">
            <div className="oshaOtherListBottomBox">
              <div className="oshaOtherListBottomPara">Total number of:</div>
              <div className="oshaOtherListBottomPara">
                Injuries:<span>{props?.reportData?.injuries}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Skin Disorder:
                <span>{props?.reportData?.skinDisorder}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Respiratory Conditions:{" "}
                <span>{props?.reportData?.respiratoryCondition}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Poisonings:
                <span>{props?.reportData?.poisoning}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                Hearing Loss:
                <span>{props?.reportData?.hearingLoss}</span>
              </div>
              <div className="oshaOtherListBottomPara">
                All Other Illness:
                <span>{props?.reportData?.otherIllnesses}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDetail;
