import React, { useEffect } from "react";
import SwProjectList from "../components/swProjectList";
import SiteProjectHeader from "../components/siteProjectHeader";
import {
  ADMIN_ADD_SITE,
  ADMIN_SITE_PROJECTS_LIST,
  ADMIN_PROJECTS_LIST,
  ADMIN_ADD_STORM_WATERPROJECT,
  ACCESS_DENIED,
} from "../../../../constant/routeContant";
import { useDispatch, useSelector } from "react-redux";
import * as swprojectAction from "../../../../redux/storm-water/actions/projectAction";
import { PAGE_SIZE, ORG_ID, LOGIN_ORG_ID } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import * as clientAction from "../../../../redux/actions/clientAction";
import * as siteAction from "../../../../redux/storm-water/actions/siteAction";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SwProjectListContainer({ isShow = true }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const divisionId = location?.state?.divisionId;
  const dataHeader = {
    title: LOGIN_ORG_ID() == 1 ? "Sites and Projects" : "Projects",
    btnLink: ADMIN_ADD_STORM_WATERPROJECT,
    menu: [
      {
        route: ADMIN_SITE_PROJECTS_LIST,
        text: "Sites",
        isshow: checkPrivileges([12, 108, 109, 110]),
      },
      {
        route: ADMIN_PROJECTS_LIST,
        text: "Projects",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
    ],
  };
  if (checkPrivileges([12, 111, 112])) {
    dataHeader.btnText = "Add Project";
  }
  // LOGIN_ORG_ID() == 1 &&
  //   checkPrivileges([12, 108, 109, 110]) &&
  //   dataHeader.menu.unshift({
  //     route: ADMIN_SITE_PROJECTS_LIST,
  //     text: "Sites",
  //   });

  const swprojectListData = useSelector((state) => state?.swprojectList);
  const swProjectList = (data) => {
    dispatch(swprojectAction.swProjectList(data));
  };
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    customerId: ORG_ID() == 1 ? 0 : ORG_ID(),
    startDate: "",
    endDate: "",
    search: "",
    siteId: "",
    status: "",
    divisionId: divisionId ?? "",
  };
  useEffect(() => {
    swProjectList(defaultData);
    dispatch(swprojectAction.clearsWProject());
    dispatch(swprojectAction.clearsWProjectUpdate());
  }, []);
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const searchSitedata = useSelector(
    (state) => state?.siteListAutoSearch?.data
  );
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const searchSite = (searchText) => {
    dispatch(siteAction.getSiteAutoSearchList(searchText));
  };

  const deleteProjectData = useSelector((state) => state.deleteSWProject);

  const deleteSWProject = (projectId) => {
    dispatch(swprojectAction.deleteProject(projectId));
  };

  useEffect(() => {
    if (deleteProjectData.status === 200) {
      swProjectList(defaultData);
    }
  }, [deleteProjectData]);

  const isLoading = swprojectListData?.isLoading;

  return (
    <>
      {checkPrivileges([111, 112, 113, 12]) ? (
        <>
          {isShow && <SiteProjectHeader dataHeader={dataHeader} />}

          <SwProjectList
            swProjectLists={swProjectList}
            swProjectListData={swprojectListData}
            isLoading={isLoading}
            searchClient={searchClient}
            autoSearchDataLoading={autoSearchDataLoading}
            searchAllClientsReducerData={searchAllClientsReducerData}
            onSearchSite={searchSite}
            searchSitedata={searchSitedata}
            divisionId={divisionId}
            deleteSWProject={deleteSWProject}
          />
          <ToastContainer />
        </>
      ) : (
        Navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default SwProjectListContainer;
