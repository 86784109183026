import React, { useState } from "react";
import "../../incidents/cases/addCaseList.scss";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../library/custom/button/Button";
import SettingTable from "../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../library/common/Loader";
import { ISwitch, PAGE_SIZE } from "../../../constant/constant";
import TableRowAction from "../../../library/custom/gridTable/TableRowAction";
import NORow from "../../../library/custom/noRow/noRow";
import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { checkPrivileges } from "../../../utils/rolesHelper";

const EstablishmentList = (props) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: searchText ? searchText : "",
      page: page ? page : 1,
      pageSize: PAGE_SIZE,
    };
    props?.getOSHAEstablishmentListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      search: search ? search : "",
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    };
    props?.getOSHAEstablishmentListData(data);
  };

  const handleChange = (items) => {
    props.setStatusShow(true);
    const data = {
      establishmentId: items?.establishmentId,
      status: !items?.isActive,
    };
    props?.updateEstablishmentStatusData(data);
  };

  const columns = [
    { field: "establishmentName", headerName: "Establishment", flex: 1.5 },
    { field: "street", headerName: "Street", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    {
      field: "industryDescription",
      headerName: "Industry Description",
      flex: 2,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.industryDescription
              ? rows?.row?.industryDescription
              : "N/A"}
          </>
        );
      },
    },
    {
      field: "sic",
      headerName: "SIC",
      flex: 1,
      renderCell: (rows) => {
        return <>{rows?.row?.sic ? rows?.row?.sic : "N/A"}</>;
      },
    },
    { field: "naics", headerName: "NAICS", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (rows) => {
        return (
          <>
            <Grid className="catListBox">
              <Grid className="closeIcon">
                <FormControl component="fieldset" variant="standard">
                  <FormGroup className="isActiveBox">
                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={
                            rows?.row.isActive ? rows?.row.isActive : false
                          }
                          onChange={() => handleChange(rows?.row)}
                          name="status"
                        />
                      }
                    />

                    <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={props?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={props?.establishmentList ?? []}
          fileNameXL={"EstablishmentList"}
        />
      ),
      flex: 1,
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            edit
            editLabel="Edit Establishment"
            editOnClick={(row) =>
              props?.editEstablishment(row?.establishmentId)
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style"></div>
          <div>
            {checkPrivileges([12, 152, 153]) && (
              <Buttons
                id="btnAddDivision"
                label={"Add Establishment"}
                varientAddIconBlue={true}
                onClick={() => props?.addEstablishment()}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r?.establishmentId}
                columns={columns}
                rows={props?.establishmentList ?? []}
                rowCount={props?.itemsCount ?? 0}
                loading={props?.isLoading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                rowsPerPageOptions={[10]}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                onPageChange={handlePagignation}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EstablishmentList;
