import React, { useState } from "react";
import "./qrcodelanding.scss";
import SeqHeader from "./seqHeader";
import SettingTable from "../../feature/incidents/incidentList/SettingTable";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../constant/constant";
import GridTable from "../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../library/common/Loader";
import NORow from "../../library/custom/noRow/noRow";
import commonImages from "../../assets";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";

const SequenceSamplingLogs = (props) => {
  const clientName = props?.clientName;
  const ProjectName = props?.projectName;
  const ProjectNumber = props?.projectNumber;

  const [showCopyMsg, setshowCopyMsg] = useState();
  const [copyreport, setCopyreport] = useState("");
  const samplingLogData = props?.samplingLogData?.data?.sampleLog;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const copyReportUrl = (id, val) => {
    let sampleid = id?.sampleId;
    setshowCopyMsg(sampleid);
    setCopyreport(val);
    navigator.clipboard.writeText(val);
    setTimeout(function () {
      setshowCopyMsg();
    }, 1000);
  };
  const columns = [
    { field: "sampleDate", headerName: "Sample Date", flex: 3, minWidth: 120 },
    {
      field: "report",
      headerName: "Report",
      flex: 4,
      minWidth: 300,
      renderCell: (cellValues) => {
        let cellval = cellValues;

        return (
          <>
            <div className="nameBox12">
              <div className="nameImg12">
                <p>
                  {cellval?.row?.report ? (
                    <div className="checkreport">
                      <div className="checkreport1">
                        {cellval?.row?.report}{" "}
                      </div>
                      <div className="checkreport1sample">
                        <img
                          src={commonImages?.contentcopy}
                          onClick={(e) =>
                            copyReportUrl(cellval?.row, cellval?.row?.report)
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        {showCopyMsg === cellval?.row?.sampleId && (
                          <p className="custom-tooltip">copied!</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          </>
        );
      },
    },

    {
      field: " ",
      flex: 0.9,
      minWidth: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    props?.getSequenceSamplingLogs({
      page: newPage + 1,
      pageSize: pageSize,
      projectId: props?.projectId,
    });
  };
  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    props?.getSequenceSamplingLogs({
      page: page,
      pageSize: pageSize,
      projectId: props?.projectId,
    });
  };

  return (
    <>
      {" "}
      <SeqHeader
        clientName={clientName}
        ProjectName={ProjectName}
        ProjectNumber={ProjectNumber}
      />
      <section className="grid_main_sec">
        <div className="gridMainpermit">
          <Grid className="permitBox" lg={12} mb={2}>
            <Grid className="grid_main_header two_grid" lg={12}>
              <Grid lg={8} className="grid_heading_style" space={2}>
                Sampling Log
              </Grid>
            </Grid>
          </Grid>
          <div className="grid_main_body">
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.sampleId}
                  rows={samplingLogData ?? []}
                  rowCount={props?.samplingLogData?.data?.itemsCount ?? 0}
                  columns={columns}
                  // loading={props?.samplingLogData?.isLoading}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          </div>

          <Grid container>
            <QrBackBtn text={"Back"} />
          </Grid>
        </div>
      </section>
    </>
  );
};

export default SequenceSamplingLogs;
