import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { SOILLIST } from "../../constant/storm-water/constant";
import commonImages from "../../assets";
import { INSPECTION_STATUS } from "../../constant/constant";
import NewOpenItem from "../../feature/storm-water/inspection/components/newOpenItem";
import PreviousOpenItem from "../../feature/storm-water/inspection/components/previousOpenItem";
import DailyReportView from "../../feature/storm-water/inspection/components/dailyReportView";
import "./qrInspectionReport.scss";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";

const SequenceInspectionView = ({
  inspectionDetail,
  getInTyFeByInTyList,
  createdOnDate,
}) => {
  const findCount = (detailCont) => {
    const statusCount = detailCont?.reduce((count, obj) => {
      if (obj.status === INSPECTION_STATUS?.Open) {
        return count + 1;
      }
      return count;
    }, 0);
    return <div>{statusCount}</div>;
  };
  const [currentMonthName, setCurrentMonthName] = useState();
  useEffect(() => {
    const currentDate =
      inspectionDetail?.dailyReportInspectionDetails &&
      inspectionDetail?.dailyReportInspectionDetails[0]?.date;
    const dateObject = new Date(currentDate);
    const findMonthName = dateObject?.toLocaleString("default", {
      month: "long",
    });
    setCurrentMonthName(findMonthName);
  }, [inspectionDetail]);

  const dailyReportHeader = {
    projectName: inspectionDetail?.projectName,
    projectLocation: inspectionDetail?.address,
    monthOfInspection: currentMonthName,
    logId: inspectionDetail?.report_Id,
  };
  const matchedSoil = SOILLIST?.find(
    (soil) => soil.key === inspectionDetail?.soilTypeId
  );
  return (
    <Grid px={5} className="InspectionQRContainer">
      <Grid
        mb={3}
        style={{
          fontFamily: "lato",
          fontSize: "30px",
          color: "#333333",
          fontWeight: "600",
          textAlign: "left",
        }}
      >
        View Inspection Report
      </Grid>
      <div className="pdfInnerOverRide">
        {inspectionDetail?.dailyReportInspectionDetails !== null && (
          <DailyReportView
            dailyReportInspectionDetails={
              inspectionDetail?.dailyReportInspectionDetails
            }
            dailyReportHeader={dailyReportHeader}
          />
        )}
        {inspectionDetail?.dailyReportInspectionDetails === null && (
          <>
            <div className="overReportHeader">
              <Grid container className="reportHeader  ">
                <Grid md={6} sm={6} xs={12} className="reportHeaderLeft">
                  <div className="reportTitle inspectionHeader">
                    Report ID: <span>{inspectionDetail?.report_Id}</span>
                  </div>
                  {getInTyFeByInTyList?.map((field) => {
                    if (field?.key === 1 && field.isSelected) {
                      return (
                        <div
                          className="reportTitle inspectionHeader"
                          key={field.key}
                        >
                          Project Name:{" "}
                          <span>{inspectionDetail?.projectName}</span>
                        </div>
                      );
                    }
                  })}
                </Grid>
                <Grid md={4} sm={4} xs={4} className="reportHeaderLeft right">
                  {/* <div className="reportTitle right">
                  {inspectionDetail?.inspectionType}
                </div> */}
                </Grid>
                <Grid md={2} sm={2} xs={8} className="reportHeaderLeft right">
                  <div className="hbLogo">
                    <img
                      src={
                        inspectionDetail?.clientLogo
                          ? inspectionDetail?.clientLogo
                          : commonImages?.hbNextLogo
                      }
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="basicOveride">
              <Box className="basicinfoWrap removeBorderRadius">
                <Box className="basicinfoBox">
                  <Box className="basicinfoleft sw">
                    <div className="info-report">
                      <Typography className="title removeInPdf">
                        Basic Information:
                      </Typography>
                    </div>

                    {inspectionDetail?.questionSetName && (
                      <Typography className="listView removeInPdf">
                        Question Set Name:
                        <span className="focusedText">
                          {inspectionDetail?.questionSetName}
                        </span>
                      </Typography>
                    )}
                    <Typography className="listView">
                      Frequency:
                      <span>{inspectionDetail?.frequency}</span>
                    </Typography>

                    {inspectionDetail?.frequency?.toLowerCase() ===
                      "custom" && (
                      <Typography className="listView">
                        Frequency In Days:
                        <span>{inspectionDetail?.frequencyInDays}</span>
                      </Typography>
                    )}

                    <Grid container item>
                      {getInTyFeByInTyList?.map((field) => {
                        if (field?.key === 9 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field.key}>
                              <Typography className="listView printListView">
                                Client:
                                <span>{inspectionDetail?.clientName}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 8 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field.key}>
                              <Typography className="listView printListView">
                                Report ID:
                                <span>{inspectionDetail?.report_Id}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 12 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field.key}>
                              <Typography className="listView">
                                Inspection Type:
                                <span>{inspectionDetail?.inspectionType}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 2 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Project Number:
                                <span>{inspectionDetail?.projectNumber}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 13 && field.isSelected) {
                          return (
                            <Grid item md={12} sm={12} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Inspected by:
                                <span className="focusedText">
                                  {inspectionDetail?.inspectorName}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 10 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Date of Inspection:
                                <span>
                                  {inspectionDetail?.dateOfInspection}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 3 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Time of Inspection:
                                <span>
                                  {inspectionDetail?.timeOfInspection}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 4 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Weather at Time of Inspection:
                                <span>
                                  {inspectionDetail?.weather
                                    ? inspectionDetail?.weather
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 17 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Soil:
                                <span>
                                  {matchedSoil?.value
                                    ? matchedSoil?.value
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 14 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Stage of Construction:
                                <span>
                                  {parse(
                                    unescape(
                                      inspectionDetail?.constructionStage
                                        ? inspectionDetail?.constructionStage
                                        : "N/A"
                                    )
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 6 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Sample Taken:
                                <span>
                                  {inspectionDetail?.isSampleTaken
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 15 && field.isSelected) {
                          return (
                            <Grid item md={12} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Primary Permittee Name:
                                {inspectionDetail?.listPermitte?.length > 0 ? (
                                  inspectionDetail?.listPermitte?.map(
                                    (x, index) => {
                                      return (
                                        <span key={index}>
                                          {x.primaryPermitteName} {", "}
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>{" N/A"}</span>
                                )}
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 5 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Rainfall Amount (inches):
                                <span>
                                  {inspectionDetail?.rainfall !== null
                                    ? inspectionDetail?.rainfall
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 16 && field.isSelected) {
                          return (
                            <Grid item md={12} sm={12} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Current Approximate Disturbed Area:
                                <span>
                                  {parse(
                                    unescape(
                                      inspectionDetail?.disturbedAcreage
                                        ? inspectionDetail?.disturbedAcreage
                                        : "N/A"
                                    )
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 7 && field.isSelected) {
                          return (
                            <Grid item md={12} xs={12} key={field?.key}>
                              <Typography className="listView">
                                Permit Number:
                                {inspectionDetail?.listPermitte?.length > 0 ? (
                                  inspectionDetail?.listPermitte?.map(
                                    (x, index) => {
                                      return (
                                        <span key={index}>
                                          {x.permitteOrNPDESNumber} {", "}
                                        </span>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>{" N/A"}</span>
                                )}
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 19 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Site Name:
                                <span>
                                  {inspectionDetail?.isStandAloneProject ===
                                  false
                                    ? inspectionDetail?.siteName
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 18 && field.isSelected) {
                          return (
                            <Grid item md={12} sm={12} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Construction Activity:
                                <span>
                                  {inspectionDetail?.constructionActivity
                                    ? inspectionDetail?.constructionActivity
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 20 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Division:
                                <span>{inspectionDetail?.divisionName}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 21 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Inspector Phone Number:
                                <span>
                                  {inspectionDetail?.inspectorContactNumber}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 22 && field.isSelected) {
                          return (
                            <Grid item md={12} sm={12} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Company Preforming Inspection:
                                <span>
                                  {inspectionDetail?.inspectorCompanyName}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 23 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Project Status:
                                <span>
                                  {inspectionDetail?.projectStatus
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 24 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                state:
                                <span>{inspectionDetail?.state}</span>
                              </Typography>
                            </Grid>
                          );
                        } else if (field?.key === 25 && field.isSelected) {
                          return (
                            <Grid item md={6} sm={6} xs={12} key={field?.key}>
                              <Typography className="listView desNone borderAdd">
                                Address:
                                <span>
                                  {inspectionDetail?.address
                                    ? inspectionDetail?.address
                                    : "N/A"}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                    {inspectionDetail?.comment && (
                      <Typography className="listView desNone">
                        General Comments:
                        <span>
                          {parse(unescape(inspectionDetail?.comment))}
                        </span>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </div>

            {inspectionDetail?.categoriesView?.map((item, i) => {
              return (
                <>
                  {item?.skipped === true ? (
                    <div className="overSkipView" key={item?.key}>
                      <Grid container className="skipView categorySkip">
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          className="skipViewLeft"
                        >
                          <div className="skipViewTitle">{item?.value}</div>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          className="skipViewRight"
                        >
                          <div className="skipViewDesc">Skipped</div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <Grid container className="categoriesView">
                      <Grid key={i} item md={12} className="categoriesTitle">
                        <Grid>{item?.value}</Grid>
                      </Grid>

                      {item?.subcategoriesView?.map((subItem, i) => {
                        return (
                          <>
                            {subItem?.skipped === true ? (
                              <>
                                <div
                                  className="overSkipView"
                                  key={subItem?.key}
                                >
                                  <Grid
                                    container
                                    className="skipView subCategorySkip"
                                  >
                                    <Grid
                                      item
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="skipViewLeft"
                                    >
                                      <div className="skipViewTitle">
                                        {subItem?.value}
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="skipViewRight"
                                    >
                                      <div className="skipViewDesc">
                                        Skipped
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : (
                              <>
                                <Grid
                                  key={i}
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="subCategoriesTitle subCatTitleprint"
                                >
                                  <Grid>{subItem?.value}</Grid>
                                </Grid>

                                <div className="subCateViewBox">
                                  {subItem?.insQuesAnsReportView?.map(
                                    (inQuestion, i) => {
                                      const filterPreviousData =
                                        inQuestion?.previousOpenItemDetails?.filter(
                                          (item) =>
                                            item.status === 2 ||
                                            item.dateClosed === createdOnDate
                                        );

                                      return (
                                        <>
                                          {inQuestion?.answerMstId === 1 && (
                                            <Grid
                                              key={i}
                                              item
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="subCateView"
                                            >
                                              <div className="commonInsOveride">
                                                <Grid className="commoninfoWrap">
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sm={12}
                                                    className="listViewBox"
                                                  >
                                                    <div className="listDetailBox">
                                                      <div className="listView questionDetail">
                                                        <div className="questions">
                                                          <div>
                                                            <span>
                                                              {
                                                                inQuestion?.quesNo
                                                              }
                                                              {"."}{" "}
                                                              {
                                                                inQuestion?.question
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="questionitem">
                                                          <div>
                                                            {inQuestion?.answerMstId ===
                                                              1 && (
                                                              <>
                                                                <div className="acceptableBtn commonBtn">
                                                                  Acceptable
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            </Grid>
                                          )}
                                          {inQuestion?.answerMstId === 2 && (
                                            <>
                                              <Grid
                                                key={i}
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="subCateView"
                                              >
                                                <div className="commonInsOveride">
                                                  <Grid className="commoninfoWrap">
                                                    <Grid
                                                      item
                                                      sm={12}
                                                      xs={12}
                                                      md={12}
                                                      className="listViewBox"
                                                    >
                                                      <div className="listDetailBox">
                                                        <div className="listView questionDetail">
                                                          <div className="questions">
                                                            <div>
                                                              <span>
                                                                {
                                                                  inQuestion?.quesNo
                                                                }
                                                                {"."}{" "}
                                                                {
                                                                  inQuestion?.question
                                                                }
                                                              </span>
                                                            </div>
                                                            {/* <div>
                                                            <span className="openItemCount">
                                                              Open Item Count
                                                            </span>
                                                          </div> */}
                                                          </div>
                                                          <div className="questionitem">
                                                            <div>
                                                              {inQuestion?.answerMstId ===
                                                                2 && (
                                                                <>
                                                                  <div className="notAcceptableBtn commonBtn">
                                                                    Not
                                                                    Acceptable
                                                                  </div>

                                                                  {/* {inQuestion
                                                                  ?.previousOpenItemDetails
                                                                  ?.length >
                                                                  0 && (
                                                                  <div>
                                                                    <div className="countBtnSw">
                                                                      {findCount(
                                                                        inQuestion?.previousOpenItemDetails
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                )} */}
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </div>

                                                <Grid
                                                  container
                                                  item
                                                  className="itemFoundLocation"
                                                  md={12}
                                                  xs={12}
                                                  sm={12}
                                                >
                                                  <div className="viewReportIns">
                                                    {inQuestion?.detailsReportView?.some(
                                                      (item) =>
                                                        item?.itemFoundId
                                                    ) && (
                                                      <div className="title">
                                                        New Open Items
                                                      </div>
                                                    )}

                                                    <table>
                                                      {inQuestion?.detailsReportView?.some(
                                                        (item) =>
                                                          item?.itemFoundId
                                                      ) && (
                                                        <tr className="tableHead">
                                                          <th>Location</th>
                                                          <th>Item Found</th>
                                                          <th>Media</th>
                                                          <th>Status</th>
                                                        </tr>
                                                      )}

                                                      <NewOpenItem
                                                        inQuestion={inQuestion}
                                                      />
                                                    </table>
                                                  </div>
                                                </Grid>

                                                {inQuestion
                                                  ?.previousOpenItemDetails
                                                  ?.length > 0 && (
                                                  <Grid
                                                    container
                                                    item
                                                    className="itemFoundLocation"
                                                    md={12}
                                                    xs={12}
                                                    sm={12}
                                                  >
                                                    <div className="viewReportIns">
                                                      <div className="title">
                                                        Previous Open Items
                                                      </div>
                                                      <table>
                                                        <tr className="tableHead">
                                                          <th>Location</th>
                                                          <th>Item Found</th>
                                                          <th>Media</th>
                                                          <th>Status</th>

                                                          <th className="swinspectiondownloadprint">
                                                            Comment
                                                          </th>
                                                          <th className="swinspectiondownloadprint">
                                                            Date Corrected
                                                          </th>
                                                        </tr>

                                                        <PreviousOpenItem
                                                          filterPreviousData={
                                                            filterPreviousData
                                                          }
                                                        />
                                                      </table>
                                                    </div>
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </>
                                          )}
                                          {inQuestion?.answerMstId === 3 && (
                                            <Grid
                                              key={i}
                                              item
                                              md={12}
                                              xs={12}
                                              sm={12}
                                              className="subCateView"
                                            >
                                              <div className="commonInsOveride">
                                                <Grid className="commoninfoWrap">
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    className="listViewBox"
                                                  >
                                                    <div className="listDetailBox">
                                                      <div className="listView questionDetail">
                                                        <div className="questions">
                                                          <div>
                                                            <span>
                                                              {
                                                                inQuestion?.quesNo
                                                              }
                                                              {"."}{" "}
                                                              {
                                                                inQuestion?.question
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="questionitem">
                                                          <div>
                                                            {inQuestion?.answerMstId ===
                                                              3 && (
                                                              <>
                                                                <div className="notApplicableBtn gray commonBtn">
                                                                  N/A
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            </Grid>
                                          )}
                                          {(inQuestion?.answerMstId === 4 ||
                                            inQuestion?.answerMstId === 0) && (
                                            <Grid
                                              key={i}
                                              item
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="subCateView"
                                            >
                                              <div className="commonInsOveride">
                                                <Grid className="commoninfoWrap">
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sm={12}
                                                    className="listViewBox"
                                                  >
                                                    <div className="listDetailBox">
                                                      <Grid
                                                        container
                                                        className="listView"
                                                      >
                                                        <Grid
                                                          item
                                                          md={8}
                                                          xs={12}
                                                        >
                                                          <Grid>
                                                            <span>
                                                              {
                                                                inQuestion?.quesNo
                                                              }
                                                              {"."}{" "}
                                                              {
                                                                inQuestion?.question
                                                              }
                                                            </span>
                                                          </Grid>
                                                          <Grid></Grid>
                                                        </Grid>
                                                        <Grid
                                                          questionitem
                                                          md={12}
                                                          xs={12}
                                                          sm={12}
                                                        >
                                                          {inQuestion?.detailsReportView?.map(
                                                            (itemDetail, i) => {
                                                              return (
                                                                <Grid key={i}>
                                                                  <Grid
                                                                    container
                                                                    item
                                                                    className=""
                                                                    md={12}
                                                                    xs={12}
                                                                    sm={12}
                                                                  >
                                                                    <Grid
                                                                      md={8}
                                                                      xs={12}
                                                                      sm={8}
                                                                    >
                                                                      {parse(
                                                                        unescape(
                                                                          itemDetail?.details
                                                                        )
                                                                      )}
                                                                    </Grid>
                                                                  </Grid>
                                                                </Grid>
                                                              );
                                                            }
                                                          )}
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            </Grid>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        );
                      })}
                    </Grid>
                  )}
                </>
              );
            })}

            <Grid container>
              <Grid md={10} xs={12}>
                <div className="bottomBox" style={{ textAlign: "left" }}>
                  <p className="mendetryText">
                    "I certify under penalty of law that this report and all
                    attachments were prepared under my direction or supervision
                    in accordance with a system designed to assure that
                    certified personnel properly gather and evaluate the
                    information submitted. Based on my inquiry of the person or
                    persons who manage the system, or those persons directly
                    responsible for gathering the information, the information
                    submitted is, to the best of my knowledge and belief, true,
                    accurate, and complete. I am aware that there are
                    significant penalties for submitting false information,
                    including the possibility of fine and imprisonment for
                    knowing violations."
                  </p>

                  <div className="reportSignatureImg">
                    <img src={inspectionDetail?.signature} alt="" />
                  </div>
                </div>
              </Grid>
              <Grid container mt={5}>
                <QrBackBtn text={"Back"} />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </Grid>
  );
};

export default SequenceInspectionView;
