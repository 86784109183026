import React, { useEffect, useState, useRef } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Carousel from "../../library/custom/carouselBox/Carousel";
import {
  getQuestionSet,
  revokeQuesDynamicField,
  getReferenceData,
} from "../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import "./inspection.scss";
import SuccessScreen from "../../library/custom/success/successScreen";
import { FullPageLoader } from "../../library/common/Loader";
import { VIEW_INSPECTION_REPORT } from "../../constant/routeContant";
import { ORG_ID, USER_ID } from "../../constant/constant";
import NORow from "../../library/custom/noRow/noRow";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import { Grid } from "@mui/material";
import { ASSETTYPE } from "../../constant/inspectionConstant";
import { getInspectionDetails } from "../../redux/actions/incpectionAction";
import * as assetsAction from "../../redux/actions/assetsAction";

function AddInspectionContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const pathN = pathname?.split("/");
  const InspectionId = pathN[4] ?? null;
  const { quesSet, isSuccess, successData, isInLoading, isLoading } =
    useSelector((state) => state.inspection);

  const [carouselValue, setcarouselValue] = useState({});
  const [edit, setEdit] = useState(false);
  const [assetTypeValue, setAssetTypeValue] = useState(edit ? "" : 1);

  const prevquesSetId = useRef();
  const sliderItems = React.useMemo(() => {
    const slideItems =
      quesSet &&
      quesSet.map((item) => {
        return {
          id: item.key,
          img: item.imgUrl,
          title: item.value,
        };
      });
    return slideItems;
  }, [quesSet]);

  const navigateRoute = (value) => {
    if (!edit) {
      prevquesSetId.current = value?.id ?? null;
      prevquesSetId.assetTypeValue = assetTypeValue ?? null;
      navigate(`/inspection/${value?.id}`);
    }
  };
  const inspEditDetails = useSelector(
    (state) => state?.inspectionDetailData.inspEditDetails
  );
  const isSearchLoading = useSelector((state) => state?.users?.isLoading);

  useEffect(() => {
    if (assetTypeValue) {
      const data = {
        SubClassId: "",
        organisationId: ORG_ID(),
        assetTypeId: assetTypeValue,
        isActive: false,
      };
      dispatch(getQuestionSet({ data }));

      dispatch(
        assetsAction.getUserAssetList({
          userId: USER_ID(),
          type: assetTypeValue,
        })
      );
    }
  }, [assetTypeValue]);

  useEffect(() => {
    if (sliderItems.length > 0 && Object.keys(location).length > 0) {
      const { pathname } = location;
      const loCoName = pathname.split("/");
      const isEdit = loCoName && loCoName.some((val) => val === "edit");
      setEdit(isEdit);
      const comName = loCoName[2];
      const routerFound = sliderItems.filter(
        (item) => item.id === Number(comName)
      );
      setcarouselValue(routerFound[0] ?? {});
    }
  }, [sliderItems, location]);

  // unmount
  useEffect(() => {
    return () => {
      setcarouselValue({});
      dispatch(revokeQuesDynamicField());
    };
  }, [dispatch]);
  useEffect(() => {
    if (isLoading || isInLoading || isSearchLoading) {
      document.body.classList.add("full_page_loader");
    } else {
      document.body.classList.remove("full_page_loader");
    }
  }, [isLoading, isInLoading, isSearchLoading]);

  const handleAssetChange = (e) => {
    setcarouselValue({});
    setAssetTypeValue(e.target.value);
  };

  useEffect(() => {
    if (inspEditDetails && edit) {
      const data = {
        InspectionId: InspectionId,
        organisationId: ORG_ID(),
      };
      dispatch(getInspectionDetails({ data }));
    }
  }, [InspectionId, edit]);

  useEffect(() => {
    if (inspEditDetails && edit) {
      setAssetTypeValue(inspEditDetails?.assetType);
    }
  }, [edit, inspEditDetails]);
  useEffect(() => {
    dispatch(getReferenceData());
  }, []);

  return (
    <>
      {(isLoading || isInLoading || isSearchLoading) && <FullPageLoader />}
      {isSuccess ? (
        <SuccessScreen
          id={successData?.inspectionId ?? null}
          isUpdate={
            successData?.insAddEditFlag === "edit"
              ? "Inspection Report Updated successfully"
              : "Inspection Report Saved successfully" ?? null
          }
          route={VIEW_INSPECTION_REPORT}
          label2={
            successData?.insAddEditFlag === "edit"
              ? "has been Updated"
              : "has been generated"
          }
        />
      ) : (
        <>
          <div className="dashboard-slider">
            <div>
              <div className="dashbord_heading">
                <div className="insi-stitle">
                  <p>Select Inspection Type</p>
                </div>
              </div>
              <Grid container mt={2} mb={2}>
                <Grid item lg={5} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Asset Type" isRequired={true} />
                    <SelectMenu
                      listData={ASSETTYPE}
                      value={assetTypeValue}
                      onchangehandler={(e) => handleAssetChange(e)}
                      disabled={edit ? true : false}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {sliderItems.length === 0 ? (
                <NORow />
              ) : (
                <>
                  <Carousel
                    sliderItems={sliderItems}
                    activeSlide={carouselValue}
                    handleOnChange={navigateRoute}
                    edit={edit}
                  />
                </>
              )}
              {Object.keys(carouselValue).length > 0 &&
                sliderItems[0] !== undefined && (
                  <Outlet context={prevquesSetId} />
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(AddInspectionContainer);
