import React, { useRef } from "react";
import { Grid, RadioGroup, FormControl } from "@mui/material";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import UsersChip from "../../../library/custom/usersChip/UsersChip";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import "./notAcceptable.scss";
import {
  InspectionInvolvedPartyOptionData,
  escalatetoReprimand,
  closeItem,
} from "../../../constant/inspectionConstant";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import FormDropZone from "../../../library/dropZone/FormDropZone";
import { USER_TYPE } from "../../../constant/constant";

const NotAcceptable = (props) => {
  const autoCompInspector = useRef(null);

  return (
    <div className="overideNotAc">
      <div className="notAcceptableBox">
        <Grid item md={12} xs={12} textAlign="left" mt={2}>
          {props?.notacceptablelength > 1 && (
            <div
              onClick={(e) =>
                props?.handleFormChange(
                  props?.acc,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  "deleteCorrective"
                )
              }
              className="removeRepetBox"
            >
              X Remove Items
            </div>
          )}
          <FormLabels label="Issue Found" />
          <TextEditor
            value={props?.acc?.comment ?? ""}
            placeholderText={"Add Issue Found Here..."}
            onChange={(e) =>
              props?.handleFormChange(
                e,
                props?.question,
                props?.subcategory,
                props?.category,
                "comment",
                props?.acc
              )
            }
          />
          {props?.commentValue?.length === 500 && (
            <p
              className={
                props?.commentValue?.length === 500 ? "error" : "errornot"
              }
            >
              Character limit exceeded.
            </p>
          )}

          <div className="customMultiLable">
            <FormDropZone
              isMultipleIcon={true}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop the files here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileDataId) =>
                props?.setImage(
                  photo,
                  fileDataId,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  props?.acc,
                  "notacceptable"
                )
              }
              fileType={10}
              imageUrl={props?.acc?.files}
              deleteImageURL={(url, index) =>
                props?.deleteImageURLS(
                  url,
                  index,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  props?.acc,
                  "notacceptable"
                )
              }
              id="uploadMedia"
              key="uploadMedia"
              isMuliple={true}
              isDoc={false}
              isImage={true}
              allFiles={false}
            />
          </div>
        </Grid>

        {props?.question?.involvedPartyShow && (
          <div className="container inVoledParty">
            <FormLabels label="Involved Party Type" labelPlacement="start" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {InspectionInvolvedPartyOptionData &&
                InspectionInvolvedPartyOptionData.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={props?.acc?.involdevPaty === item.value}
                    value={item.value}
                    label={item.label}
                    onChange={(e) =>
                      props?.handleFormChange(
                        e?.target?.value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "involvedpartytype",
                        props?.acc
                      )
                    }
                  />
                ))}
            </RadioGroup>
          </div>
        )}

        {props?.acc?.involdevPaty === "Employee" && (
          <>
            <Grid item container spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  {!props?.assets?.key && (
                    <p className="error">First select Asset</p>
                  )}

                  <FormLabels label="Add Involved Employee" />
                  <SearchAutoComplete
                    optionsData={
                      props?.isAutoSearchUserLoading
                        ? []
                        : props?.allUsers?.users ?? []
                    }
                    value={props?.acc?.employee[0] ?? ""}
                    onChange={(e, value) =>
                      props?.handleFormChange(
                        value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "involvedpartyemployee",
                        props?.acc
                      )
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      props?.handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        0,
                        0,
                        props?.assets?.key,
                        0,
                        0,
                        0,
                        true
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={props?.isAutoSearchUserLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <div className="pastVioltionBox">
                  <UsersChip
                    userList={props?.acc?.employee}
                    handleDelete={(e, value) =>
                      props?.handleFormChange(
                        e,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "deleteEmployee",
                        props?.acc
                      )
                    }
                    isCountChip={true}
                    isCount={props?.acc?.empCount}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}

        {props?.acc?.involdevPaty === "Sub-Contractor" && (
          <>
            <Grid item container spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  {!props?.assets?.key && (
                    <p className="error">First select Asset</p>
                  )}
                  <FormLabels label="Add Involved Company" />
                  <SearchAutoComplete
                    optionsData={
                      props?.isAutoSearchUserLoading
                        ? []
                        : props?.allUsers?.users ?? []
                    }
                    value={props?.acc?.subcontractor[0] ?? ""}
                    onChange={(e, value) =>
                      props?.handleFormChange(
                        value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "addinvolvedcompany",
                        props?.acc
                      )
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      props?.handleAllUserSearch(
                        event,
                        USER_TYPE.CONTRACTOR,
                        0,
                        0,
                        props?.assets?.key,
                        0,
                        0,
                        0,
                        true
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={props?.isAutoSearchUserLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <div className="pastVioltionBox">
                  <UsersChip
                    userList={props?.acc?.subcontractor}
                    handleDelete={(e, value) =>
                      props?.handleFormChange(
                        e,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "deleteSubcontractor",
                        props?.acc
                      )
                    }
                    isCountChip={true}
                    isCount={props?.acc?.empCount}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {/* ===============================Escalate to reprimand radio button ================== */}
        {(props?.acc?.involdevPaty === "Sub-Contractor" ||
          props?.acc?.involdevPaty === "Employee") && (
          <div className="notAcceptableBoxcontainer">
            <FormLabels label="Escalate to Reprimand" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {escalatetoReprimand &&
                escalatetoReprimand.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={
                      props?.acc?.escalatetoreprimandIsChecked === item.value
                    }
                    value={item.value}
                    label={item.label}
                    onChange={(e) =>
                      props?.handleFormChange(
                        e?.target?.value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "escalatetoreprimand",
                        props?.acc
                      )
                    }
                  />
                ))}
            </RadioGroup>
          </div>
        )}

        {props?.acc?.escalatetoreprimandIsChecked === "Yes" && (
          <Grid item container>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Enter Action Taken" />
                <TextEditor
                  placeholderText={"Enter Here..."}
                  value={props?.acc?.escalatetoreprimandComment ?? ""}
                  onChange={(e) =>
                    props?.handleFormChange(
                      e,
                      props?.question,
                      props?.subcategory,
                      props?.category,
                      "escalatetoreprimandComment",
                      props?.acc
                    )
                  }
                />
                {props?.escalatetoreprimandCommentValue?.length === 500 && (
                  <p
                    className={
                      props?.escalatetoreprimandCommentValue?.length === 500
                        ? "error"
                        : "errornot"
                    }
                  >
                    Character limit exceeded.
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid item container>
          <Grid item lg={7} className="inVoledParty">
            <FormLabels label="Item Status" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {closeItem &&
                closeItem.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={props?.acc?.itemStatusIsChecked === item.value}
                    value={item.value}
                    label={item.label}
                    onChange={(e) =>
                      props?.handleFormChange(
                        e?.target?.value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "itemStatus",
                        props?.acc
                      )
                    }
                  />
                ))}
            </RadioGroup>
          </Grid>
        </Grid>

        {props?.acc?.itemStatusIsChecked === "Closed" && (
          <Grid item container mt={2}>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add Abatement Notes" />
                <TextEditor
                  placeholderText={"Enter Here..."}
                  value={props?.acc?.addAbatementNotes ?? ""}
                  onChange={(e) =>
                    props?.handleFormChange(
                      e,
                      props?.question,
                      props?.subcategory,
                      props?.category,
                      "addabatementnotes",
                      props?.acc
                    )
                  }
                />
                {props?.itemStatusCommentValue?.length === 500 && (
                  <p
                    className={
                      props?.itemStatusCommentValue?.length === 500
                        ? "error"
                        : "errornot"
                    }
                  >
                    Character limit exceeded.
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        {props?.acc?.itemStatusIsChecked === "Open" && (
          <Grid item container mt={0} spacing={2}>
            <Grid item lg={4}>
              <FormControl fullWidth>
                <FormLabels
                  label="Assign Item Responsibility"
                  isRequired={false}
                />
                <SearchAutoComplete
                  optionsData={
                    props?.isAutoSearchUserLoading
                      ? []
                      : props?.allUsers?.users ?? []
                  }
                  value={props?.acc?.itemStatusCommentUserCurrent ?? ""}
                  onChange={(e, value) =>
                    props?.handleFormChange(
                      value,
                      props?.question,
                      props?.subcategory,
                      props?.category,
                      "assignitemresponsibility",
                      props?.acc
                    )
                  }
                  name="Add Involved Party"
                  typeOnchange={(event, value) =>
                    props?.handleAllUserSearch(
                      event,
                      USER_TYPE.DIVISION_EMPLOYEE,
                      0,
                      0,
                      props?.assets?.key,
                      0,
                      0,
                      0,
                      true
                    )
                  }
                  isKeyValuePair={true}
                  isLoading={props?.isAutoSearchUserLoading}
                  autoCompClear={autoCompInspector}
                />
              </FormControl>
            </Grid>
            <Grid md={9} mt={3} ml={2} xs={12}>
              <UsersChip
                userList={
                  props?.acc?.itemStatusCommentUser && [
                    props?.acc?.itemStatusCommentUser,
                  ]
                }
                handleDelete={(e, value) =>
                  props?.handleFormChange(
                    e,
                    props?.question,
                    props?.subcategory,
                    props?.category,
                    "deleteItemStatusCommentUser",
                    props?.acc
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default NotAcceptable;
