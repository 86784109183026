import React from "react";
import Grid from "@mui/material/Grid";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import "./injuryRecordable.scss";
import { getFormatedDate } from "../../../../utils/helper";
export default function InjuryRecordable({
  illnessType,
  dateOfInjury,
  caseId,
  caseClassify,
  illnessTypesValue,
  caseClassifysValue,
  setcaseClassifysValue,
  howManyDays,
  setillnessTypesValue,
  injuredOrMadePersonValue,
  setInjuredOrMadePersonValue,
  bodyPartValue,
  setHowManyDays,
  name,
  jobTitle,
  whereOccured,
  caseClassifyValuee,
  isHowManyDays
}) {

  return (
    <>
      <div className="overideinjuryRecordable">
        <div className="injuryCard">
          <Grid item container spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Case Number"
                value={caseId}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Employee's Name"
                value={name}
              />
            </Grid>
          </Grid>
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Job Title"
                value={jobTitle}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Date of Injury / Illness"
                value={dateOfInjury ? getFormatedDate(dateOfInjury) : ""}
              />
            </Grid>
          </Grid>
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Where did the event occur?"
                value={whereOccured}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label="Body Part Injured"
                value={bodyPartValue}
              />
            </Grid>
          </Grid>

          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6} className="injurylabel">
              <FormLabels label="Object or Substance that directly injured or made the person ill" />
              <InputField
                fullWidth={true}
                type="text"
                // label="Object or Substance that directly injured or made the person ill"
                value={injuredOrMadePersonValue}
                onChange={setInjuredOrMadePersonValue}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormLabels label="Classify the case" />
              <br />
              <SelectMenu
                value={caseClassifysValue}
                listData={caseClassify?.filter((list) => list.isActive)}
                onchangehandler={setcaseClassifysValue}
              />
            </Grid>
          </Grid>
          <Grid item container mt={3} spacing={2}>
            {caseClassifyValuee?.toLowerCase() === "death" ||
            caseClassifyValuee?.toLowerCase() === "other recordable" ||
            caseClassifysValue == "" ? null : (
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <NumberField
                  type="text"
                  isRequired={false}
                  label="How Many Days Classify"
                  value={howManyDays}
                  placeholder="Add Number of Injuries Reported Here..."
                  onChange={setHowManyDays}
                />
                {isHowManyDays && <p className="error">How Many Days Classify should be greater than 0 </p>}
              </Grid>
            )}

            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormLabels label="Type of Illness" />
              <SelectMenu
                value={illnessTypesValue}
                listData={illnessType?.filter((list) => list.isActive)}
                onchangehandler={setillnessTypesValue}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
