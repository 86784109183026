import React from "react";
import Drawer from "@mui/material/Drawer";
import "./sidebar.scss";
import AppMenu from "./SideBarMui";

export default function Sidebar({ handleRoute, location }) {
  return (
    <div className="Sidebar">
      <div className="sidesss">
        <Drawer
          variant="permanent"
          classes={{
            paper: "drawerPaper",
          }}
        >
          <AppMenu handleRoute={handleRoute} location={location} />
        </Drawer>
      </div>
    </div>
  );
}
