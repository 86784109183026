import React, { useState } from "react";
import "./qrcodelanding.scss";
import SeqHeader from "./seqHeader";
import GridTable from "../../library/custom/gridTable/GridTable";
import NORow from "../../library/custom/noRow/noRow";
import { TableLoader } from "../../library/common/Loader";
import { Grid } from "@mui/material";
import "./seqHeader.scss";
import ReportRowManipulte from "../../feature/incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../feature/incidents/incidentList/SettingTable";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../constant/constant";
import SearchIcon from "@mui/icons-material/Search";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";

const SequenceResources = ({
  projectNumber,
  projectName,
  clientName,
  QrResourecListData,
  getSequenceLandingQrResource,
  isLoading,
  projectId,
  assetTypeId,
}) => {
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isTableLoading, setisTableLoading] = useState(false);

  const onRowClick = (rows) => {
    if (rows.value) {
      if (rows.row.resourceTypeId === 2 && rows?.row?.linkUrls?.length > 0) {
        window.open(rows?.row?.linkUrls[0], "_blank");
      } else if (rows?.row?.fileUrls?.length > 0) {
        window.open(rows?.row?.fileUrls[0], "_blank");
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 120 },
    { field: "category", headerName: "Category", flex: 3, minWidth: 120 },
    {
      field: "resourceTitle",
      headerName: "Resource Name",
      flex: 3,
      minWidth: 120,
    },
    {
      field: "dateOfEntry",
      headerName: "Date of Entry",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "resourceType",
      headerName: "Resource Type",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={QrResourecListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={QrResourecListData?.resourceData ?? []}
          fileNameXL={"Resource_List"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            openLink
            openLinkUrl={
              rows?.row?.resourceTypeId === 2
                ? `${rows?.row?.linkUrls[0]}`
                : `${rows?.row?.fileUrls[0]}`
            }
            openLinkLabel={
              rows?.row?.resourceTypeId === 2
                ? "Open Link"
                : "Download Resource"
            }
          />
        );
      },
    },
  ];

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      assetId: projectId,
      assetTypeId: assetTypeId,
      search: searchText ? searchText : "",
      page: 1,
      pageSize: pageSize,
    };
    getSequenceLandingQrResource(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getSequenceLandingQrResource({
      assetId: projectId,
      assetTypeId: assetTypeId,
      search: search ? search : "",
      page: page,
      pageSize: newPazeSize,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getSequenceLandingQrResource({
      assetId: projectId,
      assetTypeId: assetTypeId,
      search: search ? search : "",
      page: newPage + 1,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <SeqHeader
        clientName={clientName}
        ProjectName={projectName}
        ProjectNumber={projectNumber}
      />
      <section className="grid_main_sec">
        <div className="gridMainpermit">
          <Grid className="permitBox" lg={12} mb={2}>
            <Grid className="grid_main_header two_grid" lg={12}>
              <Grid lg={8} className="grid_heading_style" space={2}>
                Resource Library
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} xs={12} sm={12} md={6} mb={3}>
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search Resource Library"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
            </Grid>
          </Grid>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r?.id}
                  rows={QrResourecListData?.resourceData ?? []}
                  rowCount={QrResourecListData?.noOfRecords ?? 0}
                  columns={columns}
                  // loading={isLoading}
                  page={1}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onClick={(rows) => onRowClick(rows)}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
          <Grid container>
            <QrBackBtn text={"Back"} />
          </Grid>
        </div>
      </section>
    </>
  );
};

export default SequenceResources;
