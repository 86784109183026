import React, { useEffect } from "react";
import OshaViewReportList from "../component/oshaViewReportList";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE } from "../../../constant/constant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { useSelector, useDispatch } from "react-redux";
import {
  ACCESS_DENIED,
  OSHA_VIEW_REPORT,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const OshaViewReportListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const establishmentId = null;
  const year = 2024;
  const getListAddedInOSHAData = (data) => {
    dispatch(incidentAction.getListAddedInOSHA(data));
  };

  const listAddedInOSHA = useSelector((state) => state.getListAddedInOSHA);
  const isLoader = useSelector((state) => state.getListAddedInOSHA.isLoading);
  useEffect(() => {
    const defaultData = {
      establishmentId: establishmentId,
      year: "",
      status: "",
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
    };

    getListAddedInOSHAData(defaultData);
  }, [establishmentId, year]);

  const handleViewOnClick = (rows) => {
    navigate(OSHA_VIEW_REPORT, {
      state: { row: rows, editMode: false },
    });
  };

  return (
    <>
      {checkPrivileges([12, 152, 153, 154]) ? (
        <OshaViewReportList
          establishmentId={establishmentId}
          year={year}
          isLoading={isLoader}
          listAddedInOSHA={listAddedInOSHA?.data}
          handleViewOnClick={handleViewOnClick}
          getListAddedInOSHAData={getListAddedInOSHAData}
        />
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default OshaViewReportListContainer;
