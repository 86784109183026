import React, { useEffect } from "react";
import SequencePermitsDetail from "../component/sequencePermitsDetail";
import { useLocation } from "react-router-dom";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../library/common/Loader";

const SequencePermitsDetailContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const permitId = location?.state?.permidData?.permitId;
  const projectNumber = location?.state?.projectdata?.ProjectNumber;
  const projectName = location?.state?.projectdata?.ProjectName;
  const clientName = location?.state?.projectdata?.clientName;

  const permitDetails = useSelector(
    (state) => state.sequenceLandingPermitDetail?.data?.result
  );
  const permitDetailsIsLoading = useSelector(
    (state) => state.sequenceLandingPermitDetail?.isLoading
  );

  useEffect(() => {
    if (permitId) {
      const data = {
        permitId: permitId,
      };
      dispatch(
        sequenceLandingAction.getSequenceLandingProjectPermitDetails(data)
      );
    }
  }, []);

  return (
    <>
      {permitDetailsIsLoading && <Loader />}
      <SequencePermitsDetail
        permitId={permitId}
        permitDetails={permitDetails}
        clientName={clientName}
        projectName={projectName}
        projectNumber={projectNumber}
      />
    </>
  );
};

export default SequencePermitsDetailContainer;
