import React, { useState, useEffect } from "react";
import AddProject from "../components/addProject";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import * as siteAction from "../../../../redux/storm-water/actions/siteAction";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import Loader from "../../../../library/common/Loader";
import {
  ADMIN_PROJECTS_LIST,
} from "../../../../constant/routeContant";
import { API_STATUS, ORG_ID } from "../../../../constant/constant";
import * as clientAction from "../../../../redux/actions/clientAction";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";

function AddProjectContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const navigate = useNavigate();
  const viewMode = location?.state?.viewMode;
  const Projectid = location?.state?.projectid;
  const stateId = location.state?.stateId;
  const { projectNumber, projectName } = location?.state || {};
  const [customerId, setcustomerID] = useState(location?.state?.customerId);
  const [customer, setcustomer] = useState(location?.state?.customerName);
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.state);
  const cityData = useSelector((state) => state.city);
  const stateIsloading = useSelector((state) => state.state?.isLoading);
  const cityIsloading = useSelector((state) => state.city?.isLoading);
  const siteDetailsIsLoading = useSelector(
    (state) => state.getSiteDetsilsData?.isLoading
  );

  const initProjectData = [
    {
      id: Projectid,
      projectNumber: projectNumber,
      projectName: projectName,
      clientname: customer,
    },
  ];
  const [projectData, setProjectData] = useState(initProjectData);

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const [setsubmit, setSetsubmit] = useState(false);
  const [generateQr, setGenerateQr] = useState(false);
  const [currentProjectNumber, setCurrentProjectNumber] = useState(null);
  const createProjectData = useSelector((state) => state?.createSwProjectData);

  const [prId, setprId] = useState(null);
  const [isedit, setIsedit] = useState(false);


  const refData = {
    flags: [10],
    orgId: customerId,
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getRefLoader = useSelector((state) => state.getSwReference?.isLoading);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  useEffect(() => {
    getSwReferenceData(refData);
  }, []);

  useEffect(() => {
    if (
      createProjectData?.status === API_STATUS.SUCCESS &&
      createProjectData?.data &&
      setsubmit
    ) {
      if (createProjectData?.data?.ids?.length > 1) {
        setIsSuccess(true);
      } else {
        setprId(createProjectData?.data?.ids[0]);
        setIsedit(true);
        toast("Project Added Successfully");
      }
      const projData = [...projectData];
      if (currentProjectNumber) {
        projData[0].projectNumber = currentProjectNumber;
        setGenerateQr(true);
      } else {
        projData[0].projectNumber = "";
        setGenerateQr(false);
      }
      setProjectData(projData);
    }
  }, [createProjectData]);

  const createIsloading = useSelector(
    (state) => state.createSwProjectData?.isLoading
  );
  const createprojectStatus = useSelector(
    (state) => state.createSwProjectData?.status
  );

  const updateSwProjectData = useSelector((state) => state?.updateSwProject);
  const updateIsloading = useSelector(
    (state) => state.updateSwProject?.isLoading
  );

  useEffect(() => {
    if (
      updateSwProjectData?.status === API_STATUS.SUCCESS &&
      updateSwProjectData?.data !== "" &&
      setsubmit
    ) {
      if (
        updateSwProjectData?.data?.responseMessages?.responseCode == "HBNG011"
      ) {
        toast(updateSwProjectData?.data?.responseMessages?.responseMessage);
      } else {
        toast("Project Updated Successfully");
      }

      const projData = [...projectData];
      if (currentProjectNumber) {
        projData[0].projectNumber = currentProjectNumber;
        setGenerateQr(true);
      } else {
        projData[0].projectNumber = "";
        setGenerateQr(false);
      }
      setProjectData(projData);
    }
  }, [updateSwProjectData]);

  const searchSitedata = useSelector(
    (state) => state?.siteListAutoSearch?.data
  );
  const getSiteDetsilsData = useSelector((state) => state.getSiteDetsilsData);

  const isLoading = useSelector(
    (state) => state?.siteListAutoSearch?.isLoading
  );
  const getSwProjectDetailData = useSelector(
    (state) => state.getSwProjectDetail
  );
  const getSwProjectDetailDataIsloading = useSelector(
    (state) => state.getSwProjectDetail?.isLoading
  );

  const updateSwProjectStatus = useSelector(
    (state) => state.updateSwProject?.status
  );

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getCity = (stateId) => {
    dispatch(stateCityAction.getCity(stateId));
  };

  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  useEffect(() => {
    getStateData();
    dispatch(projectAction.clearsWProject());
    dispatch(projectAction.clearsWProjectUpdate());
  }, []);
  useEffect(() => {
    if (location?.state?.projectid) {
      setprId(location?.state?.projectid);
    } else {
      setprId(null);
    }

    if (location?.state?.editMode) {
      setIsedit(location?.state?.editMode);
    } else {
      setIsedit(false);
    }
  }, [location?.state]);

  const addProject = (data) => {
    if (isedit && prId) {
      setCurrentProjectNumber(data?.projectNumber || null);
      dispatch(projectAction.updateProject(data));
      setSetsubmit(true);
    } else {
      setcustomerID(data?.projectDetails[0]?.client);
      setcustomer(data?.projectDetails[0]?.clientName);
      setCurrentProjectNumber(data?.projectDetails[0]?.projectNumber);
      dispatch(projectAction.createProject(data));
      setSetsubmit(true);
    }
  };

  const getProjectDetails = (data) => {
    const datas = {
      projectId: data,
    };
    dispatch(projectAction.getProjectDetails(datas));
  };

  useEffect(() => {
    if (isedit || viewMode) {
      getProjectDetails(prId);
    }
  }, [isedit, prId, dispatch]);

  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          isedit ? "Project Updated Successfully" : "Project Added Successfully"
        }
        route={ADMIN_PROJECTS_LIST}
        label1=" "
        label2=" "
        label3="You will be redirected to"
        label4="Project List"
      ></SuccessScreen>
    );
  };
  const searchSite = (searchText) => {
    dispatch(siteAction.getSiteAutoSearchList(searchText));
  };
  useEffect(() => {
    dispatch(siteAction.getSiteClear());
  }, []);

  const getSiteDetsil = (data) => {
    dispatch(siteAction.getSiteDetsils(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: customerId ? customerId : parseInt(ORG_ID()),
    flags: "6",
    productId: PRODUCT_ID(),
    Clientid: customerId ? customerId : null,
  };
  useEffect(() => {
    setIsSuccess(false);
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  return (
    <>
      {" "}
      {getSwProjectDetailDataIsloading && <Loader />}
      {updateIsloading && <Loader />}
      {createIsloading && <Loader />}
      {stateIsloading && <Loader />}
      {cityIsloading && <Loader />}
      {siteDetailsIsLoading && <Loader />}
      {getRefLoader && <Loader />}
      {createprojectStatus === API_STATUS.SUCCESS &&
      setsubmit &&
      createProjectData &&
      successScreens ? (
        successScreen(createProjectData?.data?.id)
      ) : updateSwProjectStatus === API_STATUS.SUCCESS &&
        successScreens &&
        setsubmit &&
        updateSwProjectData?.data ? (
        successScreen(updateSwProjectData?.data?.id)
      ) : (
        <AddProject
        state={stateData && stateData.data?.getState}
        getCity={getCity}
        city={cityData && cityData.data?.cities}
        cityData={cityData}
        addProject={addProject}
        getSwProjectDetailData={getSwProjectDetailData}
        editMode={isedit}
        viewMode={viewMode}
        projectId={prId}
        onSearchSite={searchSite}
        searchSitedata={searchSitedata}
        isLoading={isLoading}
        searchClient={searchClient}
        autoSearchDataLoading={autoSearchDataLoading}
        searchAllClientsReducerData={searchAllClientsReducerData}
        generateQr={generateQr}
        customerId={customerId}
        customer={customer}
        stateId={stateId}
        getSiteDetsil={getSiteDetsil}
        getSiteDetsilsData={getSiteDetsilsData}
        divisions={division}
        setcustomer={setcustomer}
        getSwReference={getSwReference?.data}
        getSwReferenceData={getSwReferenceData}
        projectDataQr={projectData}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default AddProjectContainer;
