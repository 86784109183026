export const getApiUrl = function () {
  return {
    incidentApiUrl: process.env.REACT_APP_INCIDENT_URL,
    userApiUrl: process.env.REACT_APP_USER_URL,
    searchApiUrl: process.env.REACT_APP_SEARCH_URL,
    fileUploadURL: process.env.REACT_APP_UPLOAD_URL,
    userProdUrl: process.env.REACT_APP_BASEURL,
    incpectionApiUrl: process.env.REACT_APP_UPDATED_INSPECTION_URL,
    supportApiUrl: process.env.REACT_APP_SUPPORT_URL,
    lmsApiUrl: process.env.REACT_APP_LMS_URL,
    adminApiUrl: process.env.REACT_APP_ADMIN_URL,
    credentilApiUrl: process.env.REACT_APP_ADMIN_URL + "Credential",
    incidentApiUrlNew: process.env.REACT_APP_INCIDENT_URL_NEW,
    reportApiUrl: process.env.REACT_APP_REPORT_URL,
    adminSWApiUrl: process.env.REACT_APP_SW_ADMIN,
    swIncpectionApiUrl: process.env.REACT_APP_SW_INSPECTION_URL,
    contactSearchListUrl: process.env.REACT_APP_SEARCH_URL + "/v1",
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    qrCodeScannerUrl: process.env.REACT_APP_PUBLIC_URL,
    sequenceLandingListUrl: process.env.REACT_APP_SEQUENCE_LANDING_URL,
  };
};

export const countryCode = process.env.REACT_APP_COUNTRY_CODE;

export const getGATRackingId = function () {
  return process.env.REACT_APP_GA_TRACKING_ID;
};
