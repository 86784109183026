import React, { useState } from "react";
import "../../incidents/cases/addCaseList.scss";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../library/common/Loader";
import { PAGE_SIZE } from "../../../constant/constant";
import TableRowAction from "../../../library/custom/gridTable/TableRowAction";
import NORow from "../../../library/custom/noRow/noRow";

const OshaViewReportList = (props) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      establishmentId: props?.establishmentId,
      year: "",
      status: "",
      search: searchText ? searchText : "",
      page: 1,
      pageSize: PAGE_SIZE,
    };
    props?.getListAddedInOSHAData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    props?.getListAddedInOSHAData({
      establishmentId: props?.establishmentId,
      year: props?.year,
      status: "",
      search: search ? search : "",
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    });
  };

  const columns = [
    { field: "submitOshaDetailsId", headerName: "Report ID", flex: 1 },
    { field: "establishmentName", headerName: "Establishment", flex: 2 },
    { field: "year", headerName: "Year", flex: 1 },
    { field: "lastSubmittedDate", headerName: "Last Date Submitted", flex: 2 },
    {
      field: "caseNumberOtherRecordable",
      headerName: "Total Recordable Cases",
      flex: 2,
      renderCell: (rows) => {
        return parseInt(rows?.row?.caseNumberOfDeath) + parseInt(rows?.row?.caseNumberOfDaysAway) +
          parseInt(rows?.row?.caseNumberOfTransfer) + parseInt(rows?.row?.caseNumberOtherRecordable)

      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={props?.listAddedInOSHA?.count ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={props?.listAddedInOSHA?.addedOshaLogDetails ?? []}
          fileNameXL={"osha-reports"}
        />
      ),
      flex: 1,
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            view
            viewLabel="View Report"
            viewOnClick={(row) => props?.handleViewOnClick(row)}
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Historical OSHA Records</div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.submitOshaDetailsId}
                columns={columns}
                rows={props?.listAddedInOSHA?.addedOshaLogDetails ?? []}
                rowCount={props?.listAddedInOSHA?.count ?? 0}
                loading={props?.isLoading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                rowsPerPageOptions={[10]}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                onPageChange={handlePagignation}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OshaViewReportList;
