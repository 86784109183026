import React, { useEffect, useState } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import InspectionSetList from "../component/inspection/inspectionSetList";
import { useNavigate } from "react-router-dom";
import { INSPECTION_SET } from "../../../constant/routeContant";
import { ORG_ID } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";

const SetConfigurationListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addInspection = () => {
    navigate(INSPECTION_SET);
  };

  const cloneQuestionSet = useSelector((state) => state.cloneQuestionSet);

  const questionSetList = useSelector((state) => state.getQuestionSetList);
  const questionSetListData = (data) => {
    dispatch(incpectionAction.getQuestionSetList(data));
  };
  const isLoading = useSelector((state) => state.getQuestionSetList?.isLoading);

  const defaultQuestionSetData = {
    organisationId: ORG_ID(),
    isActive: true,
  };
  const [clearStatus, setClearStatus] = useState(false);
  const [clearClone, setClearClone] = useState(false);

  const adminAddUpdateInspection = useSelector(
    (state) => state.adminAddUpdateInspection
  );
  const showLoader = useSelector(
    (state) =>
      state.adminAddUpdateInspection?.isLoading ||
      state.cloneQuestionSet?.isLoading
  );

  const onSubmit = (data) => {
    setClearStatus(true);
    dispatch(incpectionAction.adminAddUpdateInspection(data));
  };

  useEffect(() => {
    questionSetListData(defaultQuestionSetData);
    dispatch(incpectionAction.clearCloneQuestionSet());
    dispatch(incpectionAction.clearAdminAddUpdateInspection());
  }, []);

  useEffect(() => {
    if (
      adminAddUpdateInspection?.status === "SUCCESS" &&
      adminAddUpdateInspection?.isLoading === false &&
      clearStatus
    ) {
      toast("Inspection Updated successfully");
      questionSetListData(defaultQuestionSetData);
    }
  }, [adminAddUpdateInspection]);

  const handleEditOnClick = (questsetId, assetTypeId) => {
    navigate(INSPECTION_SET, {
      state: {
        questsetId: questsetId,
        assetTypeId: assetTypeId,
        editMode: true,
      },
    });
  };

  const handleCloneOnClick = (questsetId) => {
    navigate(INSPECTION_SET, {
      state: { questsetId: questsetId, cloneMode: true },
    });
  };

  const onCloneSubmit = (data) => {
    setClearClone(true);
    dispatch(incpectionAction.cloneQuestionSet(data));
  };

  useEffect(() => {
    if (
      cloneQuestionSet?.status === "SUCCESS" &&
      cloneQuestionSet?.isLoading === false &&
      clearClone
    ) {
      toast("Clone Inspection Name Added successfully");
      questionSetListData(defaultQuestionSetData);
    }
  }, [cloneQuestionSet]);

  return (
    <>
      <>{showLoader && <Loader />}</>

      <InspectionSetList
            addInspection={addInspection}
            isLoading={isLoading}
            questionSetList={
              isLoading ? [] : questionSetList?.data?.questionsSets
            }
            handleEditOnClick={handleEditOnClick}
            handleCloneOnClick={handleCloneOnClick}
            onSubmit={onSubmit}
            onCloneSubmit={onCloneSubmit}
            questionSetListData={questionSetListData}
          />
      <ToastContainer />
    </>
  );
};

export default SetConfigurationListContainer;
