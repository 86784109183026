import React, { useEffect } from "react";
import OshaViewReport from "../component/oshaViewReport";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as incidentAction from "../../../redux/actions/incidentAction";
import Loader from "../../../library/common/Loader";
import { useSelector } from "react-redux";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";

const OshaViewReportContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reportData = location?.state?.row;

  const getEstablishmentDetail = useSelector(
    (state) => state?.establishmentDetail
  );

  const getEstablishmentDetailData = (data) => {
    dispatch(incidentAction.getEstablishmentDetail(data));
  };

  const isLoader = useSelector(
    (state) => state?.establishmentDetail?.isLoading
  );

  useEffect(() => {
    if (reportData?.establishmentId) {
      const data = {
        establishmentId: reportData?.establishmentId,
      };
      getEstablishmentDetailData(data);
    }
  }, [reportData?.establishmentId]);

  return (
    <>
      {checkPrivileges([152, 153, 154, 12]) ? (
        <>
          {isLoader && <Loader />}
          <OshaViewReport
            reportData={reportData}
            establishmentDetail={getEstablishmentDetail?.data?.result}
            reportShow={true}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default OshaViewReportContainer;
