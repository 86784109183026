import React from "react";
import CaseIncidentList from "../components/caseIncidentList";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import { useLocation } from "react-router-dom";
import { EDITCASE, CASES } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer, toast } from "react-toastify";
import BackBtn from "../../../library/custom/backBtn/backBtn";

const CaseIncidentsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const caseId = location?.state?.caseId;
  const caseState = location?.state;

  const [page, setPage] = useState(1);
  const [clearMessage, setClearMessage] = useState(false);
  const pageSet = (pageNumber) => {
    setPage(pageNumber);
  };
  const deleteIncidents = (deleteIncedent) => {
    setClearMessage(true);
    dispatch(incidentAction.deleteIncidents(deleteIncedent.reportId));
  };
  const incidentListDelete = useSelector((state) => state.deleteIncidentsData);

  const incidentList = useSelector((state) => state.incidentList);

  const getIncidentListData = (data) => {
    dispatch(incidentAction.getIncidentList({ pageNumber: 1, caseId: caseId }));
  };

  const isListSuccess = useSelector(
    (state) => state.incidentList.isLoading || state.closeIncident.isLoading
  );

  const viewCaseData = useSelector((state) => state?.viewCase?.data);
  const closeIncidentData = useSelector((state) => state.closeIncident);

  const isSuccess = useSelector(
    (state) => state.viewCase.status === API_STATUS.SUCCESS
  );
  useEffect(() => {
    dispatch(incidentAction.getCaseDetails(caseId));
  }, [dispatch]);

  useEffect(() => {
    if (incidentListDelete?.status === "SUCCESS") {
      getIncidentListData(0);
    } else {
      getIncidentListData(caseId);
    }
  }, [incidentListDelete]);

  useEffect(() => {
    if (closeIncidentData?.status === "SUCCESS") {
      dispatch(
        incidentAction.getIncidentList({ pageNumber: 1, caseId: caseId })
      );
      dispatch(incidentAction.getCaseDetails(caseId));
    }
  }, [closeIncidentData, dispatch]);

  useEffect(() => {
    getIncidentListData(caseId);
  }, []);

  const handleEditOnClick = () => {
    navigate(EDITCASE, {
      state: {
        caseId: caseId,
        editMode: true,
        isEditModeThroughViewCase: false,
      },
    });
  };

  const closeIncidents = (closeIncident) => {
    dispatch(
      incidentAction.closeIncident({ incidentId: closeIncident.reportId })
    );
  };

  useEffect(() => {
    if (
      incidentListDelete?.status === "SUCCESS" &&
      incidentListDelete?.isLoading === false &&
      clearMessage
    ) {
      toast("Incident Deleted successfully");
    }
  }, [incidentListDelete]);

  const dataBack = {
    title: `${
      caseState?.incidentURL
        ? caseState?.backRouteText
        : "Back to View Cases"
    }`,
    route: caseState?.incidentURL ? caseState?.incidentURL : CASES,
    state: caseState?.apiData,
  };

  return (
    <>
      {isSuccess ? (
        <>
          {caseState?.incidentURL && (
            <BackBtn dataBack={dataBack} className="equipmentBack" />
          )}
          <CaseIncidentList
            pageSet={pageSet}
            incidentList={isListSuccess ? [] : incidentList}
            deleteIncidents={deleteIncidents}
            isFullAccess={checkPrivileges([1, 12])}
            isWriteAccess={checkPrivileges([2, 12])}
            isReadAccess={checkPrivileges([3, 12])}
            isListSuccess={isListSuccess}
            viewCaseData={viewCaseData}
            handleEditOnClick={handleEditOnClick}
            getIncidentListData={getIncidentListData}
            closeIncidents={closeIncidents}
            caseState={caseState}
          />
        </>
      ) : (
        <Loader />
      )}
      <ToastContainer />
    </>
  );
};

export default CaseIncidentsList;
