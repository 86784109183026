import { sequenceLandingType } from "../constants/sequenceLandingType";

export const getSequenceLandingProjectPermit = (data) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_PENDING,
  data,
});

export const getSequenceLandingProjectPermitSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_SUCCESS,
  data,
});

export const getSequenceLandingProjectPermitFailure = (error) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_FAILURE,
  error,
});

export const getSequenceLandingProjectPermitDetails = (data) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_PENDING,
  data,
});

export const getSequenceLandingProjectPermitDetailsSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_SUCCESS,
  data,
});

export const getSequenceLandingProjectPermitDetailsFailure = (error) => ({
  type: sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_FAILURE,
  error,
});

export const getSequenceLandingSamplingLogs = (data) => ({
  type: sequenceLandingType.GET_QR_SAMPLING_LOG_PENDING,
  data,
});

export const getSequenceLandingSamplingLogsSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_SAMPLING_LOG_SUCCESS,
  data,
});

export const getSequenceLandingSamplingLogsFailure = (error) => ({
  type: sequenceLandingType.GET_QR_SAMPLING_LOG_FAILURE,
  error,
});

//  Inspection and resource

export const getSequenceLandingInspection = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_PENDING,
  data,
});

export const getSequenceLandingInspectionSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_SUCCESS,
  data,
});

export const getSequenceLandingInspectionFailure = (error) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_FAILURE,
  error,
});

export const getSequenceLandingInspectionDetails = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_PENDING,
  data,
});

export const getSequenceLandingInspectionDetailsSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_SUCCESS,
  data,
});

export const getSequenceLandingInspectionDetailsFailure = (error) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_PENDING,
  error,
});

export const getSequenceLandingResource = (data) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_PENDING,
  data,
});

export const getSequenceLandingResourceSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_SUCCESS,
  data,
});

export const getSequenceLandingResourceFailure = (error) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_FAILURE,
  error,
});

export const getSequenceLandingResourceDetails = (data) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_VIEW_PENDING,
  data,
});

export const getSequenceLandingResourceDetailsSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_VIEW_SUCCESS,
  data,
});

export const getSequenceLandingResourceDetailsFailure = (error) => ({
  type: sequenceLandingType.GET_QR_RESOURCE_VIEW_FAILURE,
  error,
});

export const getSequenceLandingInspectionFieldType = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_PENDING,
  data,
});

export const getSequenceLandingInspectionFieldTypeSuccess = (data) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_SUCCESS,
  data,
});

export const getSequenceLandingInspectionFieldTypeFailure = (error) => ({
  type: sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_FAILURE,
  error,
});
