import React, { useEffect, useState } from "react";
import EstablishmentAdd from "../component/establishmentAdd";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import * as incidentAction from "../../../redux/actions/incidentAction";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_CATEGORY_INCIDENT,
} from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { API_STATUS } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const EstablishmentAddContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const establishmentId = location?.state?.establishmentId;
  const [isSubmit, setIsSubmit] = useState(false);
  const [successScreens, setIsSuccess] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const stateData = useSelector((state) => state.state);
  const addEstablishment = useSelector((state) => state?.addEstablishment);
  const getEstablishmentDetail = useSelector(
    (state) => state?.establishmentDetail
  );

  const updateEstablishment = useSelector(
    (state) => state?.updateEstablishment
  );

  const isloading = useSelector(
    (state) =>
      state.addEstablishment?.isLoading ||
      state.updateEstablishment?.isLoading ||
      state.state?.isLoading ||
      state?.establishmentDetail?.isLoading
  );

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getEstablishmentDetailData = (data) => {
    dispatch(incidentAction.getEstablishmentDetail(data));
  };

  useEffect(() => {
    getStateData();
  }, []);

  const addEstablishmentHandler = (data) => {
    setIsToast(true);
    if (editMode && data?.establishmentId) {
      dispatch(incidentAction.updateEstablishment(data));
      setIsSubmit(true);
      setIsSuccess(true);
      setIsUpdate(true);
    } else {
      dispatch(incidentAction.addEstablishment(data));
      setIsSubmit(true);
      setIsSuccess(true);
      setIsUpdate(false);
    }
  };

  const successScreen = (id, successMsg, label1, label4, label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={
          location?.state?.route
            ? location?.state?.route
            : ADMIN_CATEGORY_INCIDENT
        }
        label1={label1}
        label4={label4}
        label2={label2}
        establishmentData={location?.state?.year ? "" : { flag: 13 }}
        routeProps={{
          route: location?.state?.route,
          routeText: location?.state?.routeText,
          establishmentId: location?.state?.establishmentId,
          year: location?.state?.year,
          flag: location?.state?.flag ? location?.state?.flag : 13,
        }}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (
      (addEstablishment?.status === "SUCCESS" ||
        updateEstablishment?.status === "SUCCESS") &&
      (updateEstablishment?.data?.responseMessages?.responseCode ===
        "HBNG002" ||
        addEstablishment?.data?.responseMessages?.responseCode === "HBNG002") &&
      isToast
    ) {
      toast(
        addEstablishment?.data?.responseMessages?.responseMessage ||
          updateEstablishment?.data?.responseMessages?.responseMessage
      );
    }
  }, [addEstablishment, updateEstablishment]);

  useEffect(() => {
    dispatch(incidentAction.clearUPdateEstablishment());
    if (editMode && establishmentId) {
      const data = {
        establishmentId: establishmentId,
      };
      getEstablishmentDetailData(data);
    }
  }, [editMode, establishmentId]);

  useEffect(() => {
    setIsToast(false);
  }, []);

  return (
    <>
      {checkPrivileges([152, 153, 12]) ? (
        <>
          {isloading && <Loader />}
          {addEstablishment?.status === API_STATUS.SUCCESS &&
          isSubmit &&
          !isUpdate &&
          addEstablishment &&
          addEstablishment?.data?.responseMessages?.responseCode !==
            "HBNG002" &&
          successScreens ? (
            successScreen(
              addEstablishment?.data?.id,
              "Establishment Added Successfully",
              "Establishment Id",
              "Establishment List in",
              " "
            )
          ) : updateEstablishment?.status === API_STATUS.SUCCESS &&
            successScreens &&
            isSubmit &&
            isUpdate &&
            updateEstablishment?.data?.responseMessages?.responseCode !==
              "HBNG002" &&
            updateEstablishment?.data ? (
            successScreen(
              updateEstablishment?.data?.id,
              "Establishment Updated Successfully",
              "Establishment Id",
              "Establishment List in",
              "has been Updated"
            )
          ) : (
            <>
              <EstablishmentAdd
                editMode={editMode}
                establishmentId={establishmentId}
                isSubmit={isSubmit}
                setIsSubmit={setIsSubmit}
                state={stateData && stateData.data?.getState}
                addEstablishmentHandler={addEstablishmentHandler}
                establishmentDetail={getEstablishmentDetail?.data?.result}
                route={location?.state?.route}
                routeText={location?.state?.routeText}
                year={location?.state?.year}
              />
              <ToastContainer />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default EstablishmentAddContainer;
