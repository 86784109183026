import React, { useEffect, useState } from "react";
import "./qrcodeinnerpage.scss";
import SeqHeader from "./seqHeader";
import SettingTable from "../../feature/incidents/incidentList/SettingTable";
import { Grid } from "@mui/material";
import GridTable from "../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../library/common/Loader";
import NORow from "../../library/custom/noRow/noRow";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../constant/constant";
import ReportRowManipulte from "../../feature/incidents/incidentList/ReportRowManipulte";
import { QR_LANDING_PAGE_PERMITS_DETAIL } from "../../constant/routeContant";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";

const SequencePermits = (props) => {
  const navigate = useNavigate();

  const clientName = props?.clientName;
  const ProjectName = props?.projectName;
  const ProjectNumber = props?.projectNumber;

  const ProjectPermitData = props?.ProjectPermitData?.permits;
  const itemsCount = props?.ProjectPermitData?.itemsCount;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [search, setSearch] = useState();

  const handleOnViewclick = (val) => {
    navigate(QR_LANDING_PAGE_PERMITS_DETAIL, {
      state: {
        permidData: val?.row,
        projectdata: { clientName, ProjectName, ProjectNumber },
      },
    });
  };

  const onRowClick = (rows) => {
    navigate(QR_LANDING_PAGE_PERMITS_DETAIL, {
      state: {
        permidData: rows?.row || {},
        projectdata: { clientName, ProjectName, ProjectNumber },
      },
    });
  };
  const columnPermitHeader = [
    {
      field: "documentUrl",
      headerName: "File Name",
      flex: 1,
      minWidth: 130,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.documentUrl ? (
              <Grid className="catListBox">
                <Grid className="closeIcon closeBtn">
                  <div title={rows?.row?.documentUrl?.slice(15)}>
                    {rows?.row?.documentUrl?.slice(15)}
                  </div>
                </Grid>
              </Grid>
            ) : rows?.row?.documentUrl === undefined ? (
              ""
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },

    {
      field: "permitNumber",
      headerName: "Permit Number",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "holderName",
      headerName: "Permit Holder",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "approvalDate",
      headerName: "Approval Date",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={itemsCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={ProjectPermitData ?? []}
          fileNameXL={"ProjectPermit"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      minWidth: 70,
      renderCell: (rows) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <ReportRowManipulte
              rows={ProjectPermitData}
              viewOnClick={() => handleOnViewclick(rows)}
              download={false}
              view={true}
              viewLabel="View Permits"
            />
          </div>
        );
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    props?.getSequenceLandingProjectPermit({
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      projectId: props?.projectId,
    });
  };
  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    props?.getSequenceLandingProjectPermit({
      page: page,
      pageSize: newPazeSize,
      search: search,
      projectId: props?.projectId,
    });
  };

  const handleautoSearch = (event) => {
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      projectId: props?.projectId,
      page: page,
      pageSize: pageSize,
      search: searchText ? searchText : "",
    };
    props?.getSequenceLandingProjectPermit(data);
  };

  return (
    <>
      <SeqHeader
        clientName={clientName}
        ProjectName={ProjectName}
        ProjectNumber={ProjectNumber}
      />

      <section className="grid_main_sec">
        <div className="gridMainpermit">
          <Grid className="permitBox" lg={12} mb={2}>
            <Grid className="grid_main_header two_grid" lg={12}>
              <Grid lg={8} className="grid_heading_style" space={2}>
                Project Permits
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} xs={12} sm={12} md={6} mb={3}>
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
            </Grid>
          </Grid>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r?.permitId}
                rows={ProjectPermitData ?? []}
                rowCount={itemsCount}
                columns={columnPermitHeader}
                //   loading={props?.ProjectPermitisLoading}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onClick={(rows) => onRowClick(rows)}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>

          <Grid container>
            <QrBackBtn text={"Back"} />
          </Grid>
        </div>
      </section>
    </>
  );
};

export default SequencePermits;
