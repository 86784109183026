import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import UserForm from "../component/users/userForm";
import * as userAction from "../../../redux/actions/userAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ADMIN_USER } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyErrorContractor } from "../../../../src/library/custom/toastMessage/Toast";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import DownloadCsv from "../../download_file/DownloadCsv";
import { useRef } from "react";

const UsersContainer = () => {
  const dowloadCsv = useRef();

  const location = useLocation();
  const dispatch = useDispatch();
  const [successScreens, setSuccessScreens] = useState(false);
  const [setsubmit, setSetsubmit] = useState(false);
  const [divisionSafety, setDivisionSafety] = useState([]);
  const [roleSafety, setRoleSafety] = useState([]);
  const [divisionStormWater, setDivisionStormWater] = useState([]);
  const [roleStormWater, setRoleStormWater] = useState([]);
  const [productID, setProductID] = useState("");
  const [allUserList, setallUserList] = useState([]);
  const [stormDivisionIds, setStormDivisionIds] = useState([]);
  const [safetyDivisionIds, setSafetyDivisionIds] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [duplicateUsers, setDuplicateuser] = useState("");

  const ismultipleUpload = (isBulkUpload) => {
    setIsSingle(isBulkUpload);
  };

  const adduserLists = (data) => {
    if (editMode) {
      setSetsubmit(true);
      setStormDivisionIds(data?.stormDivisionIds);
      setSafetyDivisionIds(data?.safetyDivisionIds);
      dispatch(userAction.updateUserList(data));
      setSuccessScreens(true);
    } else {
      setSetsubmit(true);
      dispatch(userAction.addUserList(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.addUserListData.isLoading ||
      state.updateUserListData.isLoading ||
      state.getUserListDetsilsData.isLoading
  );

  const showLoaders = useSelector(
    (state) => state.getDropdownDataKeyValue.isLoading
  );

  const userId = location?.state?.userId;
  const editMode = location?.state?.editMode;

  const getDropdownDataKeyValues = useSelector(
    (state) => state.getDropdownDataKeyValue
  );

  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );
  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const userData = useSelector((state) => state.getUserListDetsilsData);

  const updateUserListData = useSelector((state) => state.updateUserListData);
  const addUserListData = useSelector((state) => state.addUserListData);
  const updateUserListDataStatus = useSelector(
    (state) => state.updateUserListData.status
  );
  const addUserListDataStatus = useSelector(
    (state) => state.addUserListData.status
  );

  const getDropDowndata = (productsID) => {
    setProductID(productsID);
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "5,6,12",
      productId: productsID,
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  };

  const getUserListDetail = () => {
    const data = {
      userId: userId,
      orgId: ORG_ID(),
    };
    dispatch(userAction.getUserListDetsils(data));
  };

  useEffect(() => {
    dispatch(userAction.clearList());
    setSuccessScreens(false);
    if (userId) {
      getUserListDetail();
    }
  }, []);

  useEffect(() => {
    if (getDropdownDataKeyValues?.status === API_STATUS.SUCCESS) {
      if (productID === 1) {
        setDivisionSafety(getDropdownDataKeyValues?.data?.divisons);
        setRoleSafety(getDropdownDataKeyValues?.data?.roles);
      } else {
        setDivisionStormWater(getDropdownDataKeyValues?.data?.divisons);
        setRoleStormWater(getDropdownDataKeyValues?.data?.roles);
      }
    }
  }, [getDropdownDataKeyValues]);

  useEffect(() => {
    if (addUserListData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (
        addUserListData?.data?.userStatusResponses?.length <= 1 &&
        !isSingle
      ) {
        if (
          addUserListData?.data?.userStatusResponses?.[0]?.status === false &&
          setsubmit
        ) {
          notifyErrorContractor();
        }
        if (
          addUserListData?.data?.userStatusResponses?.[0]?.status === true &&
          setsubmit
        ) {
          setSuccessScreens(true);
        }
      } else if (
        addUserListData?.data?.userStatusResponses?.length >= 1 &&
        isSingle &&
        setsubmit
      ) {
        setIsDownload(true);
        setTimeout(() => {
          setSuccessScreens(true);
        }, 1000);
      }
    }
  }, [addUserListData]);

  useEffect(() => {
    if (addUserListData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (addUserListData?.status === API_STATUS.SUCCESS) {
        const duplicateUserList =
          addUserListData?.data?.userStatusResponses?.filter(
            (user) => !user.status
          );
        if (duplicateUserList?.length >= 1 && isSingle) {
          setDuplicateuser(duplicateUserList);
        }
      }
    }
  }, [addUserListData]);

  useEffect(() => {
    if (duplicateUsers && duplicateUsers?.length >= 1) {
      if (isDownload) {
        dowloadCsv?.current?.link?.click();
      }
    }
  }, [duplicateUsers]);

  useEffect(() => {
    if (updateUserListData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      updateUserListData?.status === API_STATUS.SUCCESS &&
      updateUserListData?.data
    ) {
      const duplicateUserList =
        updateUserListData?.data?.userStatusResponses?.filter(
          (user) => !user.status
        );
      if (
        duplicateUserList?.length == 1 &&
        updateUserListData?.data?.userStatusResponses?.[0]?.status === false &&
        setsubmit
      ) {
        notifyErrorContractor();
      } else {
        setSuccessScreens(true);
      }
    }
    return () => {
      setSuccessScreens(false);
    };
  }, [updateUserListData]);

  const SuccessScree = (observationid) => {
    return (
      <SuccessScreen
        isUpdate={
          editMode ? "User Updated Successfully" : "User Created Successfully"
        }
        route={ADMIN_USER}
        label4="Users List Screen in"
        label1={true}
        label2={true}
        id={observationid}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (
      editMode &&
      updateUserListDataStatus === API_STATUS.SUCCESS &&
      userId === parseInt(localStorage.getItem("userId"))
    ) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const allDivisionData = [...divisionSafety, ...divisionStormWater];
      const swSelectedDivision = allDivisionData.reduce(
        (accumulator, currentValue) => {
          if (stormDivisionIds?.includes(currentValue?.key)) {
            accumulator.push({ serviceType: 2, ...currentValue });
          }
          return accumulator;
        },
        []
      );

      const selectedDivision = allDivisionData.reduce(
        (accumulator, currentValue) => {
          if (safetyDivisionIds?.includes(currentValue?.key)) {
            accumulator.push({ serviceType: 1, ...currentValue });
          }
          return accumulator;
        },
        swSelectedDivision
      );

      userInfo.divisions = selectedDivision;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }, updateUserListDataStatus);

  return (
    <>
        <>
          {(addUserListDataStatus === "SUCCESS" &&
            addUserListData?.data &&
            addUserListData?.data?.userStatusResponses?.[0]?.status === true) ||
          (addUserListData?.data?.userStatusResponses?.length >= 1 &&
            successScreens) ? (
            SuccessScree()
          ) : updateUserListDataStatus === "SUCCESS" &&
            updateUserListData?.data?.userStatusResponses?.[0]?.status ===
              true &&
            updateUserListData?.data?.userStatusResponses?.length == 1 &&
            successScreens ? (
            SuccessScree()
          ) : (
            <>
              {showLoader && <Loader />}
              <div>
                <ToastContainer />
              </div>
              <UserForm
                adduserLists={adduserLists}
                divisions={divisionSafety}
                roles={roleSafety}
                jobTitles={jobTitles}
                userData={editMode ? userData?.data : ""}
                editMode={editMode}
                userId={userId}
                divisionStormWater={divisionStormWater}
                roleStormWater={roleStormWater}
                getDropDowndata={getDropDowndata}
                ismultipleUpload={ismultipleUpload}
              />
              <DownloadCsv
                ref={dowloadCsv}
                data={duplicateUsers}
                filename={"Already Exist User List"}
              />
            </>
          )}
        </>
    </>
  );
};

export default UsersContainer;