import React, { useState, useEffect } from "react";
import OshaItaSubmission from "../component/oshaItaSubmission";
import { useDispatch, useSelector } from "react-redux";
import * as incidentAction from "../../../redux/actions/incidentAction";
import Loader from "../../../library/common/Loader";
import { generateYears } from "../../../utils/helper";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ESTABLISHMENT_ADD,
  OSHA_ITASUBMISSION,
  OSHA_RECORDKEEPING,
  OSHA_VIEW_REPORT_LIST,
} from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { API_STATUS } from "../../../constant/constant";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const OshaItaSubmissionContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const years = generateYears(currentYear - 2, currentYear);
  const [establishmentId, setEstablishmentId] = useState();
  const [year, setYear] = useState(currentYear.toString() - 1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [successScreens, setIsSuccess] = useState(false);

  const incident = useSelector((state) => state.incident.data);
  const getOshaDetails = useSelector((state) => state.getOshaDetails);
  const addOshaSubmission = useSelector((state) => state?.addOshaDetails);
  const submitFromRecordkeeping =
    location?.state?.submitFromRecordkeeping ?? false;

  const getEstablishmentDetail = useSelector(
    (state) => state?.establishmentDetail
  );

  const getEstablishmentDetailData = (data) => {
    dispatch(incidentAction.getEstablishmentDetail(data));
  };

  const isLoader = useSelector(
    (state) =>
      state?.addOshaDetails?.isLoading ||
      state?.establishmentDetail?.isLoading ||
      state?.incident?.isLoading ||
      state.getOshaDetails?.isLoading
  );

  useEffect(() => {
    if (establishmentId) {
      const data = {
        establishmentId: establishmentId,
      };
      getEstablishmentDetailData(data);
    }
  }, [establishmentId]);

  const getIncidentData = () => {
    dispatch(incidentAction.getIncidentData());
  };
  useEffect(() => {
    getIncidentData();
  }, []);

  const getOshaDetailsData = (data) => {
    dispatch(incidentAction.getOshaDetails(data));
  };

  useEffect(() => {
    if (establishmentId && year) {
      const defaultData = {
        establishmentId: establishmentId,
        year: year,
      };

      getOshaDetailsData(defaultData);
    }
  }, [establishmentId, year]);

  const addOshaSubmissionHandler = (data) => {
    dispatch(incidentAction.addOshaDetails(data));
    setIsSubmit(true);
    setIsSuccess(true);
  };

  const successScreen = (id, successMsg, label1, label4, label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={OSHA_VIEW_REPORT_LIST}
        label1={label1}
        label4={label4}
        label2={label2}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (
      (isSubmit &&
        addOshaSubmission?.status === "SUCCESS" &&
        addOshaSubmission?.data?.responseMessages?.responseCode ===
          "HBNG002") ||
      (isSubmit &&
        getOshaDetails?.status === "SUCCESS" &&
        getOshaDetails?.data?.responseMessages?.responseCode === "HBNG002")
    ) {
      toast(
        addOshaSubmission?.data?.responseMessages?.responseMessage ||
          getOshaDetails?.data?.responseMessages?.responseMessage
      );
    }
  }, [addOshaSubmission, getOshaDetails]);

  useEffect(() => {
    if (location?.state?.oshaDetail) {
      setYear(location?.state?.year);
      setEstablishmentId(location?.state?.establishmentId);
    }
  }, [location?.state?.oshaDetail]);

  const editEstablishment = (id) => {
    navigate(ESTABLISHMENT_ADD, {
      state: {
        establishmentId: id,
        editMode: true,
        route: OSHA_ITASUBMISSION,
        routeText: "Back to OSHA ITA Submission",
        year: year,
      },
    });
  };

  useEffect(() => {
    if (location?.state?.flag) {
      setYear(location?.state?.year);
      setEstablishmentId(location?.state?.establishmentId);
    }
  }, [location?.state?.flag]);

  const backHandler = () => {
    navigate(OSHA_RECORDKEEPING);
  };

  return (
    <>
      {checkPrivileges([12, 152, 153, 154]) ? (
        <>
          {isLoader && <Loader />}
          {addOshaSubmission?.status === API_STATUS.SUCCESS &&
          isSubmit &&
          addOshaSubmission &&
          addOshaSubmission?.data?.responseMessages?.responseCode !==
            "HBNG002" &&
          successScreens ? (
            successScreen(
              addOshaSubmission?.data?.id,
              "Osha Ita Submission Added Successfully",
              "Osha Ita Submission Id",
              "Osha Ita Submission List in",
              " "
            )
          ) : (
            <>
              <OshaItaSubmission
                backHandler={backHandler}
                years={years}
                setYear={setYear}
                year={year}
                establishmentDetail={getEstablishmentDetail?.data?.result}
                establishments={incident?.locationOfRecord}
                establishmentId={establishmentId}
                setEstablishmentId={setEstablishmentId}
                oshaDetail={getOshaDetails?.data?.oshaLogDetails}
                addOshaSubmissionHandler={addOshaSubmissionHandler}
                showDetail={
                  getOshaDetails?.data?.responseMessages?.responseCode !==
                  "HBNG002"
                }
                editEstablishment={editEstablishment}
                submitFromRecordkeeping={submitFromRecordkeeping}
                currentYear={currentYear}
              />
              <ToastContainer />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default OshaItaSubmissionContainer;
