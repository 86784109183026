import React, { useEffect, useState } from "react";
import SeqHeader from "./seqHeader";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import ViewImages from "../../feature/case/common/viewImages/viewImages";
import "./qrcodeinnerpage.scss";
import { useNavigate } from "react-router-dom";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";

const SequencePermitsDetail = (props) => {
  const clientName = props?.clientName;
  const ProjectName = props?.projectName;
  const ProjectNumber = props?.projectNumber;

  const permitDetails = props?.permitDetails;

  const imageSize = {
    size: "small",
  };
  const imageSizeSignature = {
    size: "small",
  };
  const [image, setImage] = useState([]);
  const [signatureImage, setSignatureImage] = useState([]);

  useEffect(() => {
    setImage(permitDetails?.documentUrl);
    setSignatureImage(permitDetails?.signatureUrl);
  }, [permitDetails]);
  useEffect(() => {
    console.log(image);
  }, [image]);

  return (
    <>
      <SeqHeader
        clientName={clientName}
        ProjectName={ProjectName}
        ProjectNumber={ProjectNumber}
      />
      <div className="seqPermitDetail">
        <section className="grid_main_sec">
          <>
            <div className="viewOverideContact">
              <Grid className="permitBox" lg={12} mb={2}>
                <Grid className="grid_main_header two_grid" lg={12}>
                  <Grid lg={8} className="grid_heading_style" space={2}>
                    Permit Details
                  </Grid>
                </Grid>
              </Grid>

              <div className="commonOveride">
                <Box className="commoninfoWrap">
                  <Box className="commoninfoBox">
                    <Box className="commoninfoleft widthIncr">
                      <Typography className="title">Details:</Typography>
                      <Box className="columns">
                        <Typography className="listView">
                          Permittee ID :
                          <span className="font-w400">
                            {permitDetails?.permitId}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Permit Type :
                          <span className="font-w400">
                            {permitDetails?.permitName}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Permit Holder :
                          <span className="font-w400">
                            {permitDetails?.holderName}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Permit Number :
                          <span className="font-w400">
                            {permitDetails?.permitNumber}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Project Name :
                          <span className="font-w400">
                            {" "}
                            {permitDetails?.projectName}
                          </span>
                        </Typography>
                        <Typography className="listView"></Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Approval Date :
                          <span className="font-w400">
                            {permitDetails?.approvalDate}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Project Permit Expiration Date:
                          <span className="font-w400">
                            {permitDetails?.expirationDate}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns noWrapText">
                        <Typography className="listView">
                          General Permit Expiration Date:
                          <span className="font-w400">
                            {permitDetails?.sitePermitExpirationDate}
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="listView ">
                          Permit :
                          <span className="blockView">
                            <ViewImages
                              image={image?.length > 0 ? [image] : []}
                              imageSize={imageSize}
                            />
                          </span>
                        </Typography>
                      </Box>
                      {permitDetails?.stateId === 3 &&
                      (permitDetails?.permitName === "primary" ||
                        permitDetails?.permitName === "Primary") ? (
                        <Box>
                          <Typography className="listView ">
                            Signature :
                            <span className="blockView">
                              <ViewImages
                                image={
                                  signatureImage?.length > 0
                                    ? [signatureImage]
                                    : []
                                }
                                imageSize={imageSizeSignature}
                              />
                            </span>
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>

            <Grid container>
              <QrBackBtn text={"Back"} />
            </Grid>
          </>
        </section>
      </div>
    </>
  );
};

export default SequencePermitsDetail;
