import React, { useEffect, useState } from "react";
import SequenceLanding from "../component/sequenceLanding";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QR_LANDING_PAGE_INSPECTION_REPORT,
  QR_LANDING_PAGE_PERMITS,
  QR_LANDING_PAGE_RESOURCES,
  QR_LANDING_PAGE_SAMPLINGLOGS,
} from "../../constant/routeContant";

const SequenceLandingContainer = (props) => {
  const navigate = useNavigate();
console.log(props);
  const onClickHandler = (id, paramas) => {
   
    if (id === 1) {
      navigate(QR_LANDING_PAGE_INSPECTION_REPORT, { state: paramas });
    } else if (id === 2) {
      navigate(QR_LANDING_PAGE_RESOURCES, { state: paramas });
    } else if (id === 3) {
      navigate(QR_LANDING_PAGE_PERMITS, { state: paramas });
    } else if (id === 4) {
      navigate(QR_LANDING_PAGE_SAMPLINGLOGS, { state: paramas });
    }
  };

  return (
    <>
      <SequenceLanding onClickHandler={onClickHandler} />
    </>
  );
};

export default SequenceLandingContainer;
