import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../../feature/download_file/DownloadCsv";
import commonImages from "../../../assets";

export default function TableRowAction(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    publish,
    publishText,
    publishOnClick,
    edit,
    download,
    view,
    deleted,
    deleteOnClick,
    viewLabel = "View Incident",
    deleteLabel = "Delete Incident",
    editLabel = "Edit Incident",
    downloadLabel = "Download Incident",
    viewOnAttendeeClick,
    VerifyAttendees,
    viewAttendeeLabel,
    CloseIncident = false,
    CloseIncidentLabel,
    closeOnClick,
    AssignAttendees,
    AssignAttendeeOnClick,
    ScheduleAttendeeLabel,
    scheduleMeetingOnclick,
    assignLabel,
    ScheduleMeetings,
    selfAssignClick,
    SelfAssign,
    selfAssignLabel,
    showMenu = true,
    editCase = false,
    editCaseOnClick,
    editCaseLabel
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      {showMenu && (
        <Menu
          anchorEl={anchorEl}
          id="setting-menu"
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="setting_button_ui">
            <div className="dropdownList_ui">
              <ul>
                {view && (
                  <li onClick={() => viewOnClick(row)}>
                    <RemoveRedEye /> <span>{viewLabel}</span>
                  </li>
                )}

                {edit && (
                  <li
                    // className={statusClosed ? "disabledBtnLi" : null}
                    onClick={() => editOnClick(row)}
                  >
                    <Edit /> <span>{editLabel}</span>
                  </li>
                )}
                {download && (
                  <li onClick={() => dowloadCsv.current.link.click()}>
                    <FileDownload /> <span>{downloadLabel}</span>
                  </li>
                )}
                {AssignAttendees && (
                  <li
                    onClick={() => AssignAttendeeOnClick(row)}
                    className="deleteIcon"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 3C7.14444 3 7.26389 2.95278 7.35833 2.85833C7.45278 2.76389 7.5 2.64444 7.5 2.5C7.5 2.35556 7.45278 2.23611 7.35833 2.14167C7.26389 2.04722 7.14444 2 7 2C6.85556 2 6.73611 2.04722 6.64167 2.14167C6.54722 2.23611 6.5 2.35556 6.5 2.5C6.5 2.64444 6.54722 2.76389 6.64167 2.85833C6.73611 2.95278 6.85556 3 7 3ZM11.9953 18C10.8873 18 9.94444 17.6095 9.16667 16.8286C8.38889 16.0477 8 15.1033 8 13.9953C8 12.8873 8.39046 11.9444 9.17138 11.1667C9.95229 10.3889 10.8967 10 12.0047 10C13.1127 10 14.0556 10.3905 14.8333 11.1714C15.6111 11.9523 16 12.8967 16 14.0047C16 15.1127 15.6095 16.0556 14.8286 16.8333C14.0477 17.6111 13.1033 18 11.9953 18ZM11.5 16H12.5V14.5H14V13.5H12.5V12H11.5V13.5H10V14.5H11.5V16ZM3 6.5H11V5H3V6.5ZM6.85417 16H1.5C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3.5C0 3.0875 0.146875 2.73438 0.440625 2.44062C0.734375 2.14687 1.0875 2 1.5 2H5.0625C5.17361 1.56944 5.40625 1.21181 5.76042 0.927083C6.11458 0.642361 6.52778 0.5 7 0.5C7.47222 0.5 7.88542 0.642361 8.23958 0.927083C8.59375 1.21181 8.82639 1.56944 8.9375 2H12.5C12.9125 2 13.2656 2.14687 13.5594 2.44062C13.8531 2.73438 14 3.0875 14 3.5V8.875C13.6705 8.76389 13.3381 8.68056 13.0028 8.625C12.6676 8.56944 12.3333 8.54167 12 8.54167C11.8333 8.54167 11.6667 8.54861 11.5 8.5625C11.3333 8.57639 11.1667 8.59722 11 8.625V8.25H3V9.75H8.54167C8.23611 9.98611 7.95833 10.25 7.70833 10.5417C7.45833 10.8333 7.24306 11.1528 7.0625 11.5H3V13H6.54167C6.51389 13.1667 6.49306 13.3333 6.47917 13.5C6.46528 13.6667 6.46528 13.8333 6.47917 14C6.49306 14.3194 6.53125 14.6562 6.59375 15.0104C6.65625 15.3646 6.74306 15.6944 6.85417 16Z"
                        fill="#1B436E"
                      />
                    </svg>

                    <span>{assignLabel}</span>
                  </li>
                )}
                {ScheduleMeetings && (
                  <li
                    onClick={() => scheduleMeetingOnclick(row)}
                    className="deleteIcon"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.125 14.2917L4.16667 11.3334L5.375 10.1251L7.125 11.8751L10.625 8.37508L11.8333 9.58341L7.125 14.2917ZM2.16667 17.3334C1.70833 17.3334 1.31597 17.1702 0.989583 16.8438C0.663194 16.5174 0.5 16.1251 0.5 15.6667V4.00008C0.5 3.54175 0.663194 3.14939 0.989583 2.823C1.31597 2.49661 1.70833 2.33341 2.16667 2.33341H3V0.666748H4.66667V2.33341H11.3333V0.666748H13V2.33341H13.8333C14.2917 2.33341 14.684 2.49661 15.0104 2.823C15.3368 3.14939 15.5 3.54175 15.5 4.00008V15.6667C15.5 16.1251 15.3368 16.5174 15.0104 16.8438C14.684 17.1702 14.2917 17.3334 13.8333 17.3334H2.16667ZM2.16667 15.6667H13.8333V7.33341H2.16667V15.6667Z"
                        fill="#1B436E"
                      />
                    </svg>
                    <span> {ScheduleAttendeeLabel}</span>
                  </li>
                )}
                {VerifyAttendees && (
                  <li
                    onClick={() => viewOnAttendeeClick(row)}
                    className="deleteIcon"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.938 11L11.896 6.062L10.833 5L6.938 8.875L5.167 7.125L4.104 8.188L6.938 11ZM8 16C6.90267 16 5.868 15.7917 4.896 15.375C3.924 14.9583 3.07333 14.3853 2.344 13.656C1.61467 12.9267 1.04167 12.076 0.625 11.104C0.208333 10.132 0 9.09733 0 8C0 6.88867 0.208333 5.85033 0.625 4.885C1.04167 3.92033 1.61467 3.07333 2.344 2.344C3.07333 1.61467 3.924 1.04167 4.896 0.625C5.868 0.208333 6.90267 0 8 0C9.11133 0 10.1497 0.208333 11.115 0.625C12.0797 1.04167 12.9267 1.61467 13.656 2.344C14.3853 3.07333 14.9583 3.92033 15.375 4.885C15.7917 5.85033 16 6.88867 16 8C16 9.09733 15.7917 10.132 15.375 11.104C14.9583 12.076 14.3853 12.9267 13.656 13.656C12.9267 14.3853 12.0797 14.9583 11.115 15.375C10.1497 15.7917 9.11133 16 8 16Z"
                        fill="#1B436E"
                      />
                    </svg>

                    <span> {viewAttendeeLabel}</span>
                  </li>
                )}
                {deleted && (
                  <li
                    // className={statusClosed ? "disabledBtnLi" : null}
                    onClick={() => deleteOnClick(row)}
                    className="deleteIcon"
                  >
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.83331 15.5C2.37498 15.5 1.98276 15.3369 1.65665 15.0108C1.32998 14.6842 1.16665 14.2917 1.16665 13.8333V3H0.333313V1.33333H4.49998V0.5H9.49998V1.33333H13.6666V3H12.8333V13.8333C12.8333 14.2917 12.6703 14.6842 12.3441 15.0108C12.0175 15.3369 11.625 15.5 11.1666 15.5H2.83331ZM4.49998 12.1667H6.16665V4.66667H4.49998V12.1667ZM7.83331 12.1667H9.49998V4.66667H7.83331V12.1667Z"
                        fill="#1B436E"
                      />
                    </svg>
                    <span> {deleteLabel}</span>
                  </li>
                )}
                {CloseIncident && (
                  <li
                    onClick={() => closeOnClick(row)}
                    className="closeIncident"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.938 11L11.896 6.062L10.833 5L6.938 8.875L5.167 7.125L4.104 8.188L6.938 11ZM8 16C6.90267 16 5.868 15.7917 4.896 15.375C3.924 14.9583 3.07333 14.3853 2.344 13.656C1.61467 12.9267 1.04167 12.076 0.625 11.104C0.208333 10.132 0 9.09733 0 8C0 6.88867 0.208333 5.85033 0.625 4.885C1.04167 3.92033 1.61467 3.07333 2.344 2.344C3.07333 1.61467 3.924 1.04167 4.896 0.625C5.868 0.208333 6.90267 0 8 0C9.11133 0 10.1497 0.208333 11.115 0.625C12.0797 1.04167 12.9267 1.61467 13.656 2.344C14.3853 3.07333 14.9583 3.92033 15.375 4.885C15.7917 5.85033 16 6.88867 16 8C16 9.09733 15.7917 10.132 15.375 11.104C14.9583 12.076 14.3853 12.9267 13.656 13.656C12.9267 14.3853 12.0797 14.9583 11.115 15.375C10.1497 15.7917 9.11133 16 8 16Z"
                        fill="#1B436E"
                      />
                    </svg>
                    <span> {CloseIncidentLabel}</span>
                  </li>
                )}
                {publish && (
                  <li
                    onClick={() => publishOnClick(row)}
                    className="deleteIcon"
                  >
                    {/* <RemoveRedEye /> */}
                    {publishText === "Publish Lesson" ||
                    publishText === "Publish Course" ? (
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 11.8333V8.49996C0.5 7.34718 0.906389 6.3644 1.71917 5.55163C2.53139 4.7394 3.51389 4.33329 4.66667 4.33329H12.3333L9.33333 1.33329L10.5 0.166626L15.5 5.16663L10.5 10.1666L9.33333 8.99996L12.3333 5.99996H4.66667C3.97222 5.99996 3.38194 6.24302 2.89583 6.72913C2.40972 7.21524 2.16667 7.80552 2.16667 8.49996V11.8333H0.5Z"
                          fill="#1B436E"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5 0.166707L15.5 3.50004C15.5 4.65282 15.0936 5.6356 14.2808 6.44837C13.4686 7.2606 12.4861 7.66671 11.3333 7.66671L3.66667 7.66671L6.66667 10.6667L5.5 11.8334L0.5 6.83337L5.5 1.83337L6.66667 3.00004L3.66667 6.00004L11.3333 6.00004C12.0278 6.00004 12.6181 5.75698 13.1042 5.27087C13.5903 4.78476 13.8333 4.19448 13.8333 3.50004L13.8333 0.166707L15.5 0.166707Z"
                          fill="#1B436E"
                        />
                      </svg>
                    )}

                    <span>{publishText}</span>
                  </li>
                )}
                {SelfAssign && (
                  <li
                    onClick={() => selfAssignClick(row)}
                    className="deleteIcon"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 14C6.02778 14 5.11713 13.8153 4.26806 13.4458C3.41898 13.0764 2.68009 12.5773 2.0514 11.9486C1.42269 11.3199 0.923611 10.581 0.554167 9.73194C0.184722 8.88287 0 7.97222 0 7C0 6.02778 0.184722 5.11713 0.554167 4.26806C0.923611 3.41898 1.42269 2.68009 2.0514 2.0514C2.68009 1.42269 3.41898 0.923611 4.26806 0.554167C5.11713 0.184722 6.02778 0 7 0V1.5C5.47222 1.5 4.17361 2.03318 3.10417 3.09954C2.03472 4.16592 1.5 5.46453 1.5 6.99538C1.5 8.52624 2.03363 9.82639 3.1009 10.8958C4.16816 11.9653 5.46786 12.5 7 12.5V14ZM10.5 10.5L9.4375 9.4375L11.125 7.75H5V6.25H11.125L9.4375 4.5625L10.5 3.5L14 7L10.5 10.5Z"
                        fill="#1B436E"
                      />
                    </svg>

                    <span> {selfAssignLabel}</span>
                  </li>
                )}
                {editCase && (
                  <li
                    onClick={() => editCaseOnClick(row)}
                  >
                    <Edit /> <span>{editCaseLabel}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Menu>
      )}
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
