import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { sideNavImg } from "../../assets";
import { useNavigate } from "react-router-dom";
import {
  ADD_INSPECTION,
  DASHBOARD,
  SUPPORT,
  OBSERVATION,
  REPRIMAND,
  INCIDENT_LIST,
  VIEW_INSPECTION_REPORT,
  REPRIMAND_LIST,
  CREATE_LESSON,
  CREATE_COURSE,
  LESSON,
  OPEN_ITEMS_LIST,
  VIEW_OBSERVATION_LISTS,
  ADMIN_SAFETY_LOG,
  USERCOURSES,
  INSPECTION_ADMIN,
  ADMIN_CLIENT_LIST,
  ADMIN_UTILITY,
  ADMIN_COMPANY_MANAGEMENT,
  ADMIN_USER_MANAGEMENT,
  ADMIN_ASSET_MANAGEMENT,
  ADMIN_CREDENTIAL_MANAGEMENT,
  ADMIN_RESOURCE,
  REPORTS_ANALYTICS,
  ADDCASE,
  CASES,
  TOOL_BOX_LIST,
  ADMIN_CONTRACTOR_MANAGEMENT,
  ADMIN_CONTRACTOR_DETAILS,
  TOOL_BOX_USER,
  SW_INSPECTION_CONFIGURATION,
  ADMIN_JHA_CONFIGURATION,
  SW_INSPECTION_LIST,
  CONDUCT_JHA,
  CONDUCT_JHA_LIST,
  SW_OPEN_ITEMS,
  SWDASHBOARD,
  ADMIN_PROJECTS_LIST,
  ADMIN_SITE_PROJECTS_LIST,
  SW_INSPECTION_ADD,
  SW_INSPECTION_DAILY_VIEW,
  SW_REPORTS_ANALYTICS,
  ADD_INSPECTION_NEW,
  OSHA_VIEW_REPORT_LIST,
  OSHA_RECORDKEEPING,
  OSHA_ITASUBMISSION,
} from "../../constant/routeContant";
import "./sidebar.scss";
import { logos } from "../../assets";
import { checkPrivileges, showRighSideMenu } from "../../utils/rolesHelper";
import { LOGIN_ORG_ID, HBNEXT_ORG_ID, ORG_ID } from "../../constant/constant";

const AppMenu = ({ handleRoute, location }) => {
  const navigate = useNavigate();
  const [inspection, setInspection] = React.useState(false);
  const [swInspection, setSwInspection] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [oshaManagement, setOshaManagement] = React.useState(false);
  const [incident, setIncident] = React.useState(false);
  const [openItem, setOpenItem] = React.useState(false);
  const [training, setTraining] = React.useState(false);
  const [jhaReports, setJHAReports] = React.useState(false);
  const [activeClass, setActiveClass] = useState();
  const showMenu = showRighSideMenu();
  const siteURL =
    checkPrivileges([12, 111, 112, 113]) &&
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
      ? ADMIN_PROJECTS_LIST
      : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
        checkPrivileges([108, 109, 110])
      ? ADMIN_SITE_PROJECTS_LIST
      : ADMIN_PROJECTS_LIST;
  const onRouteChange = (url) => {
    handleRoute(url);
    setActiveClass({ active: url });
  };
  const userInfoDetails = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (location?.pathname) {
      setActiveClass({ active: location?.pathname });
    }
  }, [location]);

  return (
    <>
      <div className="logo">
        <img
          src={logos?.logo}
          width="110px"
          className="light-logo"
          alt="no logo"
        />
        <img
          src={logos?.logoWhite}
          width="110px"
          className="dark-logo"
          alt="no logo"
        />
      </div>
      <List component="nav" className="appMenu" disablePadding>
        {/* Dashboard swdahsboard*/}
        <ListItem
          button
          className={
            activeClass?.active === DASHBOARD
              ? "menuItem activeClass"
              : "menuItem"
          }
          onClick={() =>
            onRouteChange(showMenu?.isSW ? SWDASHBOARD : DASHBOARD)
          }
        >
          <ListItemIcon className={"menuItemIcon"}>
            <img src={sideNavImg?.homeIcon} alt="Home" />
          </ListItemIcon>
          <ListItemText className="menuText" primary="Dashboard" />
        </ListItem>
        {/* Admin */}
        {checkPrivileges([
          11, 12, 13, 37, 38, 39, 40, 41, 42, 43, 44, 45, 47, 48, 49, 50, 51,
          52, 53, 54, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 78, 79, 80, 93, 94, 95, 99, 100, 101, 108, 109, 110, 111,
          112, 113, 120, 121, 122, 123, 1, 2, 14, 55, 56, 57, 76, 77, 7, 96, 97,
          98, 126, 127, 148, 150, 151, 152, 153, 154,
        ]) && (
          <ListItem
            button
            onClick={() => setAdmin(!admin)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.adminIcon} alt="Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Admin" />
            {admin ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={admin} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 67, 68, 69]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CLIENT_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CLIENT_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Client Management"
                />
              </ListItem>
            )}
            {showMenu?.isSW &&
              checkPrivileges([111, 112, 113, 108, 109, 110, 12]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === siteURL
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(siteURL)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Site Management"
                  />
                </ListItem>
              )}

            {checkPrivileges([40, 41, 42, 49, 50, 51, 73, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_COMPANY_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_COMPANY_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Company Management"
                />
              </ListItem>
            )}

            {checkPrivileges([12, 55, 56, 57, 64, 65, 66, 77, 76, 14, 148]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_USER_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_USER_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="User Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety &&
              checkPrivileges([
                12, 37, 38, 39, 52, 53, 54, 70, 71, 72, 78, 79, 80,
              ]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === ADMIN_ASSET_MANAGEMENT
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(ADMIN_ASSET_MANAGEMENT)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Asset Management"
                  />
                </ListItem>
              )}

            {checkPrivileges([12, 13, 47, 48]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CREDENTIAL_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CREDENTIAL_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Credential Management"
                />
              </ListItem>
            )}

            {checkPrivileges([
              12, 1, 4, 11, 90, 7, 64, 78, 52, 43, 37, 13, 44,
            ]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_UTILITY
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_UTILITY)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Configuration Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety && checkPrivileges([12, 120, 121, 122]) && (
              <ListItem
                button
                className={
                  activeClass?.active === INSPECTION_ADMIN
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(INSPECTION_ADMIN)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Inspection Management"
                />
              </ListItem>
            )}

            {showMenu?.isSW &&
              checkPrivileges([12, 93, 94, 95, 96, 97, 98, 99, 100, 101]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === SW_INSPECTION_CONFIGURATION
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(SW_INSPECTION_CONFIGURATION)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Inspection Management"
                  />
                </ListItem>
              )}

            {showMenu?.isSafety && checkPrivileges([12, 43, 44, 45]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CONTRACTOR_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CONTRACTOR_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Contractor Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety &&
              checkPrivileges([12, 58, 59, 60, 61, 62, 63]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === ADMIN_JHA_CONFIGURATION
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(ADMIN_JHA_CONFIGURATION)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Hazard Analysis Configuration"
                  />
                </ListItem>
              )}
          </List>
        </Collapse>

        {/* inspection */}
        {showMenu?.isSafety && checkPrivileges([12, 16, 4, 5, 6]) && (
          <ListItem
            button
            onClick={() => setInspection(!inspection)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.inspections} alt="Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Inspections" />
            {inspection ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={inspection} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 16, 4, 5]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADD_INSPECTION_NEW
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADD_INSPECTION_NEW)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Report"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 16, 4, 5, 6]) && (
              <ListItem
                button
                className={
                  activeClass?.active === VIEW_INSPECTION_REPORT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(VIEW_INSPECTION_REPORT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Report"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/* sw inspection */}
        {showMenu?.isSW && checkPrivileges([12, 102, 103, 104]) && (
          <>
            <ListItem
              button
              onClick={() => setSwInspection(!swInspection)}
              className={"menuItem"}
            >
              <ListItemIcon className={"menuItemIcon"}>
                <img src={sideNavImg?.inspections} alt="swInspection" />
              </ListItemIcon>
              <ListItemText className="menuText" primary="Inspections" />
              {inspection ? (
                <IconExpandLess className="menuIcon" />
              ) : (
                <IconExpandMore className="menuIcon" />
              )}
            </ListItem>
            <Collapse in={swInspection} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                {checkPrivileges([12, 102, 103]) && (
                  <ListItem
                    button
                    className={
                      activeClass?.active === SW_INSPECTION_ADD
                        ? "menuItem activeClass"
                        : "menuItem"
                    }
                    onClick={() => onRouteChange(SW_INSPECTION_ADD)}
                  >
                    <ListItemText
                      className="menuSubText"
                      inset
                      primary="Add Report"
                    />
                  </ListItem>
                )}
                {checkPrivileges([12, 102, 103, 104]) && (
                  <>
                    <ListItem
                      button
                      className={
                        activeClass?.active === SW_INSPECTION_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(SW_INSPECTION_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Report"
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={
                        activeClass?.active === SW_INSPECTION_DAILY_VIEW
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(SW_INSPECTION_DAILY_VIEW)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Daily Report"
                      />
                    </ListItem>
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* JHA inspection */}
        {showMenu?.isSafety && checkPrivileges([12, 123, 124, 125]) && (
          <ListItem
            button
            onClick={() => setJHAReports(!jhaReports)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="JHA Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Hazard Analysis" />
            {jhaReports ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={jhaReports} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {showMenu?.isSafety && checkPrivileges([12, 123, 124]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CONDUCT_JHA
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CONDUCT_JHA)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Hazard Analysis Report"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 123, 124, 125]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CONDUCT_JHA_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CONDUCT_JHA_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Hazard Analysis Report"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/*incident */}
        {showMenu?.isSafety && checkPrivileges([1, 2, 3, 12]) && (
          <ListItem
            button
            onClick={() => setIncident(!incident)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.incidentmanagement} alt="Incidents" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Incident Management" />
            {incident ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={incident} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([1, 2, 3, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CASES
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CASES)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Cases"
                />
              </ListItem>
            )}
            {checkPrivileges([1, 2, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADDCASE
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADDCASE)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Case"
                />
              </ListItem>
            )}

            {checkPrivileges([12, 1, 2, 3]) && (
              <ListItem
                button
                className={
                  activeClass?.active === INCIDENT_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(INCIDENT_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Incidents"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/*OSHA Management*/}
        {showMenu?.isSafety && checkPrivileges([12, 152, 153, 154]) && (
          <>
            <ListItem
              button
              onClick={() => setOshaManagement(!oshaManagement)}
              className={"menuItem"}
            >
              <ListItemIcon className={"menuItemIcon"}>
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_9483_2153)">
                    <path
                      d="M36.8229 6.94404C36.6313 5.45556 36.5036 3.96709 36.5036 2.50929C35.5139 2.38545 32.8008 2.04418 31.9074 2.04418C27.7895 2.04418 24.8533 4.43208 24.8533 8.46364C24.8533 15.9375 34.2372 14.2319 34.2372 19.721C34.2372 21.9228 32.4817 23.2564 30.3112 23.2564C27.2789 23.2564 25.7468 20.9615 25.3639 18.3256L24.47 18.5115C24.6297 20.093 24.8533 21.6438 25.0125 23.2253C26.5767 24.0007 28.3004 24.621 30.088 24.621C34.1735 24.621 37.8124 22.3878 37.8124 18.1085C37.8124 10.6654 28.2367 12.2162 28.2367 7.06821C28.2367 4.61794 29.6412 3.16071 32.1624 3.16071C34.365 3.16071 35.7058 5.0835 35.9289 7.09922"
                      fill="#1A446F"
                    />
                    <path
                      d="M7.01461 9.51319C8.18779 7.93886 10.0951 6.91482 12.2484 6.91482C15.815 6.91482 18.7061 9.72366 18.7061 13.189C18.7061 16.6541 15.815 19.4629 12.2484 19.4629C9.60849 19.4629 7.33859 17.924 6.3374 15.7187C7.14161 16.6168 8.32647 17.1843 9.64805 17.1843C12.0709 17.1843 14.0349 15.2761 14.0349 12.9221C14.0349 10.5681 12.071 8.66006 9.64805 8.66006C8.65993 8.66006 7.74815 8.97774 7.01461 9.51319Z"
                      fill="#1A446F"
                    />
                    <path
                      d="M0.5 13.1641C0.5 8.2635 4.58895 4.2906 9.63316 4.2906C12.918 4.2906 15.7979 5.97573 17.4073 8.50477C16.1187 7.16574 14.2841 6.32891 12.2484 6.32891C8.34906 6.32891 5.18774 9.40026 5.18774 13.1891C5.18774 16.9779 8.34906 20.0493 12.2484 20.0493C14.2341 20.0493 16.0283 19.253 17.3111 17.9711C15.6853 20.418 12.8538 22.0377 9.63304 22.0377C4.58895 22.0376 0.5 18.0649 0.5 13.1641Z"
                      fill="#1A446F"
                    />
                    <path
                      d="M1.17505 8.43869C3.03539 4.37073 7.2304 1.53391 12.1077 1.53391C18.7114 1.53391 24.0649 6.73503 24.0649 13.1512C24.0649 19.5676 18.7114 24.769 12.1077 24.769C7.27585 24.769 3.11323 21.9843 1.22744 17.9771C2.92469 20.7691 6.05375 22.6419 9.63319 22.6419C15.0208 22.6419 19.3883 18.3985 19.3883 13.1643C19.3883 7.92954 15.0208 3.68638 9.63319 3.68638C6.01662 3.68638 2.85958 5.59816 1.17505 8.43869Z"
                      fill="#1A446F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9483_2153">
                      <rect
                        width="24"
                        height="24.7742"
                        fill="white"
                        transform="translate(0.5 0.73941)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </ListItemIcon>
              <ListItemText className="menuText" primary="OSHA Management" />
              {oshaManagement ? (
                <IconExpandLess className="menuIcon" />
              ) : (
                <IconExpandMore className="menuIcon" />
              )}
            </ListItem>

            <Collapse in={oshaManagement} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                <>
                  <ListItem
                    button
                    className={
                      activeClass?.active === OSHA_RECORDKEEPING ||
                      activeClass?.active === OSHA_ITASUBMISSION
                        ? "menuItem activeClass"
                        : "menuItem"
                    }
                    onClick={() => onRouteChange(OSHA_RECORDKEEPING)}
                  >
                    <ListItemText
                      className="menuSubText innerSubMenuText"
                      inset
                      primary="OSHA Recordkeeping"
                    />
                  </ListItem>
                  {/* <ListItem
                    button
                    className={
                      activeClass?.active === OSHA_ITASUBMISSION
                        ? "menuItem activeClass"
                        : "menuItem"
                    }
                    onClick={() => onRouteChange(OSHA_ITASUBMISSION)}
                  >
                    <ListItemText
                      className="menuSubText innerSubMenuText"
                      inset
                      primary="OSHA ITA Submission"
                    />
                  </ListItem> */}
                  <ListItem
                    button
                    className={
                      activeClass?.active === OSHA_VIEW_REPORT_LIST
                        ? "menuItem activeClass"
                        : "menuItem"
                    }
                    onClick={() => onRouteChange(OSHA_VIEW_REPORT_LIST)}
                  >
                    <ListItemText
                      className="menuSubText innerSubMenuText"
                      inset
                      primary="Historical OSHA Records"
                    />
                  </ListItem>
                </>
              </List>
            </Collapse>
          </>
        )}

        {/*SW Item Management */}
        {showMenu?.isSW && checkPrivileges([12, 105, 106, 107]) && (
          <ListItem
            button
            className={
              activeClass?.active === SW_OPEN_ITEMS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(SW_OPEN_ITEMS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.observations} alt="Item Management" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Item Management" />
          </ListItem>
        )}

        {/* Item management */}
        {showMenu?.isSafety &&
          checkPrivileges([12, 81, 82, 83, 84, 85, 86, 117, 118, 119]) && (
            <>
              <ListItem
                button
                onClick={() => setOpenItem(!openItem)}
                className={"menuItem"}
              >
                <ListItemIcon className={"menuItemIcon"}>
                  <img src={sideNavImg?.observations} alt="Reprimand" />
                </ListItemIcon>
                <ListItemText className="menuText" primary="Item Management" />
                {openItem ? (
                  <IconExpandLess className="menuIcon" />
                ) : (
                  <IconExpandMore className="menuIcon" />
                )}
              </ListItem>

              <Collapse in={openItem} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                  {checkPrivileges([12, 117, 118, 119]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === OPEN_ITEMS_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(OPEN_ITEMS_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Open Items"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 84, 85]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === OBSERVATION
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(OBSERVATION)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="Add Observations"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 84, 85, 86]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === VIEW_OBSERVATION_LISTS
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(VIEW_OBSERVATION_LISTS)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Observations"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 81, 82]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === REPRIMAND
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(REPRIMAND)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="Add Reprimand"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 81, 82, 83]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === REPRIMAND_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(REPRIMAND_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Reprimand"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

        {/* Resource Library */}
        {checkPrivileges([73, 74, 75, 12]) && (
          <ListItem
            button
            className={
              activeClass?.active === ADMIN_RESOURCE
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(ADMIN_RESOURCE)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.resourcelibrary} alt="Resource" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Resource Library" />
          </ListItem>
        )}

        {/* Learning Management */}
        {checkPrivileges([12, 9, 10, 11, 90, 91, 92]) && (
          <ListItem
            button
            className={"menuItem"}
            onClick={() => setTraining(!training)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.learningmanagement} alt="Training" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Learning Management" />
            {training ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={training} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CREATE_LESSON
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CREATE_LESSON)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Create Lesson"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CREATE_COURSE
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CREATE_COURSE)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Create Course"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 10, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === LESSON
                    ? "menuItem activeClass"
                    : "menuItem"
                }
              >
                <ListItemText
                  inset
                  className="menuSubText"
                  primary="Training Management"
                  onClick={() => onRouteChange(LESSON)}
                />
              </ListItem>
            )}
            {checkPrivileges([12, 9, 10, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === USERCOURSES
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(USERCOURSES)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="My Training"
                />
              </ListItem>
            )}
            {showMenu?.isSafety && checkPrivileges([12, 90, 91]) && (
              <ListItem
                button
                className={
                  activeClass?.active === TOOL_BOX_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
              >
                <ListItemText
                  inset
                  className="menuSubText"
                  primary="Safety Meeting Management"
                  onClick={() => onRouteChange(TOOL_BOX_LIST)}
                />
              </ListItem>
            )}
            {showMenu?.isSafety && checkPrivileges([12, 90, 91, 92]) && (
              <ListItem
                button
                className={
                  activeClass?.active === TOOL_BOX_USER
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(TOOL_BOX_USER)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="My Safety Meetings"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/* Safetylog */}
        {showMenu?.isSafety && checkPrivileges([12, 135]) && (
          <ListItem
            button
            className={
              activeClass?.active === ADMIN_SAFETY_LOG
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(ADMIN_SAFETY_LOG)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Safety Log" />
          </ListItem>
        )}

        {/* Reports & Analytics */}
        {showMenu?.isSafety && checkPrivileges([12, 114, 115, 116]) && (
          <ListItem
            button
            className={
              activeClass?.active === REPORTS_ANALYTICS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(REPORTS_ANALYTICS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Reports & Analytics" />
          </ListItem>
        )}

        {showMenu?.isSW && checkPrivileges([12, 142]) && (
          <ListItem
            button
            className={
              activeClass?.active === SW_REPORTS_ANALYTICS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(SW_REPORTS_ANALYTICS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Reports & Analytics" />
          </ListItem>
        )}

        {/* Support */}
        <ListItem
          button
          className={
            activeClass?.active === SUPPORT
              ? "menuItem activeClass"
              : "menuItem"
          }
          onClick={() => onRouteChange(SUPPORT)}
        >
          <ListItemIcon className={"menuItemIcon"}>
            <img src={sideNavImg?.supportresources} alt="Support" />
          </ListItemIcon>
          <ListItemText className="menuText" primary="Support" />
        </ListItem>

        {/* Contactor */}
        {userInfoDetails?.isContractor && (
          <ListItem
            button
            className={"menuItem"}
            onClick={() => onRouteChange(ADMIN_CONTRACTOR_DETAILS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img
                width="30px"
                height="30px"
                src={sideNavImg?.personnelcontractor}
                alt="PersonnelContractor"
              />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Personnel Management" />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default AppMenu;
