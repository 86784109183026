import React, { useEffect } from "react";
import SequencePermits from "../component/sequencePermits";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../constant/constant";
import Loader from "../../library/common/Loader";
import { useLocation } from "react-router-dom";

const SequencePermitsContainer = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    const projectNumber = location?.state?.projectNumber;
    const projectName = location?.state?.projectName;
    const clientName = location?.state?.clientname;
    const projectId = location?.state?.id;

    const ProjectPermitData = useSelector(
        (state) => state.sequenceLandingProjectpermitList?.data
    );
    const ProjectPermitisLoading = useSelector(
        (state) => state.sequenceLandingProjectpermitList?.isLoading
    );

    const getSequenceLandingProjectPermit = (data) => {
        dispatch(sequenceLandingAction.getSequenceLandingProjectPermit(data));
    };
    // const projectId = location?.state?.id;
    useEffect(() => {
        const data = { projectId: projectId, page: 1, pageSize: PAGE_SIZE };
        getSequenceLandingProjectPermit(data);
    }, []);

    return (
        <>
            {ProjectPermitisLoading && <Loader />}
            <SequencePermits
                ProjectPermitData={ProjectPermitData}
                getSequenceLandingProjectPermit={getSequenceLandingProjectPermit}
                ProjectPermitisLoading={ProjectPermitisLoading}
                projectId={projectId}
                clientName={clientName}
                projectName={projectName}
                projectNumber={projectNumber}
            />
        </>
    );
};

export default SequencePermitsContainer;
