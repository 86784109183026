import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React, { useRef } from "react";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import TImePicker from "../../../library/custom/timePicker/TImePicker";
import Card from "../../../library/custom/card/Card";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import vehicleAccident from "../../../constant/accidentConstant";
import RadioGroup from "@mui/material/RadioGroup";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";

import { INSPECTION_HEADER_RESPONSE_TYPE } from "../../../constant/constant";
import SearchAutoComplete2 from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  getLabelByAssetType,
  getNoOptionsTextByAssetType,
} from "../../../utils/helper";

const InspectionCommonForm = (props) => {
  const preparedBy = localStorage.getItem("name");
  const autoSearch = useRef(null);

  const inspectionFieldsMapSort = props?.inspectionFieldsMap
    ?.filter((field) => field.isSelected)
    ?.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const renderField = (item) => {
    const fieldValue = props?.fieldValues[item.key] || "";
    const error = props?.validationErrors[item.key];
    switch (item.responsetype) {
      case INSPECTION_HEADER_RESPONSE_TYPE.TEXTBOX:
        return (
          <Grid md={12} xs={12} item>
            <FormLabels label={item.value} isRequired={item?.isMandatory} />
            <TextEditor
              value={fieldValue ?? ""}
              placeholderText={"Enter Here..."}
              onChange={(e) =>
                props?.handleFieldChange(item.key, e, null, item?.responsetype)
              }
            />
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.SINGLE_SELECT:
        return (
          <Grid md={6} xs={12} item>
            <FormControl fullWidth>
              <FormLabels label={item.value} isRequired={item?.isMandatory} />
              <SelectMenu
                listData={item?.optionList}
                value={fieldValue}
                onchangehandler={(e) =>
                  props?.handleFieldChange(
                    item.key,
                    e.target.value,
                    null,
                    item?.responsetype
                  )
                }
              />
            </FormControl>
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.MULTI_SELECT:
        return (
          <Grid md={6} xs={12} item>
            <FormControl fullWidth>
              <FormLabels label={item?.value} isRequired={item?.isMandatory} />
              <MultiSelectBox
                placeholder={"Please Select"}
                listData={item?.optionList}
                value={Array.isArray(fieldValue) ? [...fieldValue] : []}
                onChange={(value) =>
                  props?.handleFieldChange(
                    item.key,
                    value?.target?.value,
                    null,
                    item?.responsetype
                  )
                }
              />
            </FormControl>
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.CHECKBOX:
        return (
          <Grid md={6} xs={12} item key={item.key}>
            <FormControl component="fieldset">
              <FormLabels label={item?.value} isRequired={item?.isMandatory} />
              <FormGroup row>
                {item?.optionList?.map((option, checkboxIndex) => {
                  const checked = fieldValue.includes(option?.value);
                  return (
                    <FormControlLabel
                      key={checkboxIndex}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? [...fieldValue.split(","), option?.value].join(
                                  ","
                                )
                              : fieldValue
                                  .split(",")
                                  .filter((val) => val !== option?.value)
                                  .join(",");
                            props.handleFieldChange(
                              item.key,
                              newValue,
                              null,
                              item?.responsetype
                            );
                          }}
                          name={option?.value}
                        />
                      }
                      label={option?.value}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.RADIOBUTTON:
        return (
          <Grid md={6} xs={12} item key={item.key}>
            <FormControl fullWidth>
              <FormLabels label={item?.value} isRequired={item?.isMandatory} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                {item?.optionList?.map((option, radioIndex) => (
                  <RadioButton
                    checked={fieldValue === option?.value ? true : false}
                    key={radioIndex}
                    value={option?.value}
                    label={option?.value}
                    onChange={(e) =>
                      props?.handleFieldChange(
                        item.key,
                        e.target.value,
                        null,
                        item?.responsetype
                      )
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.INPUT_TEXTBOX:
        return (
          <Grid md={6} xs={12} item>
            <InputField
              fullWidth={true}
              type="text"
              label={item.value}
              name={item.value}
              placeholder={`Enter ${item.value}`}
              isRequired={item?.isMandatory}
              value={fieldValue}
              onChange={(e) =>
                props?.handleFieldChange(
                  item.key,
                  e.target.value,
                  null,
                  item?.responsetype
                )
              }
            />
            {error && <p className="error">{error}</p>}
          </Grid>
        );
      case INSPECTION_HEADER_RESPONSE_TYPE.PILLS_BUTTON:
        return (
          <Grid item md={12} xs={12}>
            <FormLabels
              label="Weather at Time of Inspection"
              isRequired={item?.isMandatory}
            />
            <Card
              staticCardData={true}
              cardData={vehicleAccident}
              selectedVehicleAccident={fieldValue}
              cardClickHandler={(e) =>
                props?.handleFieldChange(item.key, e, null, item?.responsetype)
              }
            />
            {error && <p className="error">{error}</p>}
          </Grid>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Grid item container mb={2} spacing={2}>
        <Grid item lg={4} md={6} xs={12}>
          <InputField
            fullWidth={true}
            disabled={true}
            type="text"
            label="Prepared By"
            value={preparedBy}
            name="preparedBy"
            placeholder="Enter name"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <>
            {props?.HBNUser ? (
              <>
                <FormLabels
                  label={getLabelByAssetType(props?.assetTypeValue)}
                  isRequired={true}
                />
                <SearchAutoComplete2
                  optionsData={
                    props?.isAssetLoading ? [] : props?.assetOption ?? []
                  }
                  isKeyValuePair={true}
                  value={props?.assets}
                  isLoading={props?.isAssetLoading}
                  noOptionsTextMsg={getNoOptionsTextByAssetType(
                    props?.assetTypeValue
                  )}
                  typeOnchange={(event) =>
                    props?.searchAsset(
                      event.target.value,
                      props?.assetTypeValue
                    )
                  }
                  onChange={(e, value) => props?.handleAssetOnChange(value)}
                  autoCompClear={autoSearch}
                  disabled={props?.editMode && props?.statusId === 5}
                />
                {props?.assetError && !props?.assets && (
                  <p className="error">Please select Asset</p>
                )}
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <FormLabels
                    label={getLabelByAssetType(props?.assetTypeValue)}
                    isRequired={true}
                  />
                  <SelectMenu
                    listData={props?.allAsset}
                    value={props?.assets?.key}
                    onchangehandler={(e, selectValue) =>
                      props?.handleSelectOnChange(e, selectValue)
                    }
                    disabled={props?.editMode && props?.statusId === 5}
                  />
                </FormControl>
                {props?.assetError && !props?.assets && (
                  <p className="error">Please select Asset</p>
                )}
              </>
            )}
          </>
        </Grid>
      </Grid>
      <Grid item container mb={2} spacing={2}>
        <Grid item lg={4} md={6} xs={12}>
          <FormControl fullWidth>
            <FormLabels label={"Date of Inspection"} isRequired={true} />
            <DatePicker
              value={props?.inspectionDate}
              name="dateOfInspection"
              onChangeHandler={(e) => props?.handleDateHandler(e)}
            />
          </FormControl>
          {props?.inspectionDateError && !props?.inspectionDate && (
            <p className="error">Please select Date of Inspection</p>
          )}
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <FormControl fullWidth>
            <FormLabels label={"Time of Inspection"} isRequired={true} />
            <TImePicker
              disabled={false}
              name="timeOfInspection"
              value={props?.inspectionTime}
              onChangeTimeHandler={(e) => props?.handleTimeHandler(e)}
            />
          </FormControl>
          {props?.inspectionTimeError && !props?.inspectionTime && (
            <p className="error">Please select Time of Inspection</p>
          )}
        </Grid>
      </Grid>
      <Grid item container md={9} xs={12} spacing={2}>
        {inspectionFieldsMapSort?.map((item) => {
          return <>{renderField(item)}</>;
        })}
      </Grid>
    </>
  );
};

export default InspectionCommonForm;