import { Grid } from "@mui/material";
import React from "react";
import DynamicTable from "../../../library/custom/dynamicTable/dynamicTable";
import "./oshaRecordkeepingPdf.scss";
const OshaRecordkeepingPdf = (props) => {
  const columns = [
    {
      label: "Employee's name",
      key: "enteredBy",
      className: "",
      hide: props?.employeeName,
    },
    {
      label: "Job title",
      key: "jobTitle",
      className: "",
    },
    {
      label: "Date of injury or onset of illness",
      key: "dateOfEntry",
      className: "",
    },
    {
      label: "Where event occurred",
      key: "caseOccured",
      className: "",
    },
    {
      label: "Injury description",
      key: "injuryDescription",
      className: "",
    },
    {
      label: "Classification",
      key: "classification",
      className: "",
    },
    {
      label: "Number of days",
      key: "classifyHowManyDays",
      className: "",
    },
    {
      label: "Illness Type",
      key: "illnessType",
      className: "",
    },
  ];

  return (
    <div className="oshaRecordkeepingPdff">
      <Grid container mb={2} className="oshaRecordkeepingPdff__header">
        <Grid item xs={12} md={6} sm={6}>
          <div className="oshaRecordkeepingPdff__Coloumn">
            <strong>Establishment name:</strong>{" "}
            {props?.headerInfo?.establishmentName}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <div className="oshaRecordkeepingPdff__Coloumn">
            <strong>Year:</strong> {props?.year}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <div className="oshaRecordkeepingPdff__Coloumn">
            <strong>City:</strong> {props?.headerInfo?.city}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <div className="oshaRecordkeepingPdff__Coloumn">
            <strong>State:</strong> {props?.headerInfo?.state}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <DynamicTable title="" columns={columns} data={props?.oshaLog} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OshaRecordkeepingPdf;
