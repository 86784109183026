export const vehiclesType = {
  VEHICLE_PAGE: "vehicles",
  GET_VEHICLE_LIST: "[GET_VEHICLE_LIST] Get vehicle List Data",
  GET_VEHICLE_LIST_SUCCESS:
    "[GET_VEHICLE_LIST_SUCCESS] Get vehicle List Data Successfull",
  GET_VEHICLE_LIST_FAILURE:
    "[GET_VEHICLE_LIST_FAILURE] Get vehicle List Data Failed",
};

export const postAddVehicleType = {
  POST_ADD_VEHICLE_PENDING: "[Add VEHICLE][REQUEST] POST_ADD_VEHICLE",
  POST_ADD_VEHICLE_FAILURE: "[Add VEHICLE][RESPONSE] POST_ADD_VEHICLE Failed",
  POST_ADD_VEHICLE_SUCCESS:
    "[Add VEHICLE][RESPONSE] POST_ADD_VEHICLE Successfull",
};

export const deleteVehicleType = {
  DELETE_VEHICLE_PENDING: "DELETE_VEHICLE",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
  DELETE_VEHICLE_FAILED: "DELETE_VEHICLE_FAILED",
  Vehicle_Page: "Vehicle Page",
};

export const getVehicleDetailsType = {
  VEHICLE_DETAILS_PAGE: "Vehicle Details",
  GET_VEHICLE_DETAILS: "[GET_VEHICLE_DETAILST] Get vehicle DETAILS Data",
  GET_VEHICLE_DETAILS_SUCCESS:
    "[GET_VEHICLE_DETAILS_SUCCESS] Get vehicl DETAILS Data Successfull",
  GET_VEHICLE_DETAILS_FAILURE:
    "[GET_VEHICLE_DETAILS_FAILURE] Get vehicle DETAILS Data Failed",
};

export const updateVehicleType = {
  UPDATE_VEHICLE_PENDING: "UPDATE_VEHICLE_PENDING",
  UPDATE_VEHICLE_SUCCESS: "UPDATE_VEHICLE_SUCCESS",
  UPDATE_VEHICLE_FAILED: "UPDATE_VEHICLE_FAILED",
};

export const clearVehicleType = {
  CLEAR_VEHICLE: "[CLEAR_VEHICLE][REQUEST] Clear Vehicle",
};
