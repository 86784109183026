import React, { useRef } from "react";
import { Grid, RadioGroup, FormControl } from "@mui/material";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import UsersChip from "../../../library/custom/usersChip/UsersChip";
import "./acceptable.scss";
import FormDropZone from "../../../library/dropZone/FormDropZone";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { InspectionInvolvedPartyOptionData } from "../../../constant/inspectionConstant";
import { USER_TYPE } from "../../../constant/constant";

const Acceptable = (props) => {
  const autoCompInspector = useRef(null);
  return (
    <div className="overideNotAc">
      <div className="notAcceptableBox">
        <Grid item md={12} xs={12} textAlign="left" mt={2}>
          {props?.acceptablelength > 1 && (
            <div
              onClick={(e) =>
                props?.handleFormChange(
                  props?.acc,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  "deleteKudos"
                )
              }
              className="removeRepetBox"
            >
              X Remove Kudos
            </div>
          )}

          <FormLabels label="Add Comment" />
          <TextEditor
            value={props?.acc?.comment ?? ""}
            placeholderText={"Add Comment Here..."}
            onChange={(e) =>
              props?.handleFormChange(
                e,
                props?.question,
                props?.subcategory,
                props?.category,
                "comment",
                props?.acc
              )
            }
          />
          {props?.labelValue?.length === 500 && (
            <p
              className={
                props?.labelValue?.length === 500 ? "error" : "errornot"
              }
            >
              Character limit exceeded.
            </p>
          )}

          <div className="customMultiLable">
            <FormDropZone
              isMultipleIcon={true}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop the files here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileDataId) =>
                props?.setImage(
                  photo,
                  fileDataId,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  props?.acc,
                  "acceptable"
                )
              }
              fileType={10}
              imageUrl={props?.acc?.files}
              deleteImageURL={(url, index) =>
                props?.deleteImageURLS(
                  url,
                  index,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  props?.acc,
                  "acceptable"
                )
              }
              id="uploadMedia"
              key="uploadMedia"
              isMuliple={true}
              isDoc={false}
              isImage={true}
              allFiles={false}
            />
          </div>
        </Grid>

        {props?.question?.involvedPartyShow && (
          <div className="container inVoledParty">
            <FormLabels label="Involved Party Type" labelPlacement="start" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {InspectionInvolvedPartyOptionData &&
                InspectionInvolvedPartyOptionData.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={props?.acc?.involdevPaty === item.value}
                    value={item.value}
                    label={item.label}
                    onChange={(e) =>
                      props?.handleFormChange(
                        e?.target?.value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "involvedpartytype",
                        props?.acc
                      )
                    }
                  />
                ))}
            </RadioGroup>
          </div>
        )}

        {props?.acc?.involdevPaty === "Employee" && (
          <>
            <Grid item container spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  {!props?.assets?.key && (
                    <p className="error">First select Asset</p>
                  )}
                  <FormLabels label="Add Involved Employee" />
                  <SearchAutoComplete
                    optionsData={
                      props?.isAutoSearchUserLoading
                        ? []
                        : props?.allUsers?.users ?? []
                    }
                    value={props?.acc?.employeeCurrent ?? ""}
                    onChange={(e, value) =>
                      props?.handleFormChange(
                        value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "involvedpartyemployee",
                        props?.acc
                      )
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      props?.handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        0,
                        0,
                        props?.assets?.key,
                        0,
                        0,
                        0,
                        true
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={props?.isAutoSearchUserLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <UsersChip
                  userList={props?.acc?.employee}
                  handleDelete={(e, value) =>
                    props?.handleFormChange(
                      e,
                      props?.question,
                      props?.subcategory,
                      props?.category,
                      "deleteEmployee",
                      props?.acc
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        )}

        {props?.acc?.involdevPaty === "Sub-Contractor" && (
          <>
            <Grid item container spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  {!props?.assets?.key && (
                    <p className="error">First select Asset</p>
                  )}

                  <FormLabels label="Add Involved Company" />
                  <SearchAutoComplete
                    optionsData={
                      props?.isAutoSearchUserLoading
                        ? []
                        : props?.allUsers?.users ?? []
                    }
                    value={props?.acc?.subcontractorCurrent ?? ""}
                    onChange={(e, value) =>
                      props?.handleFormChange(
                        value,
                        props?.question,
                        props?.subcategory,
                        props?.category,
                        "addinvolvedcompany",
                        props?.acc
                      )
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      props?.handleAllUserSearch(
                        event,
                        USER_TYPE.CONTRACTOR,
                        0,
                        0,
                        props?.assets?.key,
                        0,
                        0,
                        0,
                        true
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={props?.isAutoSearchUserLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <UsersChip
                  userList={props?.acc?.subcontractor}
                  handleDelete={(e, value) =>
                    props?.handleFormChange(
                      e,
                      props?.question,
                      props?.subcategory,
                      props?.category,
                      "deleteSubcontractor",
                      props?.acc
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default Acceptable;
