import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import Loader from "../../../library/common/Loader";
import { useReactToPrint } from "react-to-print";
import "./viewInspectionReport.scss";
import ReportHeader from "./common/reportHeader/reportHeader";
import BasicInformation from "./common/basicInformation/basicInformation";
import "./common/common.scss";
import * as viewInspectionAction from "../../../redux/actions/incpectionAction";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";
import CategoriesReportView from "./common/categoriesReportView/categoriesReportView/categoriesReportView";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import * as incpectionAction from "../../../redux/actions/incpectionAction";

const ViewInspectionReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = window.location.search.slice(
    4,
    window.location.search?.length
  );
  
  const location = useLocation();
  const reportId = location?.state?.reportId ?? queryString;
  const [pdfLoader, setPdfLoader] = useState(false);

  const getInspectionDetails = useSelector(
    (state) => state?.inspectionDetailData?.inspEditDetails
  );

  const showLoader = useSelector(
    (state) =>
      state?.inspectionDetailData?.isInLoading ||
      state?.getInspectionAcknowledgement?.isInLoading
  );

  const getInspectionReport = (data) => {
    dispatch(viewInspectionAction.getInspectionDetails({ data }));
  };

  const defaultReportData = {
    InspectionId: reportId,
    organisationId: ORG_ID(),
  };

  useEffect(() => {
    getInspectionReport(defaultReportData);
  }, [reportId]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "View Inspection Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => setPdfLoader(true),
    onAfterPrint: () => setPdfLoader(false),
    onPrintError: () => setPdfLoader(false),
  });

  const headerData = {
    report_Id: getInspectionDetails?.report_Id,
    conducted_On: getInspectionDetails?.conducted_On,
  };
  const basicInformationData = {
    inspected_By: getInspectionDetails?.inspected_By,
    dateOfIns: getInspectionDetails?.dateOfIns,
    timeOfIns: getInspectionDetails?.timeOfIns,
    location: getInspectionDetails?.location,
    projectName: getInspectionDetails?.projectName,
    assetType: getInspectionDetails?.assetType,
    asset: getInspectionDetails?.asset,
    weatherTimeofIns: getInspectionDetails?.weatherTimeofIns,
    generalComment: getInspectionDetails?.generalComment,
  };
  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      {checkPrivileges([12, 120, 121, 122, 4, 6, 5]) ? (
        <>
          {pdfLoader && <Loader />}

          {showLoader ? (
            <Loader />
          ) : (
            <Grid
              ref={componentRef}
              className="InspectionContainer overRideSaftyPdf"
            >
              <Grid container>
                <Grid item md={12}>
                  <div className="title repotPdfHeading">
                    View Generated Report
                  </div>
                </Grid>
                <Grid item md={12} className="clientPdfDetail">
                  <Grid className="clientName">
                    {isClient ? clientName : userInfo?.clientName}
                  </Grid>
                  <Grid className="clientLogo">
                    {logoCheck && <img src={logoCheck} alt="client Log" />}
                  </Grid>
                </Grid>
              </Grid>

              <ReportHeader handlePrint={handlePrint} headerData={headerData} />
              <BasicInformation
                basicInformationData={basicInformationData}
                headerFields={getInspectionDetails?.headerFieldsInfoResponse?.filter(
                  (field) => field?.isSelected
                )}
              />
              <CategoriesReportView
                viewDetail={getInspectionDetails}
                SIGNATUREURL={getInspectionDetails?.signature}
                getInspectionDetails={getInspectionDetails}
              />
            </Grid>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ViewInspectionReport;