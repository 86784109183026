import React, { useEffect, useState } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import "./inspectionAcknowledgement.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Buttons from "../../../../library/custom/button/Button";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import { InputField } from "../../../../library/custom/textBox/InputField";
import DialogBox from "../../../../library/common/DialogBox";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";

const InspectionAcknowledgment = (props) => {
  const dataBackFooter = {
    title: "Back to Inspection Configuration",
    route: INSPECTION_ADMIN,
  };
  const [isValueError, setIsValueError] = useState(false);
  const [isValueAlreadyAddError, setIsValueAlreadyAddError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const initialState = {
    value: "",
    description: "",
    isDefault: false,
  };
  const [formValue, setFormValue] = useState(initialState);
  const [item, setItem] = useState();

  const handleChange = (item, action) => {
    if (action === "status") {
      if (item?.status) {
        setItem(item);
        setOpenModal(true);
      } else {
        setOpenModal(false);
        props?.setCheckStatus(2);
        const data = {
          userId: parseInt(USER_ID()),
          updateDetails: [
            {
              id: item?.key,
              value: item?.value,
              description: item?.description,
              status: !item?.status,
              isDefault: item?.isDefault,
            },
          ],
        };
        props?.onSubmit(data);
      }
    }
  };

  const deletehandle = () => {
    props?.setCheckStatus(2);
    setOpenModal(false);
    const data = {
      userId: parseInt(USER_ID()),
      updateDetails: [
        {
          id: item?.key,
          value: item?.value,
          description: item?.description,
          status: !item?.status,
          isDefault: item?.isDefault,
        },
      ],
    };
    props?.onSubmit(data);
  };

  const handleDefaultChange = () => {
    setFormValue({
      ...formValue,
      isDefault: !formValue.isDefault,
    });
  };

  function mappedData() {
    let errors = false;
    if (formValue?.value?.trim() === "") {
      setIsValueError(true);
      errors = true;
    }
    if (formValue?.description?.trim() === "") {
      setIsDescriptionError(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const data = {
      userId: parseInt(USER_ID()),
      updateDetails: [
        {
          value: formValue.value,
          description: formValue.description,
          status: true,
          isDefault: formValue.isDefault,
        },
      ],
    };
    return data;
  }
  const submitData = () => {
    const data = mappedData();
    if (!data) {
      return;
    }
    const exists = props.getInspectionAcknowledgement?.data?.result?.find(
      (ack) =>
        ack.value.trim().toLowerCase() === formValue.value.trim().toLowerCase()
    );
    if (exists) {
      setIsValueAlreadyAddError(true);
      return;
    }
    setIsValueError(false);
    setIsValueAlreadyAddError(false);
    setIsDescriptionError(false);
    props?.setCheckStatus(1);
    props?.onSubmit(data);
  };

  useEffect(() => {
    if (props?.updateInspectionAcknowledgement?.status === "SUCCESS") {
      setFormValue({
        value: "",
        description: "",
        isDefault: false,
      });
    }
  }, [props?.updateInspectionAcknowledgement]);

  return (
    <>
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          header={"Confirm InActive"}
          deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          content={
            "This will remove the acknowledgement from all forms it is on. Would you like to proceed?"
          }
          view
          deleteIcon={false}
        />
      )}

      <Grid className="inspectionOverRide inspectionAcknowledgement">
        <h2 className="inspectionAcknowledgementHeading">
          Set Inspection Acknowledgement
        </h2>

        <Grid container>
          <Grid item container>
            <Grid item lg={8} md={8} xs={12} mb={2}>
              <FormControl fullWidth>
                <FormLabels label={"Title"} isRequired={true} />

                <InputField
                  fullWidth={true}
                  disabled={false}
                  type="text"
                  placeholder={"Add Title Here..."}
                  value={formValue?.value}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      value: e.target.value,
                    })
                  }
                  isError={isValueError}
                  errorMsg={isValueError ? "Please add Title" : ""}
                />
              </FormControl>
              {isValueAlreadyAddError && (
                <p className="error">Acknowledgment already added.</p>
              )}
            </Grid>
            <Grid item lg={8} md={8} xs={12} mt={1}>
              <FormControl fullWidth>
                <FormLabels
                  label="Set Acknowledgment Message"
                  isRequired={true}
                />
                <TextArea
                  placeholder={"Enter Here"}
                  value={formValue?.description}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      description: e.target.value,
                    })
                  }
                  isError={isDescriptionError}
                  errorMsg={"Please add Acknowledgment Message"}
                />
              </FormControl>
            </Grid>
          </Grid>
          {checkPrivileges([12]) && parseInt(ORG_ID()) === HBNEXT_ORG_ID && (
            <Grid lg={6} xs={12} sm={6} md={6} mt={2} mb={3}>
              <FormControl
                component="fieldset"
                variant="standard"
                className="contactswitch w-100"
              >
                <FormGroup className="w-100">
                  <Checkbox
                    checked={formValue?.isDefault}
                    onChange={handleDefaultChange}
                    label={"Default Statement"}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
          <Grid container mt={2}>
            <Grid item lg={4} xs={12} sm={5} md={5}>
              <Buttons
                varientContained={true}
                label={"Save"}
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={3} className="acknowledgmentBox">
          <Grid item md={12} xs={12} mb={2}>
            <div className="title">Acknowledgment List</div>
          </Grid>

          <Grid item md={12} xs={12} className="acknowledgmentListBox">
            {props?.getInspectionAcknowledgement?.data?.result?.map((row) => {
              return (
                <Grid
                  container
                  key={row?.key}
                  className="acknowledgmentList"
                  mb={2}
                >
                  <Grid item md={12} xs={12}>
                    <Grid container md={12} xs={12}>
                      <Grid
                        item
                        md={8}
                        xs={12}
                        className="acknowledgmentListTitle"
                      >
                        {row?.value}
                        <p>{row?.description}</p>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <div className="acknowledgmentActions">
                          {!row?.isDefault && (
                            <div className="acknowledgmentAction">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <ISwitch
                                      checked={row?.status}
                                      onChange={() =>
                                        handleChange(row, "status")
                                      }
                                      name="status"
                                    />
                                  }
                                  label={row?.status ? "Active" : "InActive"}
                                />
                              </FormGroup>
                            </div>
                          )}
                          <div className="acknowledgmentAction">
                            <ReportRowManipulte
                              rows={row}
                              edit={false}
                              viewLabel="View Mapping"
                              view
                              viewOnClick={() => props?.viewMapping(row)}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <p>{item?.description}</p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid container mt={13}>
          <Grid md={8} xs={12}>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBackFooter} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InspectionAcknowledgment;
