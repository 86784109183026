export const incpectionType = {
  // get question set
  GET_QUESTION_SET_PENDING: "GET_QUESTION_SET_PENDING",
  GET_QUESTION_SET_SUCCESS: "GET_QUESTION_SET_SUCCESS",
  GET_QUESTION_SET_FAILED: "GET_QUESTION_SET_FAILED",

  // get question set
  GET_WEATHER_REF_PENDING: "GET_WEATHER_REF_PENDING",
  GET_WEATHER_REF_SUCCESS: "GET_WEATHER_REF_SUCCESS",
  GET_WEATHER_REF_FAILED: "GET_WEATHER_REF_FAILED",

  // Get Questions With Dynamic Fields
  REVOKE_DATA_UNMOUNT: "REVOKE_DATA_UNMOUNT",
  GET_QUEST_DYNAMIC_FIELD_PENDING: "GET_QUEST_DYNAMIC_FIELD_PENDING",
  GET_QUEST_DYNAMIC_FIELD_SUCCESS: "GET_QUEST_DYNAMIC_FIELD_SUCCESS",
  GET_QUEST_DYNAMIC_FIELD_FAILED: "GET_QUEST_DYNAMIC_FIELD_FAILED",

  // Get Questions With Dynamic Fields
  POST_INSPECTION_PENDING: "POST_INSPECTION_PENDING",
  POST_INSPECTION_SUCCESS: "POST_INSPECTION_SUCCESS",
  POST_INSPECTION_FAILED: "POST_INSPECTION_FAILED",

  UPDATE_INSPECTION_PENDING: "UPDATE_INSPECTION_PENDING",
  UPDATE_INSPECTION_SUCCESS: "UPDATE_INSPECTION_SUCCESS",
  UPDATE_INSPECTION_FAILED: "UPDATE_INSPECTION_FAILED",

  // Inspection report view
  GET_INSPECTION_VIEW_LIST_PENDING: "GET_INSPECTION_VIEW_LIST_PENDING",
  GET_INSPECTION_VIEW_LIST_SUCCESS: "GET_INSPECTION_VIEW_LIST_SUCCESS",
  GET_INSPECTION_VIEW_LIST_FAILED: "GET_INSPECTION_VIEW_LIST_FAILED",

  // inspection item delete
  DELETE_PAGE: "DELETE_PAGE",
  DEELETE_INSPECTION_PENDING: "DEELETE_INSPECTION_PENDING",
  DEELETE_INSPECTION_SUCCESS: "DEELETE_INSPECTION_SUCCESS",
  DEELETE_INSPECTION_FAILED: "DEELETE_INSPECTION_FAILED",

  // inspection details
  GET_INSPECTION_DETAILS_PENDING: "GET_INSPECTION_DETAILS_PENDING",
  GET_INSPECTION_DETAILS_SUCCESS: "GET_INSPECTION_DETAILS_SUCCESS",
  GET_INSPECTION_DETAILS_FAILED: "GET_INSPECTION_DETAILS_FAILED",

  // Inspection Configuration
  SUBCATEGORY_PAGE: " Subcategory",
  POST_SUBCATEGORY_PENDING:
    "[POST_SUBCATEGORY_PENDING] Create  Subcategory  Data",
  POST_SUBCATEGORY_SUCCESS:
    "[POST_CATEGORYSUBCATEGORY_SUCCESS] Create  Subcategory Data Successfull",
  POST_SUBCATEGORY_FAILURE:
    "[POST_SUBCATEGORY_FAILURE] Create Subcategory  Data Failed",
  CLEAR_SUBCATEGORY: "[CLEAR_SUBCATEGORY][REQUEST] Clear Subcategory",

  CATEGORY_PAGE: "Category Page",
  POST_CATEGORY_PENDING: "[POST_CATEGORY_PENDING] Create Category  Data",
  POST_CATEGORY_SUCCESS:
    "[POST_CATEGORY_SUCCESS] Create Category Data Successfull",
  POST_CATEGORY_FAILURE: "[POST_CATEGORY_FAILURE] Create Category Data Failed",
  CLEAR_CATEGORY: "[CLEAR_CATEGORY][REQUEST] Clear Category ",

  ADDUPDATEQUESTIONS_PAGE: "Questions",
  POST_ADDUPDATEQUESTIONS_PENDING:
    "[POST_ADDUPDATEQUESTIONS_PENDING] Create Update Questions  Data",
  POST_ADDUPDATEQUESTIONS_SUCCESS:
    "[POST_ADDUPDATEQUESTIONS_SUCCESS] Create Update Questions Data Successfull",
  POST_ADDUPDATEQUESTIONS_FAILURE:
    "[POST_ADDUPDATEQUESTIONS_FAILURE] Create Update Questions  Data Failed",
  CLEAR_QUESTIONS: "[CLEAR_ADDUPDATEQUESTIONS][REQUEST] Clear Update Questions",

  ADMINUPDATEINSPECTION_PAGE: "Admin Update Inspection",
  POST_ADMINUPDATEINSPECTION_PENDING:
    "[POST_ADMINUPDATEINSPECTION_PENDING] Create Admin Update Inspection  Data",
  POST_ADMINUPDATEINSPECTION_SUCCESS:
    "[POST_ADMINUPDATEINSPECTION_SUCCESS] Create Admin Update Inspection Data Successfull",
  POST_ADMINUPDATEINSPECTION_FAILURE:
    "[POST_ADMINUPDATEINSPECTION_FAILURE] Create Admin Update Inspection Data Failed",
  CLEAR_ADMINUPDATEINSPECTION:
    "[CLEAR_ADMINUPDATEINSPECTION][REQUEST] Clear Admin Update Inspection",

  ADMINADDUPDATEINSPECTION: "Admin Add Update Inspection",
  POST_ADMINADDUPDATEINSPECTION_PENDING:
    "[POST_ADMINADDUPDATEINSPECTION_PENDING] Create Admin Add Update Inspection  Data",
  POST_ADMINADDUPDATEINSPECTION_SUCCESS:
    "[POST_ADMINADDUPDATEINSPECTION_SUCCESS] Create Admin Add Update Inspection Data Successfull",
  POST_ADMINADDUPDATEINSPECTION_FAILURE:
    "[POST_ADMINADDUPDATEINSPECTION_FAILURE] Create Admin Add Update Inspection Data Failed",
  CLEAR_ADMINADDUPDATEINSPECTION:
    "[CLEAR_ADMINADDUPDATEINSPECTION][REQUEST] Clear Admin Add Update Inspection",

  DELETE_QUESTIONS: "DELETE_QUESTIONS",
  DELETE_QUESTIONS_SUCCESS: "DELETE_QUESTIONS_SUCCESS",
  DELETE_QUESTIONS_FAILED: "DELETE_QUESTIONS_FAILED",

  CATEGORIES_PAGE: "CATEGORIES Page",
  GET_CATEGORIES_LIST: "[GET_CATEGORIES_LIST] Get CATEGORIES List Data",
  GET_CATEGORIES_LIST_SUCCESS:
    "[GET_CATEGORIES_LIST_SUCCESS] Get CATEGORIES List Data Successfull",
  GET_CATEGORIES_LIST_FAILURE:
    "[GET_CATEGORIES_LIST_FAILURE] Get CATEGORIES List Data Failed",

  SUB_CATEGORIES_PAGE: "SUB CATEGORIES Page",
  GET_SUB_CATEGORIES_LIST:
    "[GET_SUB_CATEGORIES_LIST] Get SUB CATEGORIES List Data",
  GET_SUB_CATEGORIES_LIST_SUCCESS:
    "[GET_SUB_CATEGORIES_LIST_SUCCESS] Get SUB CATEGORIES List Data Successfull",
  GET_SUB_CATEGORIES_LIST_FAILURE:
    "[GET_SUB_CATEGORIES_LIST_FAILURE] Get SUB CATEGORIES List Data Failed",

  QUESTIONS_PAGE: "QUESTIONS Page",
  GET_QUESTIONS_LIST: "[GET_QUESTIONS_LIST] Get QUESTIONS List Data",
  GET_QUESTIONS_LIST_SUCCESS:
    "[GET_QUESTIONS_LIST_SUCCESS] Get QUESTIONS List Data Successfull",
  GET_QUESTIONS_LIST_FAILURE:
    "[GET_QUESTIONS_LIST_FAILURE] Get QUESTIONS List Data Failed",

  GET_QUESTIONS_SET_LIST_PAGE: "GET_QUESTIONS_SET_LIST Page",
  GET_QUESTIONS_SET_LIST: "[GET_QUESTIONS_SET_LIST] Get Question Set List Data",
  GET_QUESTIONS_SET_LIST_SUCCESS:
    "[GET_QUESTIONS_SET_LIST_SUCCESS] Get Question Set List Data Successfull",
  GET_QUESTIONS_SET_LIST_FAILURE:
    "[GET_QUESTIONS_SET_LIST_FAILURE] Get Question Set List Data Failed",

  GET_ASSET_LIST_PAGE: "GET_ASSET_LIST Page",
  GET_ASSET_LIST: "[GET_ASSET_LIST] Get Asset List Data",
  GET_ASSET_LIST_SUCCESS:
    "[GET_ASSET_LIST_SUCCESS] Get Asset List Data Successfull",
  GET_ASSET_LIST_FAILURE: "[GET_ASSET_LIST_FAILURE] Get Asset List Data Failed",

  CLONE_QUESTION_SET_PAGE: "CLONE_QUESTION_SET Page",
  CLONE_QUESTION_SET: "[CLONE_QUESTION_SET] Clone Question Set Data",
  CLONE_QUESTION_SET_SUCCESS:
    "[GET_QUESTIONS_SET_LIST_SUCCESS] Clone Question Set Successfull",
  CLONE_QUESTION_SET_FAILURE:
    "[GET_QUESTIONS_SET_LIST_FAILURE] Clone Question Set Data Failed",
  CLEAR_CLONE_QUESTION_SET:
    "[CLEAR_CLONE_QUESTION_SET][REQUEST] Clear Clone Question Set",

  QEST_SET_Detail_PAGE: "QEST_SET_Detail Page",
  QEST_SET_DETAIL: "[QEST_SET_DETAIL] QEST SET Detail Data",
  QEST_SET_DETAIL_SUCCESS:
    "[QEST_SET_DETAIL_SUCCESS] QEST SET Detail Successfull",
  QEST_SET_DETAIL_FAILURE:
    "[QEST_SET_DETAIL_FAILURE] QEST SET Detail Data Failed",

  GET_INSPECTION_OPENITEM_DETAIL_PAGE: "GET_OPENITEM_DETAIL Page",
  GET_INSPECTION_OPENITEM_DETAIL:
    "[GET_INSPECTION_OPENITEM_DETAIL] Get Inspection OpemItem Detail",
  GET_INSPECTION_OPENITEM_DETAIL_SUCCESS:
    "[GET_INSPECTION_OPENITEM_DETAIL_SUCCESS] Get Inspection OpemItem Detail Successfull",
  GET_INSPECTION_OPENITEM_DETAIL_FAILURE:
    "[GET_INSPECTION_OPENITEM_DETAIL_FAILURE] Get Inspection OpemItem Detail Failed",

  UPDATE_INSPECTION_OPENITEM: "UPDATE_INSPECTION_OPENITEM_PENDING",
  UPDATE_INSPECTION_OPENITEM_SUCCESS: "UPDATE_INSPECTION_OPENITEM_SUCCESS",
  UPDATE_INSPECTION_OPENITEM_FAILED: "UPDATE_INSPECTION_OPENITEM_FAILED",

  GET_INSPECTION_VIOLATION_COUNTS_PAGE: "GET_INSPECTION_VIOLATION_COUNTS Page",
  GET_INSPECTION_VIOLATION_COUNTS:
    "[GET_INSPECTION_VIOLATION_COUNTS] GET_INSPECTION_VIOLATION_COUNTS Data",
  GET_INSPECTION_VIOLATION_COUNTS_SUCCESS:
    "[GET_INSPECTION_VIOLATION_COUNTS_SUCCESS] GET_INSPECTION_VIOLATION_COUNTS Data Successfull",
  GET_INSPECTION_VIOLATION_COUNTS_FAILURE:
    "[GET_INSPECTION_VIOLATION_COUNTS_FAILURE] GET_INSPECTION_VIOLATION_COUNTS Data Failed",

  GET_ITEM_LIST: "[ GET_ITEM_LIST] get item list",
  GET_ITEM_LIST_SUCCESS: "[GET_ITEM_LIST_SUCCESS] get item list Successfull",
  GET_ITEM_LIST_FAILURE: "[GET_ITEM_LIST_FAILURE]get item list Failed",

  POST_REPRIMAND_TO_CLOSE: "[ POST_REPRIMAND_TO_CLOSE] post reprimand to close",
  POST_REPRIMAND_TO_CLOSE_SUCCESS:
    "[POST_REPRIMAND_TO_CLOSE_SUCCESS] post reprimand to Close Successfull",
  POST_REPRIMAND_TO_CLOSE_FAILURE:
    "[POST_REPRIMAND_TO_CLOSE_FAILURE]post reprimand to Close Failed",

  INSPECTION_ACKNOWLEDGEMENT: "Inspection Acknowledgement page",
  GET_INSPECTION_ACKNOWLEDGEMENT:
    "[GET_INSPECTION_ACKNOWLEDGEMENT] Get Inspection Acknowledgement Data",
  GET_INSPECTION_ACKNOWLEDGEMENT_SUCCESS:
    "[GET_INSPECTION_ACKNOWLEDGEMENT_SUCCESS] Get Inspection Acknowledgement Data Successfull",
  GET_INSPECTION_ACKNOWLEDGEMENT_FAILURE:
    "[GET_INSPECTION_ACKNOWLEDGEMENT_FAILURE] Get Inspection Acknowledgement Data Failed",

  POST_INSPECTION_ACKNOWLEDGEMENT_PENDING:
    "[POST_INSPECTION_ACKNOWLEDGEMENT_PENDING] Post Inspection Acknowledgement Data",
  POST_INSPECTION_ACKNOWLEDGEMENT_SUCCESS:
    "[POST_INSPECTION_ACKNOWLEDGEMENT_SUCCESS] Post Inspection Acknowledgement Data Successfull",
  POST_INSPECTION_ACKNOWLEDGEMENT_FAILURE:
    "[POST_INSPECTION_ACKNOWLEDGEMENT_FAILURE] Post Inspection Acknowledgement Data Failed",
  CLEAR_INSPECTION_ACKNOWLEDGEMENT:
    "[CLEAR_INSPECTION_ACKNOWLEDGEMENT][REQUEST] Clear Inspection Acknowledgement Set",

    GET_EXPORT_ITEM_LIST: "[GET_EXPORT_ITEM_LIST] Get Export Item list",
    GET_EXPORT_ITEM_LIST_SUCCESS: "[GET_EXPORT_ITEM_LIST_SUCCESS] Get Export Item list Successfull",
    GET_EXPORT_ITEM_LIST_FAILURE: "[GET_EXPORT_ITEM_LIST_FAILURE] Get Export Item list Failed",
    GET_INSPECTION_REPORT_ASSET_DETAILS:
    "[GET_INSPECTION_REPORT_ASSET_DETAILS] Get Inspecion Report Asset Details",
  GET_INSPECTION_REPORT_ASSET_DETAILS_SUCCESS:
    "[GET_INSPECTION_REPORT_ASSET_DETAILS_SUCCESS] Get Inspecion Report Asset Details Successfull",
  GET_INSPECTION_REPORT_ASSET_DETAILS_FAILURE:
    "[GET_INSPECTION_REPORT_ASSET_DETAILS_FAILURE] Get Inspecion Report Asset Details Failed",

  GET_INSPECTION_REPORT: "[GET_INSPECTION_REPORT] Get Inspecion Report",
  GET_INSPECTION_REPORT_SUCCESS:
    "[GET_INSPECTION_REPORT_SUCCESS] Get Inspecion Report Successfull",
  GET_INSPECTION_REPORT_FAILURE:
    "[GET_INSPECTION_REPORT_FAILURE] Get Inspecion Report Failed",

  GET_OPEN_ITEM_PHOTOS: "[GET_OPEN_ITEM_PHOTOS] Get Open Item Photo",
  GET_OPEN_ITEM_PHOTOS_SUCCESS:
    "[GET_OPEN_ITEM_PHOTOS_SUCCESS]  Get Open Item Photo Successfull",
  GET_OPEN_ITEM_PHOTOS_FAILURE:
    "[GET_OPEN_ITEM_PHOTOS_FAILURE]  Get Open Item Photo Failed",

  GET_INSPECTION_OPEN_ITEM_COUNT_PENDING:
    "[GET_INSPECTION_OPEN_ITEM_COUNT_PENDING] Get Inspection Open Item Count Data",
  GET_INSPECTION_OPEN_ITEM_COUNT_SUCCESS:
    "[GET_INSPECTION_OPEN_ITEM_COUNT_SUCCESS] Get Inspection Open Item Count Data Successfull",
  GET_INSPECTION_OPEN_ITEM_COUNT_FAILURE:
    "[GET_INSPECTION_OPEN_ITEM_COUNT_FAILURE] Get Inspection Open Item Count Data Failed",
    GET_INSPECTION_FIELDS_BY_QUESTIONSET:
    "[GET_INSPECTION_FIELDS_BY_QUESTIONSET] Get Inspecion Fields By QuestionSet",
  GET_INSPECTION_FIELDS_BY_QUESTIONSET_SUCCESS:
    "[GET_INSPECTION_FIELDS_BY_QUESTIONSET_SUCCESS] Get Inspecion Fields By QuestionSet Successfull",
  GET_INSPECTION_FIELDS_BY_QUESTIONSET_FAILURE:
    "[GET_INSPECTION_FIELDS_BY_QUESTIONSET_FAILURE] Get Inspecion Fields By QuestionSet list Failed",
  POST_INSPECTION_FIELDS_MAP_PENDING:
    "[POST_INSPECTION_FIELDS_MAP_PENDING] Post Update Inspection Fields Map Data",
  POST_INSPECTION_FIELDS_MAP_SUCCESS:
    "[POST_INSPECTION_FIELDS_MAP_SUCCESS] Post Update Inspection Fields Map Data Successfull",
  POST_INSPECTION_FIELDS_MAP_FAILURE:
    "[POST_INSPECTION_FIELDS_MAP_FAILURE] Post Update Inspection Fields Map Data Failed",
  CLEAR_INSPECTION_FIELDS_MAP:
    "[CLEAR_INSPECTION_FIELDS_MAP][REQUEST] Clear Update Inspection Fields Map",
  };
