import React from "react";
import "./qrBackBtn.scss";
import commonImages from "../../../assets";
import { useNavigate } from "react-router-dom";

const QrBackBtn = ({ text = "Back" }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="back-button" onClick={handleBackClick}>
      <img
        src={commonImages?.backBtn}
        alt="Back Arrow"
        className="arrow-icon"
      />
      <span className="back-text">{text}</span>
    </div>
  );
};

export default QrBackBtn;
