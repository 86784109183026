import React, { useEffect, useState } from "react";
import EstablishmentList from "../component/establishmentList";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ESTABLISHMENT_ADD,
} from "../../../constant/routeContant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { useDispatch } from "react-redux";
import Loader from "../../../library/common/Loader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { PAGE_SIZE } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const EstablishmentListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statusShow, setStatusShow] = useState(false);

  const addEstablishment = () => {
    navigate(ESTABLISHMENT_ADD);
  };
  const editEstablishment = (id) => {
    navigate(ESTABLISHMENT_ADD, {
      state: {
        establishmentId: id,
        editMode: true,
        establishmentData: { flag: 13 },
      },
    });
  };
  const defaultData = {
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
  };

  const getOSHAEstablishmentListData = (data) => {
    dispatch(incidentAction.getOSHAEstablishmentList(data));
  };

  const OSHAEstablishmentList = useSelector(
    (state) => state.OSHAEstablishmentList
  );
  const isLoader = useSelector(
    (state) =>
      state.OSHAEstablishmentList.isLoading ||
      state.updateEstablishmentStatus.isLoading
  );

  const updateEstablishmentStatusData = (data) => {
    dispatch(incidentAction.updateEstablishmentStatus(data));
  };
  const updateEstablishmentStatus = useSelector(
    (state) => state.updateEstablishmentStatus
  );

  useEffect(() => {
    setStatusShow(false);
    getOSHAEstablishmentListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      updateEstablishmentStatus?.status === "SUCCESS" &&
      updateEstablishmentStatus?.isLoading === false &&
      statusShow
    ) {
      toast("Status Updated successfully");
      getOSHAEstablishmentListData(defaultData);
    }
  }, [updateEstablishmentStatus]);

  return (
    <>
      {checkPrivileges([12, 152, 153, 154]) ? (
        <>
          {isLoader && <Loader />}
          <EstablishmentList
            isLoader={isLoader}
            itemsCount={OSHAEstablishmentList?.data?.itemsCount}
            establishmentList={
              isLoader ? [] : OSHAEstablishmentList?.data?.oshaEstablishmentlist
            }
            addEstablishment={addEstablishment}
            editEstablishment={editEstablishment}
            getOSHAEstablishmentListData={getOSHAEstablishmentListData}
            updateEstablishmentStatusData={updateEstablishmentStatusData}
            setStatusShow={setStatusShow}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default EstablishmentListContainer;
