import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import {
  PAGE_SIZE,
  GET_ITEM_LOG,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import FilterList from "./FilterIncidentList";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import {
  setColumnVisibilityModel1,
  columnVisibilityModel,
} from "../../../../utils/helper";

const ReprimandLog = ({
  userId,
  itemListData,
  isGetItemLogLoading,
  getItemLogReprimandData,
  handleViewOnClickReprimand,
  filterShow,
  searchShow,
  getItemLogAllData,
  statusIds,
  setStatusIds,
  isSafetyLog = false,
  usersId,
  filterButtonShow = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [incSearchText, setIncSearchText] = useState("");
  const [assetTypeId, setAssetTypeId] = useState();
  const [originId, setOriginId] = useState();

  const intialstate = {
    userId: userId,
    search: "",
    page: 1,
    pageSize: pageSize,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.Reprimand,
    statusIds: statusIds ? statusIds : "",
    fromDate: "",
    toDate: "",
    status: "",
    origin: "",
    assetType: "",
  };
  const [allOpenitemFilterData, setAllOpenItemFilterData] =
    useState(intialstate);

  const handleautoSearch = (event) => {
    let search = "";
    if (event.target.value) {
      search = event.target.value;
    }
    setIncSearchText(search);
    getItemLogReprimandData({
      ...allOpenitemFilterData,
      search: event.target.value,
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    {
      field: "id",
      headerName: "Reprimand ID",
      flex: 1,
      sortable: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "origin",
      headerName: "Origin",
      flex: 1,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "itemType",
      headerName: "Item Type",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.assetType === 0
              ? "NA"
              : rows?.row?.assetType === 1
              ? "Project"
              : rows?.row?.assetType === 3
              ? "Vehicle"
              : rows?.row?.assetType === 2
              ? "Equipment"
              : "Custom asset"}
          </>
        );
      },
      type: "text",
      flex: 1.2,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "jobsite",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "inspectionTitle",
      headerName: "Inspection Title",
      flex: 1.5,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "subCategory",
      headerName: "Sub-Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "questions",
      headerName: "Questions",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "location",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "involvedPartyName",
      headerName: "Involved Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "assignedToName",
      headerName: "Corrective Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "addedBy",
      headerName: "Inspected by",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "dateOfEntry",
      headerName: "Date of Entry",
      flex: 1.5,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 75,
      sortable: true,
      filterable: false,
    },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={itemListData?.result?.totalnoOfRecords ?? 0}
            numberOfItemsPerPage={PAGE_SIZE}
            incidentLists={itemListData?.result?.logsData ?? []}
            fileNameXL={"reprimand-log-list"}
          />
        ) : (
          ""
        ),
      // flex: 1,
      width: 70,
      minWidth: 70,
      maxWidth: 70,
      sortable: false,
      disableClickEventBubbling: true,
      minWidth: 60,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClickReprimand(
                row?.id,
                row?.origin,
                row?.itemType,
                row?.status,
                row?.addedById,
                row?.involvedPartyName,
                row?.involvedPartyId
              )
            }
            view={checkPrivileges([117, 118, 119, 12])}
            viewLabel="View Reprimand"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getItemLogReprimandData({
      userId: isSafetyLog ? usersId : userId,
      search: "",
      page: newPage + 1,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Reprimand,
      statusIds: isSafetyLog ? " " : statusIds,
      fromDate: "",
      toDate: "",
      status: "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getItemLogReprimandData({
      userId: isSafetyLog ? usersId : userId,
      search: "",
      page: page,
      pageSize: newPazeSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Reprimand,
      statusIds: isSafetyLog ? " " : statusIds,
      fromDate: "",
      toDate: "",
      status: "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
    });
  };

  const onRowClick = (rows) => {
    if (rows.value && checkPrivileges([117, 118, 119, 12])) {
      const { row } = rows;

      handleViewOnClickReprimand(
        row?.id,
        row?.origin,
        row?.itemType,
        row?.status,
        row?.addedById,
        row?.involvedPartyName,
        row?.involvedPartyId
      );
    }
  };
  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };
  const resetData = () => {
    setAssetTypeId();
    setOriginId();
    setAnchorEl(null);
    setStatusIds(2);
    getItemLogAllData({
      ...intialstate,
      page: 1,
      statusIds: 2,
      status: 2,
    });
  };

  const handleSearchFilter = (event, state) => {
    setAnchorEl(null);
    setAllOpenItemFilterData(state);
    setStatusIds(state.status);
    const data = {
      userId: userId,
      search: incSearchText,
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Reprimand,
      statusIds: state.status,
      status: state.status,
      fromDate: state.startDate,
      toDate: state.endDate,
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
    };
    getItemLogReprimandData(data);
  };

  var retrievedObjectdata = JSON.parse(
    localStorage.getItem("columnVisibilityReprimnd")
  );
  const typeOnAssetChanges = (e) => {
    setAssetTypeId(e?.target?.value);
  };

  const typeOnOriginChanges = (e) => {
    setOriginId(e?.target?.value);
  };
  return (
    <div className="grid_main_body">
      <div className="filter_of_caselist"></div>
      <div className="desc_box row_uniq">
        {searchShow ? (
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={incSearchText}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            {filterButtonShow && (
              <div className="filter_btn">
                <button
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClose(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  resetFilterAPI={resetData}
                  handleSearch={(event, state) =>
                    handleSearchFilter(event, state)
                  }
                  filterType={[]}
                  itemTypes={GET_ITEM_LOG.Reprimand}
                  assetTypes={true}
                  assetTypeId={assetTypeId}
                  typeOnAssetChanges={typeOnAssetChanges}
                  origin={true}
                  originId={originId}
                  typeOnOriginChanges={typeOnOriginChanges}
                  bothRecords
                />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="table_style_ui">
          <GridTable
            getRowId={(r) =>
              r?.id +
              "|" +
              r?.itemType +
              "|" +
              Date.now() +
              Math.floor(Math.random() * 100)
            }
            rows={itemListData?.result?.logsData ?? []}
            rowCount={itemListData?.result?.totalnoOfRecords ?? 0}
            columns={columns}
            onClick={(rows) => onRowClick(rows)}
            loading={isGetItemLogLoading}
            page={page}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={ROW_PER_PAGE_SIZE}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePagignation}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            disableColumnMenu={false}
            disableColumnSelector={false}
            disableColumnFilter={false}
            initialState={{
              columns: {
                columnVisibilityModel: retrievedObjectdata
                  ? retrievedObjectdata
                  : columnVisibilityModel,
              },
            }}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel1(newModel, "columnVisibilityReprimnd")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ReprimandLog;
