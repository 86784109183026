import React, { useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";
import ReactDOM from "react-dom";
import QrCodeLogo from "../../../../assets/QrCodeLogo.svg";
import { getApiUrl } from "../../../../config";
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import { toast } from "react-toastify";

const QrCodeDownload = React.forwardRef(
  ({ projectData = [], renderScanner = false }, ref) => {
    const redirectUrl = getApiUrl().qrCodeScannerUrl;

    useEffect(() => {
      if (ref) {
        ref.current = downloadQRCode;
      }
    }, [ref]);

    // Function to create QR Code image (PNG)
    const createQRCodeImage = async (qrUrl) => {
      const container = document.createElement("div");
      const qrCodeElement = <QRCodeCanvas value={qrUrl} size={256} level="H" />;
      ReactDOM.render(qrCodeElement, container);

      const qrCodeCanvas = container.querySelector("canvas");

      return new Promise((resolve) => {
        setTimeout(() => {
          const canvasWithLogo = document.createElement("canvas");
          const context = canvasWithLogo.getContext("2d");
          canvasWithLogo.width = qrCodeCanvas.width;
          canvasWithLogo.height = qrCodeCanvas.height;
          context.drawImage(qrCodeCanvas, 0, 0);

          const logo = new Image();
          logo.src = QrCodeLogo;
          logo.onload = () => {
            const logoSize = qrCodeCanvas.width * 0.2;
            const padding = logoSize * 0.1;
            const backgroundSize = logoSize + padding * 2;
            const x = (canvasWithLogo.width - backgroundSize) / 2;
            const y = (canvasWithLogo.height - backgroundSize) / 2;
            context.fillStyle = "#ffffff";
            context.fillRect(x, y, backgroundSize, backgroundSize);

            const logoX = x + padding;
            const logoY = y + padding;
            context.drawImage(logo, logoX, logoY, logoSize, logoSize);

            resolve(canvasWithLogo.toDataURL("image/png"));
          };

          logo.onerror = () => {
            console.error("Failed to load logo.");
            resolve(qrCodeCanvas.toDataURL("image/png"));
          };
        }, 500);
      });
    };

    // Function to create PDF
    const createPDF = async (projectData) => {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;

      const shiftLeft = 35;
      const heading = "Access Site Documents";
      const headingWidth = doc.getTextWidth(heading);
      const headingX = (pageWidth - headingWidth) / 2 - shiftLeft;

      // Add Heading
      doc.setFontSize(38);
      doc.text(heading, headingX, 30);

      // Generate QR Code Image
      const qrUrl = `${redirectUrl}?data=${encodeURIComponent(
        JSON.stringify(projectData)
      )}`;
      const qrImage = await createQRCodeImage(qrUrl);

      // Add QR Code to PDF
      doc.addImage(qrImage, "PNG", 60, 40, 100, 100);

      const shiftRight = 20;
      const reduceTopMargin = -15;

      // Add Project Details
      doc.setFontSize(12);
      doc.setFont(undefined, "bold");
      doc.text(
        `HB NEXT Project Number:`,
        20 + shiftRight,
        170 + reduceTopMargin
      );
      doc.text(`Project Name:`, 20 + shiftRight, 180 + reduceTopMargin);
      doc.text(`Client Name:`, 20 + shiftRight, 190 + reduceTopMargin);

      doc.setFont(undefined, "normal");
      doc.text(
        `${projectData?.projectNumber || ""}`,
        75 + shiftRight,
        170 + reduceTopMargin
      );
      doc.text(
        `${projectData?.projectName || ""}`,
        50 + shiftRight,
        180 + reduceTopMargin
      );
      doc.text(
        `${projectData?.clientname || ""}`,
        47 + shiftRight,
        190 + reduceTopMargin
      );

      // Add Contact Information
      doc.text(
        `${"For further information, call 770-619-1669."}`,
        47 + shiftRight,
        225 + reduceTopMargin
      );

      return doc.output("arraybuffer");
    };

    // Function to download QR Code (PDF or PNG)
    const downloadQRCode = async (projects, format) => {
      if (!projects?.length) return;

      const validProjects = projects.filter((project) => project.projectNumber);
      const invalidProjects = projects.filter(
        (project) => !project.projectNumber
      );

      if (invalidProjects.length > 0) {
        const missingIds = invalidProjects
          .map((project) => project.id)
          .join(", ");
        if (projects.length === 1) {
          toast.error(
            "QR Codes cannot be downloaded because of missing project number."
          );
        } else {
          toast.error(
            `QR code for Project ID(s) ${missingIds} has not been downloaded because of the missing project number.`
          );
        }
      }

      if (projects.length === 1) {
        const singleProject = projects[0];
        if (singleProject.projectNumber) {
          if (format === "pdf") {
            const pdf = await createPDF(singleProject);
            const blob = new Blob([pdf], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${singleProject.projectNumber} - ${singleProject.projectName}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
          } else if (format === "png") {
            const qrUrl = `${redirectUrl}?data=${encodeURIComponent(
              JSON.stringify(singleProject)
            )}`;
            const png = await createQRCodeImage(qrUrl);
            const a = document.createElement("a");
            a.href = png;
            a.download = `${singleProject.projectNumber} - ${singleProject.projectName}.png`;
            a.click();
          }
        }
        return;
      }

      if (validProjects.length) {
        const zip = new JSZip();
        await Promise.all(
          validProjects.map(async (project) => {
            const qrUrl = `${redirectUrl}?data=${encodeURIComponent(
              JSON.stringify(project)
            )}`;
            if (format === "pdf") {
              const pdf = await createPDF(project);
              zip.file(
                `${project.projectNumber} - ${project.projectName}.pdf`,
                pdf
              );
            } else if (format === "png") {
              const png = await createQRCodeImage(qrUrl);
              zip.file(
                `${project.projectNumber} - ${project.projectName}.png`,
                png.split(",")[1],
                { base64: true }
              );
            }
          })
        );

        zip.generateAsync({ type: "blob" }).then((content) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(content);
          const now = new Date();
          const formattedDate = `${
            now.getMonth() + 1
          }-${now.getDate()}-${now.getFullYear()}`;
          const formattedTime = now
            .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
            .replace(":", "-");

          const fileFormat = format === "pdf" ? "PDFs" : "PNGs";
          const zipFilename = `Sequence XT QR codes (${fileFormat}) ${formattedDate}_${formattedTime}.zip`;
          a.download = zipFilename;
          a.click();
          URL.revokeObjectURL(a.href);
        });
      }
    };

    if (renderScanner) {
      const normalizedData = Array.isArray(projectData)
        ? projectData[0]
        : projectData;

      const qrUrl = `${redirectUrl}?data=${encodeURIComponent(
        JSON.stringify(normalizedData)
      )}`;

      return (
        <QRCodeCanvas
          value={qrUrl}
          size={125}
          level="H"
          imageSettings={{
            src: QrCodeLogo,
            height: 30,
            width: 30,
            excavate: true,
          }}
        />
      );
    }

    return null;
  }
);

export default QrCodeDownload;
