import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_CATEGORY_INCIDENT,
  CASEINCIDENTS,
  DASHBOARD,
  SETUP_INSPECTION_LIST,
  SW_PERMIT_LIST,
} from "../../../constant/routeContant";
import "./successScreen.scss";
import commonImages from "../../../assets";
export default function SuccessScreen({
  route,
  id,
  isUpdate,
  label1,
  label2,
  label3,
  label4,
  isImg,
  isClass,
  caseId = null,
  projectData = null,
  isEditModeThroughViewCase = false,
  inspectionData = null,
  establishmentData = null,
}) {
  const [seconds, setSeconds] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      if (caseId && isEditModeThroughViewCase) {
        navigate(CASEINCIDENTS, {
          state: { caseId: caseId },
        });
      } else {
        navigate(route ? route : DASHBOARD);
      }
      if (projectData) {
        navigate(SW_PERMIT_LIST, {
          state: {
            projectid: projectData?.projectid,
            projectName: projectData?.projectName,
            projectNumber: projectData?.projectNumber,
            stateId: projectData?.stateId,
          },
        });
      }
      if (inspectionData) {
        navigate(SETUP_INSPECTION_LIST, {
          state: {
            projectId: inspectionData?.projectId,
            projectName: inspectionData?.projectName,
            customerName: inspectionData?.customerName,
            customerId: inspectionData?.customerId,
          },
        });
      }
      if (establishmentData) {
        navigate(ADMIN_CATEGORY_INCIDENT, {
          state: {
            flag: establishmentData?.flag,
          },
        });
      }
    }
  });

  return (
    <div
      className={isClass ? isClass + " successScreenBody" : "successScreenBody"}
    >
      <div className="iconSuccess">
        <img
          src={isImg ? isImg : commonImages.successCheck}
          className="succesImg"
          alt=""
        />
      </div>
      {isUpdate && <div className="heading">{isUpdate}</div>}
      <div className="report">{label1 ? label1 : "Report ID"}</div>
      <div className="CNU">{id}</div>
      <div className="generated">{label2 ? label2 : "has been generated"}</div>
      <div className="redirect">
        {label3 ? label3 : "You will be redirected to"} <br></br>
        {label4 ? label4 : " Home Screen in"} <span>{seconds} second</span>
      </div>
    </div>
  );
}
