import { createContext, useMemo, useReducer, useState, useContext, useEffect } from "react"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DARK, LIGHT } from "./constant/constant";

export const ThemeContext = createContext()

const INITIAL_STATE = { darkMode: false }

const themeReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return { darkMode: !state.darkMode }
    default:
      return state
  }
}

export const ThemeProviderComponent = (props) => {
  const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE)
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode ? savedMode : LIGHT;
  });

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === LIGHT ? DARK : LIGHT));
  };
  
  return (
    <ThemeContext.Provider value={{ state, dispatch, mode, toggleTheme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => useContext(ThemeContext);