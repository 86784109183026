import { InspectionInvolvedPartyOptionData } from "../constant/inspectionConstant";
// create qetsion set json dynamically
export const questSetJson = (data) => {
  return (
    data &&
    data.map((item) => {
      const items = { ...item };
      const subCategories = item.subcategories.map((subItem) => {
        const subItems = { ...subItem };
        const quest = subItem.questions.map((qestItem) => {
          const qestItems = { ...qestItem };
          const dataForm = {
            acceptable: [
              {
                id: Date.now() + Math.floor(Math.random() * 100),
                comment: "",
                media: [],
                filess: [],
                imgUrl: [],
                involdevPaty: "",
                employee: [],
                subcontractor: [],
              },
            ],
            notacceptable: [
              {
                id: Date.now() + Math.floor(Math.random() * 100),
                comment: "",
                involdevPaty: "",
                employee: [],
                subcontractor: [],
                escalatetoreprimandIsChecked: "No",
                escalatetoreprimandComment: "",
                itemStatusIsChecked: "",
                itemStatusComment: "",
                itemStatusCommentUser: "",
                media: [],
                filess: [],
                imgUrl: "",
                empCount: [],
              },
            ],
            notapplicable: {},
          };
          qestItems.dataForm = dataForm;
          qestItems.isQuestionComplete = false;

          return qestItems;
        });
        subItems.questions = quest;
        subItems.howManyComplete = 0;
        subItems.subError = false;
        subItems.skip = { IsSubCategory: false };
        subItems.subComplete = false;
        subItems.percentDone = 0;
        return subItems;
      });
      items.subcategories = subCategories;
      items.catError = false;
      items.catogeryCompleted = 0;
      items.skip = { IsCategory: false };
      items.catogerysDone = false;
      return items;
    })
  );
};

export const questSetEditJson = (
  data,
  edit,
  editData,
  users,
  setQestSetData
) => {
  return (
    data &&
    data.map((item, index1) => {
      const perQuestionCat = 100 / item.subcategories?.length;
      const subCatObj = item?.subcategories?.map((sub, index2) => {
        return {
          value: sub.value,
          key: sub.key,
          howManyComplete: 0,
          subComplete: false,
          percentDone: 0,
          questions: sub.questions?.map((items, index3) => {
            return {
              key: items?.key,
              isSelected: items?.isSelected,
              value: items?.value,
              questionSequenceNumber: items?.questionSequenceNumber,
              dataForm: {
                acceptable: [
                  {
                    id: index3,
                    comment: "",
                    media: [],
                    filess: [],
                    imgUrl: [],
                    involdevPaty: "",
                    employee: [],
                    subcontractor: [],
                  },
                ],
                notacceptable: [
                  {
                    id: index3,
                    comment: "",
                    involdevPaty: "",
                    employee: [],
                    subcontractor: [],
                    escalatetoreprimandIsChecked: "No",
                    escalatetoreprimandComment: "",
                    itemStatusIsChecked: "",
                    itemStatusComment: "",
                    itemStatusCommentUser: "",
                    media: [],
                    filess: [],
                    imgUrl: "",
                    empCount: [],
                  },
                ],
                notapplicable: {},
              },
            };
          }),
        };
      });
      let itemEdit = [];
      if (Object.keys(editData).length > 0) {
        itemEdit = editData?.categoriesView.find((o) => o.key === item?.key);
      }
      const completedq = itemEdit?.subcategoriesView?.filter(
        (itqa) => !itqa.skipped
      );
      const dsdsd = {
        key: item?.key,
        value: item?.value,
        catogeryCompleted: perQuestionCat * completedq?.length,
        catogerysDone: false,
        subcategories: subCatObj,
        skip: { IsCategory: itemEdit?.skipped },
      };

      const items = { ...dsdsd };

      // const itemEdit =
      //   (Object.keys(editData).length > 0 &&
      //     editData?.categoriesView[index1]) ??
      //   [];

      // const itemEdit = [...new Set([...firstArray ,...secondArray])];

      const skipped =
        (Object.keys(editData).length > 0 && editData?.skippedReportView) ?? [];

      if (item?.key === (itemEdit && itemEdit?.key) && edit) {
        const subcateg =
          item &&
          item.subcategories?.map((subItem, ind) => {
            const dsdsdsdsd = subItem.questions?.map((ites, index) => {
              return {
                key: ites?.key,
                isSelected: ites?.isSelected,
                value: ites?.value,
                questionSequenceNumber: ites?.questionSequenceNumber,
                index: ites?.index,
                dataForm: {
                  acceptable: [
                    {
                      id: index,
                      comment: "",
                      media: [],
                      filess: [],
                      imgUrl: [],
                      involdevPaty: "",
                      employee: [],
                      subcontractor: [],
                    },
                  ],
                  notacceptable: [
                    {
                      id: index,
                      comment: "",
                      involdevPaty: "",
                      employee: [],
                      subcontractor: [],
                      escalatetoreprimandIsChecked: "No",
                      escalatetoreprimandComment: "",
                      itemStatusIsChecked: "",
                      itemStatusComment: "",
                      itemStatusCommentUser: "",
                      media: [],
                      filess: [],
                      imgUrl: "",
                      empCount: [],
                    },
                  ],
                  notapplicable: {},
                },
              };
            });

            let subItemEdit = [];
            if (Object.keys(editData).length > 0) {
              subItemEdit = itemEdit?.subcategoriesView.find(
                (o) => o.key === subItem.key
              );
            }
            const perQuestion = 100 / subItemEdit?.insQuesAnsReportView?.length;
            const perQuestionCom = subItemEdit?.insQuesAnsReportView?.filter(
              (subQues) => subQues.answerMstId
            );
            const subItemsObj = {
              key: subItem.key,
              value: subItem.value,
              howManyComplete: perQuestionCom?.length,
              subComplete: !subItemEdit?.skipped,
              percentDone: perQuestion * perQuestionCom?.length,
              skip: { IsSubCategory: subItemEdit?.skipped },
              questions: dsdsdsdsd,
            };
            const subItems = { ...subItemsObj };

            if (
              subItem.key &&
              subItemEdit &&
              !subItemEdit.skipped &&
              subItemEdit.key
            ) {
              const quest =
                subItem &&
                subItem?.questions?.map((que, i) => {
                  const ques = { ...que };
                  let queEdit = [];
                  if (Object.keys(subItemEdit).length > 0) {
                    queEdit =
                      subItemEdit &&
                      subItemEdit?.insQuesAnsReportView.find(
                        (o) => o.questionId === que.key
                      );
                  }

                  if (que?.key && queEdit?.questionId) {
                    ques.isQuestionComplete = queEdit?.answerMstId
                      ? true
                      : false;
                    let accept = [];
                    let notAccept = [];

                    queEdit?.reprimands?.forEach((items) => {
                      if (parseInt(queEdit?.answerMstId) === 1) {
                        accept.push({
                          id: Date.now() + Math.floor(Math.random() * 100),
                          comment: items?.comment,
                          media: items?.correctiveFiles?.map(
                            (ite) => ite.fileId
                          ),
                          filess: items?.correctiveFiles.map((file) => {
                            return {
                              filePath: file.filePath,
                              fileId: file.fileId,
                            };
                          }),
                          imgUrl: items?.correctiveFiles
                            ?.map((ite) => ite.filePath)
                            .join("|"),
                          involdevPaty:
                            InspectionInvolvedPartyOptionData[
                              items?.employee_type
                            ].value,
                          employee:
                            parseInt(items?.employee_type) === 0
                              ? items?.involved_Ids?.map((ite) => {
                                  return {
                                    key: ite?.key,
                                    value: ite?.value,
                                    count: ite?.count,
                                  };
                                })
                              : [],
                          subcontractor:
                            parseInt(items?.employee_type) === 1
                              ? items?.involved_Ids?.map((ite) => {
                                  return {
                                    key: ite?.key,
                                    value: ite?.value,
                                    count: ite?.count,
                                  };
                                })
                              : [],
                        });
                      } else if (parseInt(queEdit?.answerMstId) === 2) {
                        notAccept.push({
                          id: Date.now() + Math.floor(Math.random() * 100),
                          comment: items?.corrective_issue_dtl,
                          involdevPaty:
                            InspectionInvolvedPartyOptionData[
                              items?.employee_type
                            ]?.value,
                          employee:
                            parseInt(items?.employee_type) === 0
                              ? items?.involved_Ids?.map((ite) => {
                                  return {
                                    key: ite?.key,
                                    value: ite?.value,
                                    count: ite?.count,
                                  };
                                })
                              : [],
                          subcontractor:
                            parseInt(items?.employee_type) === 1
                              ? items?.involved_Ids?.map((ite) => {
                                  return {
                                    key: ite?.key,
                                    value: ite?.value,
                                    count: ite?.count,
                                  };
                                })
                              : [],
                          escalatetoreprimandIsChecked:
                            items?.escalate_reprimand === true ? "Yes" : "No",
                          escalatetoreprimandComment: items?.action_taken_dtl,
                          itemStatusIsChecked: items?.item_status,
                          itemStatusComment: items?.abatement_notes,
                          itemStatusCommentUser: {
                            key: items?.item_responsibility_id,
                            value: items?.item_responsibility_name,
                          },

                          media: items?.correctiveFiles?.map(
                            (ite) => ite.fileId
                          ),
                          filess: items?.correctiveFiles.map((file) => {
                            return {
                              filePath: file.filePath,
                              fileId: file.fileId,
                            };
                          }),
                          imgUrl: items?.correctiveFiles
                            ?.map((ite) => ite.filePath)
                            .join("|"),
                          empCount: items?.involved_Ids,
                        });
                      }
                    });

                    const dataForm = {
                      acceptable: accept,
                      notacceptable: notAccept,

                      notapplicable: {},
                      ...(queEdit.answerMstId && {
                        quesType: { id: queEdit.answerMstId - 1 ?? null },
                      }),
                    };

                    ques.dataForm = dataForm;

                    return ques;
                  }

                  return ques.dataForm;
                });
              if (Object.keys(skipped).length > 0) {
                const subCaterSkip =
                  skipped &&
                  skipped.filter(
                    (sk, i) => sk?.questCategorySubcategoryId === subItem?.key
                  )[0];
                if (
                  subCaterSkip &&
                  subCaterSkip?.questCategorySubcategoryId === subItem?.key &&
                  !subCaterSkip?.isCategory
                ) {
                  subItems.skip = {
                    IsSubCategory: true,
                  };
                  setQestSetData((prevState) =>
                    prevState?.map((editD) => {
                      const editDs = { ...editD };
                      const subcategories =
                        editD &&
                        editD.subcategories?.map((subD) => {
                          const subDs = { ...subD };
                          if (
                            subD.key ===
                            subCaterSkip?.questCategorySubcategoryId
                          ) {
                            subDs.skip = {
                              IsSubCategory: true,
                            };

                            return subDs;
                          }
                          return subDs;
                        });
                      editDs.subcategories = subcategories;
                      return editDs;
                    })
                  );
                }
              }

              subItems.questions = quest;
              return subItems;
            }

            return subItems;
          });
        if (Object.keys(skipped).length > 0) {
          const subCaterSkip =
            skipped &&
            skipped.filter(
              (sk) => sk?.questCategorySubcategoryId === item?.key
            )[0];
          if (
            subCaterSkip &&
            subCaterSkip?.questCategorySubcategoryId === item?.key &&
            subCaterSkip?.isCategory
          ) {
            items.skip = {
              IsCategory: true,
            };
            setQestSetData((prevState) =>
              prevState?.map((editD) => {
                const editDs = { ...editD };
                if (editD.key === subCaterSkip?.questCategorySubcategoryId) {
                  editDs.skip = {
                    IsCategory: true,
                  };
                }

                return editDs;
              })
            );
          }
        }

        items.subcategories = subcateg;
        return items;
      }

      return items;
    })
  );
};

// mody qestion set Json dynamically
export function responseQuestionSet(data, parent, keySub, keyChild, value) {
  return (
    data &&
    data.map((item) => {
      if (parent.itemKey === item.key && parent.summary && item.value) {
        const items = { ...item };
        const perQuestionCat = 100 / item.subcategories?.length;
        let questionCompleted = false;
        const subCategorModify = item.subcategories.map((itemSub) => {
          if (itemSub.key === keySub) {
            const itemsSub = { ...itemSub };
            const perQuestion = 100 / itemSub?.questions?.length;
            const questionsModify = itemSub.questions.map((questItem) => {
              if (questItem.key === keyChild) {
                const data = { ...questItem };
                questionCompleted = data.isQuestionComplete;
                data.isQuestionComplete = true;
                data.dataForm = { ...value };
                if ("optionError" in data) {
                  delete data.optionError;
                }
                return data;
              }
              return questItem;
            });
            itemsSub.questions = questionsModify;
            itemsSub.subComplete = true;
            itemsSub.howManyComplete = questionCompleted
              ? itemsSub.howManyComplete
              : itemsSub.howManyComplete + 1;
            itemsSub.percentDone = questionCompleted
              ? itemsSub.percentDone
              : perQuestion * itemsSub.howManyComplete;
            return itemsSub;
          }
          return itemSub;
        });
        const completedSub = subCategorModify.filter(
          (items) => items.subComplete
        );
        items.subcategories = subCategorModify;
        items.catogeryCompleted = perQuestionCat * completedSub?.length;
        return items;
      }
      return item;
    })
  );
}

// get form data from question set
export function getFormdata(data, keyParent, keySub, key) {
  const parentData = data && data.filter((item) => item.key === keyParent);
  if (parentData) {
    const subData =
      parentData &&
      parentData[0]?.subcategories?.filter((item) => item?.key === keySub);
    const questionsData =
      subData && subData[0]?.questions?.filter((item) => item?.key === key);
    const { dataForm } = (questionsData && questionsData[0]) ?? {};
    return dataForm;
  }
  return parentData;
}

// skip question data
export function skipQuestions(
  value,
  key,
  parentElementData,
  qestSetState,
  setQestSetData
) {
  const skipData = qestSetState.map((itemP) => {
    if (value === itemP.value && key === itemP.key) {
      const itemsP = { ...itemP };
      itemsP.skip = {
        IsCategory: !(itemsP?.skip?.IsCategory ?? false),
      };
      setQestSetData((prevState) =>
        prevState.map((item) => {
          if (item.key === key && item.value === value) {
            const items = { ...item };
            items.skip = {
              IsCategory: !(items?.skip?.IsCategory ?? false),
            };
            return items;
          }
          return item;
        })
      );
      return itemsP;
    } else {
      const itemsP = { ...itemP };
      if (!!!itemP?.skip?.IsCategory) {
        const subCat =
          itemP &&
          itemP.subcategories.map((itemS) => {
            if (value === itemS.value && key === itemS.key) {
              const itemsS = { ...itemS };
              itemsS.skip = {
                IsSubCategory: !(itemsS?.skip?.IsSubCategory ?? false),
              };
              setQestSetData((prevState) =>
                prevState.map((itemState) => {
                  if (
                    itemState.key === parentElementData.itemKey &&
                    itemState.value === parentElementData.summary
                  ) {
                    const itemsState = { ...itemState };
                    const qestSkipSub = itemState.subcategories.map(
                      (itemSubState) => {
                        if (
                          itemSubState.key === key &&
                          itemSubState.value === value
                        ) {
                          const itemsSubState = { ...itemSubState };
                          itemsSubState.skip = {
                            IsSubCategory: !(
                              itemsSubState?.skip?.IsSubCategory ?? false
                            ),
                          };
                          return itemsSubState;
                        }
                        return itemSubState;
                      }
                    );
                    itemsState.subcategories = qestSkipSub;
                    return itemsState;
                  }
                  return itemState;
                })
              );

              return itemsS;
            } else {
              return itemS;
            }
          });
        itemsP.subcategories = subCat;
        return itemsP;
      }
      return itemsP;
    }
  });
  return skipData;
}

// question answer validations
export const questionsAnswerValidation = (qestSetState, action) => {
  // add errors
  const errors =
    qestSetState &&
    qestSetState.map((item) => {
      if (
        item?.skip?.IsCategory === false ||
        item?.skip?.IsCategory === undefined
      ) {
        const items = { ...item };
        const subCateg = item.subcategories.map((sub) => {
          if (
            sub?.skip?.IsSubCategory === false ||
            sub?.skip?.IsSubCategory === undefined
          ) {
            const subCateg = { ...sub };
            const question = sub.questions.map((quest) => {
              const quests = { ...quest };
              if (
                Object.keys(quest.dataForm).length === 0 &&
                (action === "submit" || action === "update")
              ) {
                quests.optionError =
                  "It is must that 1 option be selected among them or skip the question";
                return quests;
              } else {
                if ("optionError" in quests) {
                  delete quests.optionError;
                }
                return quests;
              }
            });
            subCateg.questions = question;
            return subCateg;
          } else {
            const subCatss = { ...sub };
            const quess = sub.questions.map((que) => {
              const quesss = { ...que };
              if (quesss && quesss.optionError !== undefined) {
                delete quesss.optionError;
              }
              return quesss;
            });
            subCatss.questions = quess;
            return subCatss;
          }
        });
        items.subcategories = subCateg;
        return items;
      } else {
        const itemss = { ...item };
        const subelse = item.subcategories.map((sub) => {
          const subs = { ...sub };
          const questelse = sub.questions.map((ques) => {
            const quess = { ...ques };
            if (quess && quess.optionError) {
              delete quess.optionError;
            }
            return quess;
          });
          subs.questions = questelse;
          return subs;
        });
        itemss.subcategories = subelse;
        return itemss;
      }
    });
  const valid = isValidate(errors);
  return { valid, errors };
};

// question validate
function isValidate(errors) {
  const ss = errors.reduce((acc, item, inde) => {
    if (item?.subcategories) {
      acc.push([]);
      item?.subcategories.forEach((element, index) => {
        acc[inde].splice(index, 0, []);
        element.questions.map((ele, ind) => {
          if (ele.optionError) {
            acc[inde][index].splice(ind, 0, true);
            return acc;
          } else {
            acc[inde][index].splice(ind, 1);
            return acc;
          }
        });
        return acc;
      });
      return acc;
    }
    return acc;
  }, []);
  return !(ss.flat(Infinity).indexOf(true) === 0);
}

// common form validate
export const formValidation = (form, action) => {
  let errorKey = {};
  for (let key in form) {
    const errorKeys = { ...errorKey };
    if (!form[key] && (action === "submit" || action === "update")) {
      errorKeys[key] = "Please enter this required field.";
    } else {
      errorKeys[key] = "";
    }
    errorKey = errorKeys;
  }
  const commonFormValid =
    Object.values(errorKey).indexOf("Please enter this required field.") < 0;
  return { commonFormValid, errorKey };
};

export const questionsAnswerDetails = (set) => {
  return set.map((cat) => {
    return {
      isSkipped: cat.skip?.IsCategory,
      categoryId: cat.key,
      sequenceNumber: cat.sequenceNumber,
      listInspectionSubCategory: cat.subcategories.map((subCat) => {
        return {
          isSkipped: subCat.skip?.IsSubCategory,
          subCategoryId: subCat?.key,
          sequenceNumber: cat.sequenceNumber,
          listInspectionQuestionAnswer: subCat.questions.map((ques) => {
            const quesType =
              parseInt(ques?.dataForm?.quesType?.id) === 0
                ? ques?.dataForm?.acceptable
                : parseInt(ques?.dataForm?.quesType?.id) === 1
                ? ques?.dataForm?.notacceptable
                : [];
            const detailCheck =
              parseInt(ques?.dataForm?.quesType?.id) === 0 ||
              parseInt(ques?.dataForm?.quesType?.id) === 1;
            const accep = parseInt(ques?.dataForm?.quesType?.id) === 0;
            const notAccep = parseInt(ques?.dataForm?.quesType?.id) === 1;

            let involvedEmpStore =
              quesType?.employee?.length > 0
                ? quesType.employee
                : quesType?.subcontractor
                ? quesType?.subcontractor
                : null;

            let involvedEmpRe = [];
            if (involvedEmpStore) {
              involvedEmpRe = involvedEmpStore.map((empId) => {
                return {
                  involvedUser: parseInt(empId.key) ?? 0,
                };
              });
            } else {
              involvedEmpRe.push({
                involvedUser: null,
              });
            }

            return {
              questionId: ques?.key,
              answerMstId: Number.isNaN(
                Number(ques?.dataForm?.quesType?.id) + 1
              )
                ? null
                : ques?.dataForm?.quesType?.id + 1,
              sequenceId: ques?.index,

              listInspectionQuestionAnswerDet: detailCheck
                ? quesType?.map((detail) => {
                    const empCheck =
                      detail?.employee?.filter((item) => item.key !== "")
                        ?.length > 0
                        ? detail?.employee?.filter((item) => item.key !== "")
                        : detail?.subcontractor?.filter(
                            (item) => item.key !== ""
                          );

                    return {
                      comment: accep ? detail?.comment : "",
                      involvedPartyType:
                        notAccep || accep
                          ? detail?.involdevPaty === "Employee"
                            ? 0
                            : detail?.involdevPaty === "Sub-Contractor"
                            ? 1
                            : 2
                          : detail?.involdevPaty === "Employee"
                          ? 0
                          : detail?.involdevPaty === "Sub-Contractor"
                          ? 1
                          : 2
                          ? detail?.involdevPaty === "Employee"
                            ? 0
                            : detail?.involdevPaty === "Sub-Contractor"
                            ? 1
                            : 2
                          : null,
                      issue: notAccep ? detail?.comment : "",
                      isReprimand:
                        detail?.escalatetoreprimandIsChecked === "Yes"
                          ? true
                          : false ?? false,
                      actionTaken: detail?.escalatetoreprimandComment ?? "",
                      itemStatus:
                        detail?.itemStatusIsChecked === "Open" ? 2 : 3,
                      itemResponsibility: notAccep
                        ? detail?.itemStatusIsChecked === "Open"
                          ? parseInt(detail.itemStatusCommentUser?.key)
                          : 0
                        : null,
                      abatementNotes: notAccep ? detail?.itemStatusComment : "",
                      correctiveFiles: detail?.media ?? [0],
                      isCorrectiveFileAdded:
                        detail?.media?.length > 0 ? true : false ?? false,
                      // listInspectionInvolvedUsers: [
                      //   {
                      //     involvedUser: 0,
                      //   },
                      // ],

                      listInspectionInvolvedUsers: empCheck?.map((empkey) => {
                        return {
                          involvedUser: empkey?.key,
                        };
                      }),
                    };
                  })
                : [],
            };
          }),
        };
      }),
    };
  });
};
