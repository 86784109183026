import React, { useEffect } from "react";
import SequenceResources from "../component/sequenceResources";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as sequenceLandingAction from "../../redux/actions/sequenceLandingAction";
import { PAGE_SIZE } from "../../constant/constant";
import { useSelector } from "react-redux";
import Loader from "../../library/common/Loader";

const SequenceResourcesContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const assetTypeId = 5;

  const projectNumber = location?.state?.projectNumber;
  const projectName = location?.state?.projectName;
  const clientName = location?.state?.clientname;
  const projectId = location?.state?.id;

  const QrResourecListData = useSelector(
    (state) => state.qrSequenceLandingResourecListData?.data?.result
  );
  const QrResourecListDataLoading = useSelector(
    (state) => state.qrSequenceLandingResourecListData?.isLoading
  );
  const getSequenceLandingQrResource = (data) => {
    dispatch(sequenceLandingAction.getSequenceLandingResource(data));
  };

  useEffect(() => {
    const data = {
      assetId: projectId,
      assetTypeId: assetTypeId,
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
    };
    getSequenceLandingQrResource(data);
  }, []);
  return (
    <>
      {QrResourecListDataLoading && <Loader />}
      <SequenceResources
        projectNumber={projectNumber}
        projectName={projectName}
        clientName={clientName}
        QrResourecListData={QrResourecListData}
        getSequenceLandingQrResource={getSequenceLandingQrResource}
        projectId={projectId}
        assetTypeId={assetTypeId}
      />
    </>
  );
};

export default SequenceResourcesContainer;
