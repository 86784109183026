import React, { useEffect, useState } from "react";
import HeaderConfiguration from "../component/inspection/HeaderConfiguration";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ORG_ID } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import { sortAlphabetically } from "../../../utils/helper";

const HeaderConfigurationContainer = () => {
  const dispatch = useDispatch();
  const [clearInspectionTypeFieldsMap, setClearInspectionTypeFieldsMap] =
    useState(false);

  const defaultData = {
    organisationId: ORG_ID(),
    isActive: true,
  };
  const questionSetList = useSelector((state) => state.getQuestionSetList);
  const questionSetListData = (data) => {
    dispatch(incpectionAction.getQuestionSetList(data));
  };

  const inspectionFieldsMap = useSelector((state) => state.inspectionFieldsMap);
  const getInspectionFieldsMap = (data) => {
    dispatch(incpectionAction.getInspecionFieldsByQuestionSet(data));
  };

  const updateInspectionFieldsMap = useSelector(
    (state) => state.updateInspectionFieldsMap
  );

  const updateInspectionFieldsMapData = (data) => {
    setClearInspectionTypeFieldsMap(true);
    dispatch(incpectionAction.inspectionFieldsMap(data));
  };

  const isLoading = useSelector(
    (state) => state.inspectionFieldsMap?.isLoading
  );

  const showLoader = useSelector(
    (state) =>
      state.inspectionFieldsMap?.isLoading ||
      state.getQuestionSetList?.isLoading ||
      state.updateInspectionFieldsMap?.isLoading
  );

  useEffect(() => {
    questionSetListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      updateInspectionFieldsMap?.status === "SUCCESS" &&
      updateInspectionFieldsMap?.isLoading === false &&
      clearInspectionTypeFieldsMap
    ) {
      toast("Header Configuration Updated successfully");
    }
  }, [updateInspectionFieldsMap]);

  const sortedQuestionsSets = sortAlphabetically(
    questionSetList?.data?.questionsSets
  );


  return (
    <>
      {showLoader && <Loader />}

      <HeaderConfiguration
        inspectionFieldsMap={isLoading ? [] : inspectionFieldsMap?.data}
        getInspectionFieldsMap={getInspectionFieldsMap}
        questionSetListData={questionSetListData}
        questionSetList={sortedQuestionsSets}
        updateInspectionFieldsMapData={updateInspectionFieldsMapData}
        updateInspectionFieldsMap={updateInspectionFieldsMap}
        clearInspectionTypeFieldsMap={clearInspectionTypeFieldsMap}
      />
      <ToastContainer />
    </>
  );
};

export default HeaderConfigurationContainer;
