import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCase from "../components/addCase";
import * as incidentAction from "../../../redux/actions/incidentAction";
import Loader from "../../../library/common/Loader";
import * as caseAction from "../../../redux/actions/caseAction";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { CASES } from "../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import { logEvent } from "../../../utils/helper";

const AddCaseContainer = () => {
  const disptch = useDispatch();
  const location = useLocation();
  const addRoute = location?.state?.addCase ?? false;
  const caseId = location?.state?.caseId;
  const editMode = location?.state?.editMode;
  const isEditModeThroughViewCase = location?.state?.isEditModeThroughViewCase;

  const createCase = useSelector((state) => state.createdCase);
  const updatedCase = useSelector((state) => state.updatedCase);
  const incident = useSelector((state) => state.incident.data);
  
  const addCase = (data) => {
    setSuccessScreens(true);
    if (data?.caseId === 0) {
      disptch(caseAction.createCase(data));
    } else {
      disptch(caseAction.updateCase(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.createdCase?.isLoading ||
      state.updatedCase?.isLoading ||
      state?.viewCase?.isLoading ||
      state?.incident?.isLoading
  );
  const [successScreens, setSuccessScreens] = useState(false);

  const viewCaseData = useSelector((state) => state?.viewCase?.data);

  useEffect(() => {
    setSuccessScreens(false);
    disptch(caseAction.clearCase());
    if (caseId) {
      disptch(incidentAction.getCaseDetails(caseId));
    }
  }, [editMode]);

  const getIncidentData = () => {
    disptch(incidentAction.getIncidentData());
  };
  useEffect(()=>{
    getIncidentData();
  },[])

  const successScreen = (id, successMsg) => {
    logEvent( "Incient",editMode ? "Edit Case" : "Add Case")
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        caseId={caseId}
        isEditModeThroughViewCase={isEditModeThroughViewCase}
        route={location?.state?.route ? location?.state?.route : CASES}
        label1="Case ID"
        label4="Case in "
        routeProps={{
          route: location?.state?.route,
          routeText: location?.state?.routeText,
          establishmentId: location?.state?.establishmentId,
          year: location?.state?.year,
        }}
      ></SuccessScreen>
    );
  };
console.log('location', location);

  return (
    <>

      {createCase?.status === "SUCCESS" && createCase.data && successScreens ? (
        successScreen(createCase?.data?.caseId, "Case Created Successfully")
      ) : updatedCase.status === "SUCCESS" &&
        updatedCase.data &&
        successScreens ? (
        successScreen(updatedCase?.data?.caseId, "Case Updated Successfully")
      ) : (
        <>
          {showLoader ? (
            <Loader />
          ) : (
            <AddCase
              caseId={caseId}
              editMode={editMode}
              onSubmit={addCase}
              viewCaseData={editMode ? viewCaseData : ""}
              addRoute={addRoute}
              locationOfRecordData={incident?.locationOfRecord}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddCaseContainer;
