import React from "react";
import JhaActivities from "../component/jha/jhaActivities";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useSelector } from "react-redux";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../library/common/Loader";
import JHAServiceList from "../component/jha/jhaServiceList";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_JHA_LIST_SERVICE,
  ADMIN_JHA_SETUP,
} from "../../../constant/routeContant";

const JhaServiceListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    userId: 0, // parseInt(USER_ID()),
    assetType: "",
    frequencyId: "",
    statusId: "",
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const getJhaSetupList = (data) => {
    dispatch(jhaAction.getJhaSetupList(data));
  };
  useEffect(() => {
    dispatch(jhaAction.clearJha());
    dispatch(jhaAction.getJHAReferenceData());
    getJhaSetupList(formValue);
  }, []);

  function handleStatusChange(data) {
    let newData = {
      setupId: data.id,
      isActive: data.statusId === 0 ? false : true,
    };
    console.log(newData);
    dispatch(jhaAction.activeInactiveJHASetup(newData));
  }

  const jhaServiceList = useSelector((state) => state.getJhaSetupList);
  const getJHAReferenceData = useSelector((state) => state.getJHAReferenceData);
  const activeInactiveJHASetupData = useSelector(
    (state) => state.activeInactiveJHASetup
  );
  // console.log(jhaServiceList);
  const isLoading = useSelector(
    (state) =>
      state.getJhaSetupList?.isLoading ||
      state.activeInactiveJHASetup?.isLoading
  );

  useEffect(() => {
    if (
      activeInactiveJHASetupData.status === "SUCCESS" &&
      activeInactiveJHASetupData.isLoading === false
    ) {
      getJhaSetupList(formValue);
    }
  }, [activeInactiveJHASetupData]);

  const showLoader = useSelector(
    (state) =>
      state.addJhaActivityData?.isLoading || state.updateJhaActivity?.isLoading
  );
  function setupJHA() {
    navigate(ADMIN_JHA_SETUP);
  }
  function handleEditOnClick(data){
    
    navigate(ADMIN_JHA_SETUP, {
      state: {
        setupId:data.id,
        editMode:true
      },
    });
  }
  return (
    <div>
      {showLoader && <Loader />}
      {/* <ToastContainer /> */}
      <JHAServiceList
        jhaServiceList={jhaServiceList?.data?.result?.itemList ?? []}
        itemsCount={jhaServiceList?.data?.result?.itemsCount ?? 0}
        setupJHA={setupJHA}
        isLoading={isLoading}
        handleStatusChange={handleStatusChange}
        setFormValue={setFormValue}
        formValue={formValue}
        getJhaSetupList={getJhaSetupList}
        frequencyList={getJHAReferenceData?.data?.frequency ?? []}
        handleEditOnClick={handleEditOnClick}
      />
    </div>
  );
};

export default JhaServiceListContainer;
