import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ACCESS_DENIED, ADMIN_VEHICLES } from "../../../constant/routeContant";
import { API_STATUS, LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";
import AddVehicle from "../component/vehicles/addVehicle";
import * as vehicleAction from "../../../redux/actions/vehiclesAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import {
  notifyError,
  notifyErrorLicencePlateNumber,
} from "../../../library/custom/toastMessage/Toast";
import DownloadCsv from "../../download_file/DownloadCsv";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer } from "react-toastify";
export default function AddVehiclesContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const dowloadCsv = useRef();
  const navigate = useNavigate();

  const postAddVehicle = useSelector((state) => state.postAddVehicle);
  const addVehicleStatus = useSelector((state) => state.postAddVehicle.status);
  const updateVehicle = useSelector((state) => state.updateVehicle);

  const vehicleDetailsData = useSelector((state) => state?.getVehicleDetails);
  const dropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue
  );
  const isLoading =
    postAddVehicle?.isLoading ||
    updateVehicle.isLoading ||
    dropdownDataKeyValue?.isLoading ||
    vehicleDetailsData.isLoading;
  const [isSuccess, setIsSuccess] = useState(false);
  const updateVehicleStatus = useSelector(
    (state) => state.updateVehicle.status
  );
  useEffect(() => {
    setIsSuccess(false);
    dispatch(vehicleAction.clearVehicle());
  }, []);

  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const vehicleCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.vehicleCategories
  );

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;

  const onSubmit = (data) => {
    if (editMode) {
      setSetsubmit(true);
      dispatch(vehicleAction.updateVehicle(data));
      setIsSuccess(true);
    } else {
      setSetsubmit(true);
      dispatch(vehicleAction.postAddVehicle(data));
    }
  };
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [6, 11],
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const allUsers = useSelector((state) => state.allUsers);
  const allGroups = useSelector((state) => state.searchAllGroup);
  const isAutoSearchDataLoading = allUsers.isLoading || allGroups.isLoading;

  const [isUserData, setIsUserData] = useState([]);

  useEffect(() => {
    setIsUserData(allUsers?.data);
  }, [allUsers?.data]);

  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetId,
    assetType,
    priviledgeId
  ) {
    setIsUserData([]);
    dispatch(
      userAction.searchAllUsers(
        event.target.value,
        type,
        divisionId,
        assetId,
        assetType,
        priviledgeId
      )
    );
  }

  function handleAllGroupSearch(event, division) {
    dispatch(userAction.searchAllGroup(event.target.value, division));
  }

  const successScreens = (id, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Vehicle Updated Successfully"
            : "Vehicle Created Successfully"
        }
        route={ADMIN_VEHICLES}
        label1="Vehicle ID"
        label4="Vehicle in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  const vehicleId = location?.state?.id;
  const editMode = location?.state?.editMode;

  const getAssetDetails = () => {
    dispatch(vehicleAction.getVehicleDetails(vehicleId));
  };
  useEffect(() => {
    if (vehicleId) {
      getAssetDetails();
    }
  }, [vehicleId, editMode]);

  const [duplicateVehicle, setDuplicateVehicle] = useState("");
  const [setsubmit, setSetsubmit] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isSingle, setIsSingle] = useState(false);

  const ismultipleUpload = (isBulkUpload) => {
    setIsSingle(isBulkUpload);
  };

  useEffect(() => {
    if (postAddVehicle?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (postAddVehicle?.data?.records?.length <= 1 && !isSingle) {
        if (postAddVehicle?.data?.records?.[0]?.status === false && setsubmit) {
          notifyErrorLicencePlateNumber();
        }
        if (postAddVehicle?.data?.records?.[0]?.status === true && setsubmit) {
          setIsSuccess(true);
        }
      } else if (
        postAddVehicle?.data?.records?.length >= 1 &&
        isSingle &&
        setsubmit
      ) {
        setIsDownload(true);
        setTimeout(() => {
          setIsSuccess(true);
        }, 1000);
      }
    }
  }, [postAddVehicle]);

  useEffect(() => {
    if (postAddVehicle?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (postAddVehicle?.status === API_STATUS.SUCCESS) {
        const duplicateVehicleList = postAddVehicle?.data?.records?.filter(
          (veh) => !veh.status
        );
        if (duplicateVehicleList?.length >= 1 && isSingle) {
          setDuplicateVehicle(duplicateVehicleList);
        }
      }
    }
  }, [postAddVehicle]);

  useEffect(() => {
    if (duplicateVehicle && duplicateVehicle?.length >= 1) {
      if (isDownload) {
        dowloadCsv?.current?.link?.click();
      }
    }
  }, [duplicateVehicle]);

  useEffect(() => {
    if (updateVehicle?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      updateVehicle?.status === API_STATUS.SUCCESS &&
      updateVehicle?.data
    ) {
      const duplicateVehicleList = updateVehicle?.data?.records?.filter(
        (eqp) => !eqp.status
      );
      if (
        duplicateVehicleList?.length == 1 &&
        updateVehicle?.data?.records?.[0]?.status === false &&
        setsubmit
      ) {
        notifyErrorLicencePlateNumber();
      } else {
        setIsSuccess(true);
      }
    }
    return () => {
      setIsSuccess(false);
    };
  }, [updateVehicle]);

  const handleonfcs = () => {
    setIsUserData([]);
  };
  return (
    <>
      {checkPrivileges([12, 78, 79]) ? (
        <>
          {(addVehicleStatus === "SUCCESS" &&
            postAddVehicle?.data &&
            postAddVehicle?.data?.records?.[0]?.status === true) ||
          (postAddVehicle?.data?.records?.length >= 1 && isSuccess) ? (
            successScreens()
          ) : updateVehicleStatus === "SUCCESS" &&
            updateVehicle?.data?.records?.[0]?.status === true &&
            updateVehicle?.data?.records?.length == 1 &&
            isSuccess ? (
            successScreens()
          ) : (
            <>
              {isLoading && <Loader />}
              <div>
                <ToastContainer />
              </div>
              <AddVehicle
                onSubmit={onSubmit}
                division={divisionList}
                vehicleCategory={vehicleCategory}
                //  allUsers={allUsers?.data}
                allUsers={isUserData}
                handleAllUserSearch={handleAllUserSearch}
                isLoading={isAutoSearchDataLoading}
                userGroups={allGroups?.data?.groupList}
                editMode={editMode}
                vehicleDetailsData={editMode ? vehicleDetailsData?.data : ""}
                vehicleId={editMode ? vehicleId : ""}
                handleAllGroupSearch={handleAllGroupSearch}
                ismultipleUpload={ismultipleUpload}
                handleonfcs={handleonfcs}
              />
              <DownloadCsv
                ref={dowloadCsv}
                data={duplicateVehicle}
                filename={"Already Exist Vehicle List"}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
