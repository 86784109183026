import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CASEINCIDENTS } from "../../../../constant/routeContant";
import "./successScreen.scss";
import commonImages from "../../../../assets";
export default function SuccessScreen({ id, isUpdate, caseId, routeProps }) {
  const [seconds, setSeconds] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      if (routeProps?.route) {
        navigate(routeProps?.route, {
          state: { routeProps: routeProps },
        });
      } else {
        navigate(CASEINCIDENTS, {
          state: { caseId: caseId },
        });
      }
    }
  });

  return (
    <div className="successScreenBody">
      <div className="iconSuccess">
        <img
          src={commonImages.successCheck}
          // width="90px"
          // height="90px"
          className="succesImg"
          alt=""
        />
      </div>
      {/* Incident Report Submitted */}
      <div className="heading">{isUpdate}</div>
      <div className="report">Report ID</div>
      <div className="CNU">{id}</div>
      <div className="generated">has been generated</div>
      <div className="redirect">
        You will be redirected to <br></br> Case Screen in{" "}
        <span>{seconds} second</span>
      </div>
    </div>
  );
}
