export const equipmentsType = {
  EQUIMENTS_PAGE: "Equipments",
  GET_EQUIPMENT_LIST: "[GET_EQUIPMENT_LIST] Get Equipment List Data",
  GET_EQUIPMENT_LIST_SUCCESS:
    "[GET_EQUIPMENT_LIST_SUCCESS] Get Equipment List Data Successfull",
  GET_EQUIPMENT_LIST_FAILURE:
    "[GET_EQUIPMENT_LIST_FAILURE] Get Equipment List Data Failed",

  POST_CREATE_EQUIPMENT: "[POST_CREATE_EQUIPMENT]  Post Create Equipment",
  POST_CREATE_EQUIPMENT_SUCCESS:
    "[POST_CREATE_EQUIPMENT_SUCCESS] Post create Equipment Successfull",
  POST_CREATE_EQUIPMENT_FAILURE:
    "[POST_CREATE_EQUIPMENT_FAILURE] Post create Equipment Failed",
};

// delete equipment
export const deleteEquipmentType = {
  DELETE_EQUIPMENT_PENDING: "DELETE_EQUIPMENT",
  DELETE_EQUIPMENT_SUCCESS: "DELETE_EQUIPMENT_SUCCESS",
  DELETE_EQUIPMENT_FAILED: "DELETE_EQUIPMENT_FAILED",
  Equipment_Page: "Equipment Page",
};

//  equipment detail
export const getEquipmentDetailType = {
  EQUIPMENT_DETAIL_PENDING: "EQUIPMENT_DETAIL",
  EQUIPMENT_DETAIL_SUCCESS: "EQUIPMENT_DETAIL_SUCCESS",
  EQUIPMENT_DETAIL_FAILED: "EQUIPMENT_DETAIL_FAILED",
};

export const postEquipmentUpdateType = {
  UPDATE_EQUIPMENT_PENDING: "UPDATE_EQUIPMENT_PENDING",
  UPDATE_EQUIPMENT_SUCCESS: "UPDATE_EQUIPMENT_SUCCESS",
  UPDATE_EQUIPMENT_FAILED: "UPDATE_EQUIPMENT_FAILED",
  UPDATE_PAGE: "UpdatePage",
};

export const clearEqiupmentType = {
  CLEAR_EQUIPMENT: "[CLEAR_EQUIPMENT][REQUEST] Clear Equipment",
};
