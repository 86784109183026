import {
  equipmentsType,
  deleteEquipmentType,
  getEquipmentDetailType,
  postEquipmentUpdateType,
  clearEqiupmentType,
} from "../constants/equipmentType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "Equipment",
  isError: false,
  isLoading: false,
};

export const equipmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case equipmentsType.GET_EQUIPMENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case equipmentsType.GET_EQUIPMENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case equipmentsType.GET_EQUIPMENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case equipmentsType.POST_CREATE_EQUIPMENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case equipmentsType.POST_CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case equipmentsType.POST_CREATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearEqiupmentType.CLEAR_EQUIPMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const deleteEquipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteEquipmentType.DELETE_EQUIPMENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case deleteEquipmentType.DELETE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case deleteEquipmentType.DELETE_EQUIPMENT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearEqiupmentType.CLEAR_EQUIPMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const equipmentDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case getEquipmentDetailType.EQUIPMENT_DETAIL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getEquipmentDetailType.EQUIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getEquipmentDetailType.EQUIPMENT_DETAIL_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateEquipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case postEquipmentUpdateType.UPDATE_EQUIPMENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postEquipmentUpdateType.UPDATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postEquipmentUpdateType.UPDATE_EQUIPMENT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearEqiupmentType.CLEAR_EQUIPMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};
