export const sequenceLandingType = {
  /**Project Permit and sampling Types */
  GET_QR_PROJECT_PERMIT_PENDING:
    "[GET_QR_PROJECT_PERMIT_PENDING][REQUEST] Get Project Permit requested",
  GET_QR_PROJECT_PERMIT_SUCCESS:
    "[GET_QR_PROJECT_PERMIT_SUCCESS][RESPONSE] Get Project Permit Successfull",
  GET_QR_PROJECT_PERMIT_FAILURE:
    "[GET_QR_PROJECT_PERMIT_FAILURE][RESPONSE] Get Project Permit Failed",
  PROJECT_PERMIT_PAGE: "PROJECT_PERMIT_PAGE",

  GET_QR_PROJECT_PERMIT_DETAIL_PENDING:
    "[GET_QR_PROJECT_PERMIT_DETAIL_PENDING][REQUEST] Get Project Permit Detail requested",
  GET_QR_PROJECT_PERMIT_DETAIL_SUCCESS:
    "[GET_QR_PROJECT_PERMIT_DETAIL_SUCCESS][RESPONSE] Get Project Permit Detail Successfull",
  GET_QR_PROJECT_PERMIT_DETAIL_FAILURE:
    "[GET_QR_PROJECT_PERMIT_DETAIL_FAILURE][RESPONSE] Get Project Permit Detail Failed",

  GET_QR_SAMPLING_LOG_PENDING:
    "[GET_QR_SAMPLING_LOG_PENDING][REQUEST] Get Sampling Log requested",
  GET_QR_SAMPLING_LOG_SUCCESS:
    "[GET_QR_SAMPLING_LOG_SUCCESS][RESPONSE] Get Sampling Log Successfull",
  GET_QR_SAMPLING_LOG_FAILURE:
    "[GET_QR_SAMPLING_LOG_FAILURE][RESPONSE] Get Sampling Log Failed",

  /**Inspections Type */

  GET_QR_INSPECTION_REPORT_PENDING:
    "[GET_QR_INSPECTION_REPORT_PENDING][REQUEST] Get Inspection Report requested",
  GET_QR_INSPECTION_REPORT_SUCCESS:
    "[GET_QR_INSPECTION_REPORT_SUCCESS][RESPONSE] Get Inspection Report  Successfull",
  GET_QR_INSPECTION_REPORT_FAILURE:
    "[GET_QR_INSPECTION_REPORT_FAILURE][RESPONSE] Get Inspection Report  Failed",

  GET_QR_INSPECTION_REPORT_VIEW_PENDING:
    "[GET_QR_INSPECTION_REPORT_VIEW_PENDING][REQUEST] Get Inspection Report Detail requested",
  GET_QR_INSPECTION_REPORT_VIEW_SUCCESS:
    "[GET_QR_INSPECTION_REPORT_VIEW_SUCCESS][RESPONSE] Get Inspection Report Detail  Successfull",
  GET_QR_INSPECTION_REPORT_VIEW_FAILURE:
    "[GET_QR_INSPECTION_REPORT_VIEW_FAILURE][RESPONSE] Get Inspection Report Detail  Failed",

  GET_QR_INSPECTION_TYPE_FIELD_PENDING:
    "[GET_QR_INSPECTION_TYPE_FIELD_PENDING][REQUEST] Get Inspection Report Type Detail requested",
  GET_QR_INSPECTION_TYPE_FIELD_SUCCESS:
    "[GET_QR_INSPECTION_TYPE_FIELD_SUCCESS][RESPONSE] Get Inspection Report Type Detail  Successfull",
  GET_QR_INSPECTION_TYPE_FIELD_FAILURE:
    "[GET_QR_INSPECTION_TYPE_FIELD_FAILURE][RESPONSE] Get Inspection Report Type Detail  Failed",

  QR_INSPECTION_REPORT_PAGE: "QR_INSPECTION_REPORT_PAGE",

  /**Resource Type */

  GET_QR_RESOURCE_PENDING:
    "[GET_QR_RESOURCE_PENDING][REQUEST] Get Resource List requested",
  GET_QR_RESOURCE_SUCCESS:
    "[GET_QR_RESOURCE_SUCCESS][RESPONSE] Get Resource List  Successfull",
  GET_QR_RESOURCE_FAILURE:
    "[GET_QR_RESOURCE_FAILURE][RESPONSE] Get Resource List  Failed",

  GET_QR_RESOURCE_VIEW_PENDING:
    "[GET_QR_RESOURCE_VIEW_PENDING][REQUEST] Get Resource view Detail requested",
  GET_QR_RESOURCE_VIEW_SUCCESS:
    "[GET_QR_RESOURCE_VIEW_SUCCESS][RESPONSE] Get Resource view Detail  Successfull",
  GET_QR_RESOURCE_VIEW_FAILURE:
    "[GET_QR_RESOURCE_VIEW_FAILURE][RESPONSE] Get Resource view Detail  Failed",
  QR_RESOURCE_REPORT_PAGE: "QR_RESOURCE_REPORT_PAGE",
};
