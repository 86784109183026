import {
  SIGNIFICAT_DEVIATION,
  TIME_LIMIT_FOR_GROUND_COVER,
  YES_NO,
} from "../constant/constant";

const generateUniqueId = () => {
  const date = new Date();
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.getTime()}-${Math.floor(Math.random() * 1000)}`;
};

export const generateInspectionGroundStabilization = (numObjects) => {
  const newObjects = [];
  for (let i = 0; i < numObjects; i++) {
    newObjects.push({
      id: generateUniqueId(),
      area: "",
      dateVerified: "",
      timeLimit: null,
      groundCover: null,
      inspectionDate: "",
      describeAction: "",
      dateCorrecion: "",
    });
  }

  return newObjects;
};

export const generateSwInspectionObservation = (numObjects) => {
  const newObjects = [];
  for (let i = 0; i < numObjects; i++) {
    newObjects.push({
      id: generateUniqueId(),
      dischargeOutfall: "",
      proposedDimension: "",
      actualDimension: "",
      significantDeviation: null,
      anyIncreaseInStream: null,
      anyVisibleOil: null,
      anyVisibleErosion: null,
      anyVisibleSedimentation: null,
      recommendation: "",
    });
  }
  return newObjects;
};

export const generateSwInspectionLotDisturbance = (numObjects) => {
  const newObjects = [];

  for (let i = 0; i < numObjects; i++) {
    newObjects.push({
      id: generateUniqueId(),
      lotNumber: "",
      disturbanceDate: "",
      erosionControlDate: "",
      stabilizationDate: "",
    });
  }
  return newObjects;
};

export const processCategories = (categories) => {
  return categories?.map((x) => ({
    isSkipped: false,
    categoryId: x.key,
    sequenceNumber: x.sequenceNumber,
    listInspectionSubCategory: processSubCategories(
      x.listInspectionSubCategory
    ),
  }));
};

const processSubCategories = (subCategories) => {
  return subCategories?.map((y) => ({
    isSkipped: false,
    subCategoryId: y.key,
    sequenceNumber: y.sequenceNumber,
    listInspectionQuestionAnswer: processQuestionAnswers(
      y.listInspectionQuestionAnswer
    ),
  }));
};

const processQuestionAnswers = (questionAnswers) => {
  return questionAnswers.map((z) => ({
    sequenceId: z.quesNo,
    answerMstId: z.radioSelectiovalue
      ? parseInt(z.radioSelectiovalue)
      : z.responseType === 2
      ? 4
      : 0,
    questionId: parseInt(z.key),
    actualDimension: z?.actualDimensionValue,
    proposedDimension: z?.proposedDimensionValue,
    significantDeviation: z?.significantDeviationValue ?? 0,
    listInspectionQuestionAnswerDet: processQuestionDetails(
      z.notAcceptableList,
      z
    ),
  }));
};

const processQuestionDetails = (detailsList, z) => {
  if (z?.responseType === 2) {
    const detailItem = {
      details: z.textValueQuestion,
      itemFoundId: z.itemFoundKey || z.itemFoundKey || 0,
      listInspectionDetailsLocation: processLocationDetails(
        z.addLocatioList,
        z
      ),
    };
    return [detailItem];
  } else {
    return detailsList?.map((detail) => {
      const detailItem = {
        details: detail.itemDetails,
        itemFoundId: detail.itemFoundKey || z.itemFoundKey || 0,
        listInspectionDetailsLocation: processLocationDetails(
          detail.addLocatioList,
          z
        ),
      };
      return detailItem;
    });
  }
};

const processLocationDetails = (locationList, z) => {
  return locationList
    ?.map((location) => {
      if (
        location.textVaue !== undefined &&
        parseInt(z.radioSelectiovalue) === 2
      ) {
        return {
          location: location.textVaue,
          files: [
            {
              subrootId: 0,
              fileId: location.fileId ? parseInt(location.fileId) : 0,
            },
          ],
        };
      }
      return null;
    })
    .filter((item) => item !== null);
};

export const processRainfallData = (rainfallData) => {
  return rainfallData?.map((item) => {
    const newItem = { ...item };
    const fieldsToRemove = [
      "projectNumber",
      "projectName",
      "projectId",
      "precipId",
      "precipDateFormat",
      "precipDate",
      "precipAmt",
      "organisationName",
      "organisationId",
      "isRainfallAboveThreshold",
      "exceedance",
      "evenTrackPrecipAmt",
      "accountId",
    ];
    fieldsToRemove?.forEach((field) => {
      delete newItem[field];
    });

    return newItem;
  });
};

export const processProjectPhaseData = (projectPhaseData) => {
  return projectPhaseData?.map((item) => {
    const newItem = { ...item };
    const fieldsToRemove = ["value", "key"];
    fieldsToRemove?.forEach((field) => {
      delete newItem[field];
    });

    return newItem;
  });
};

export const listInspectionCategoryObject = (data) => {
  return data.questionSetCatDetails.map((categoryItem) => {
    return {
      key: categoryItem.key,
      value: categoryItem.value,
      catPercent: 0,
      howManyCat: 0,
      catAns: false,
      catError: false,
      sequenceNumber: categoryItem.sequenceNumber,
      listInspectionSubCategory: categoryItem.subcategories.map(
        (subCategoryItem) => {
          return {
            key: subCategoryItem.key,
            isAnswerCat: false,
            subCatError: false,
            howManySub: 0,
            value: subCategoryItem.value,
            subPercent: 0,
            sequenceNumber: subCategoryItem.sequenceNumber,

            showBth: subCategoryItem.questions.every(
              (findItem) =>
                findItem.responseType === 1 || findItem.responseType === 3
            ),
            listInspectionQuestionAnswer: subCategoryItem.questions
              .filter((x) => x.isSelected)
              .map((questionItem) => {
                return {
                  key: questionItem.key,
                  actualDimensionValue: "",
                  proposedDimensionValue: "",
                  significantDeviationValue: 0,
                  isAnswerSubCat: false,
                  quesNo: questionItem.quesNo,
                  value: questionItem.value,
                  options: questionItem.options,
                  responseType: questionItem.responseType,
                  questionSequenceNumber: questionItem.questionSequenceNumber,
                  radioSelectiovalue: "",
                  textValueQuestion: "",
                  notAcceptableList:
                    questionItem.responseType !== 2
                      ? [
                          {
                            id: Date.now() + Math.floor(Math.random() * 100),
                            itemFound: "",
                            itemDetails: "",
                            itemFoundValue: "",
                            itemFoundKey: "",
                            addLocationText: "",
                            addLocatioImg: "",
                            addLocatioImgId: "",
                            addLocatioList: [],
                          },
                        ]
                      : [],
                };
              }),
          };
        }
      ),
    };
  });
};

export const updateCategoryPercent = (category) => {
  const perQuestionCat = 100 / category?.listInspectionSubCategory?.length;
  const completedCat = category.listInspectionSubCategory.filter(
    (items) => items.isAnswerCat
  );
  category.catPercent = perQuestionCat * completedCat?.length;
  category.howManyCat = completedCat?.length;
  category.catAns = true;
};

export const updateSubCategoryPercent = (subCategory) => {
  const perQuestionSub =
    100 / subCategory?.listInspectionQuestionAnswer?.length;
  const completedSub = subCategory.listInspectionQuestionAnswer.filter(
    (items) => items.isAnswerSubCat
  );
  subCategory.subPercent = perQuestionSub * completedSub?.length;
  subCategory.howManySub = completedSub?.length;
};

export const updateQuestion = (
  question,
  targetValue,
  targetName,
  questionValue,
  event,
  setAcceptable
) => {
  if (targetName === "allAcceptable") {
    event.stopPropagation();
    question.isAnswerSubCat = true;
    question.radioSelectiovalue = "1";
  }
  if (targetName === "notApplicable") {
    event.stopPropagation();
    question.isAnswerSubCat = true;
    question.radioSelectiovalue = "3";
  }
  if (question?.key == questionValue?.key) {
    question.isAnswerSubCat = true;
    if (targetName === "radio") {
      question.radioSelectiovalue = targetValue;
      if (targetValue === "2") {
        setAcceptable(questionValue?.key);
      }
    }
    if (targetName === "questionV") {
      question.textValueQuestion = targetValue;
    }
    if (targetName == "appendFrom") {
      question.notAcceptableList.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        itemFound: "",
        itemDetails: "",
        itemFoundValue: "",
        itemFoundKey: "",
        addLocationText: "",
        addLocatioImg: "",
        addLocatioImgId: "",
        addLocatioList: [],
      });
    }
  }
};

export const validateInspectionCategories = (
  listInspectionCategory,
  setListInspectionCategory
) => {
  const listInspectionCategoryClone = [...listInspectionCategory];
  let isError = false;

  listInspectionCategoryClone.forEach((cat) => {
    if (!cat.catAns) {
      cat.catError = true;
      isError = true;
    } else {
      cat.catError = false;
    }
    cat.listInspectionSubCategory.forEach((sub) => {
      sub.listInspectionQuestionAnswer.forEach((question) => {
        if (question.responseType === 3) {
          if (question.actualDimensionValue === "") {
            question.actualDimensionValueError = true;
            isError = true;
          } else {
            question.actualDimensionValueError = false;
          }
          if (question.significantDeviationValue === 0) {
            question.significantDeviationValueError = true;
            isError = true;
          } else {
            question.significantDeviationValueError = false;
          }
          if (question.proposedDimensionValue === "") {
            question.proposedDimensionValueError = true;
            isError = true;
          } else {
            question.proposedDimensionValueError = false;
          }
        }

        if (parseInt(question.radioSelectiovalue) === 2) {
          question.notAcceptableList?.forEach((notAcc) => {
            if (!notAcc.itemFoundKey) {
              notAcc.itemFoundError = true;
              isError = true;
            } else {
              notAcc.itemFoundError = false;
            }

            if (notAcc.addLocatioList?.length === 0) {
              notAcc.addLocationDetailError = true;
              isError = true;
            } else {
              notAcc.addLocationDetailError = false;
            }
          });
        }
      });
    });
  });
  if (isError) {
    setListInspectionCategory(listInspectionCategoryClone);
  }
  return isError;
};

export const rainfallColumns = [
  { label: "Day/Date", key: "day", className: "" },
  { label: "Rain Amount (in.)", key: "rainfallAmt", className: "" },
  { label: "Initials", key: "initials", className: "" },
  { label: "Notes", key: "notes", className: "" },
];

export const phaseProjectColumns = [
  {
    label:
      "Phase of Grading (Construction) (Place a check in the box of the current project phase)",
    key: "optionName",
    className: "phaseProjectWidth",
  },
  { key: "isChecked", label: "" },
  { label: "Comments:", key: "comment", className: "" },
];

export const groundStabilizationColumns = [
  {
    label: "Area where temporary or permanent ground cover is required",
    key: "area",
    className: "",
  },
  {
    label: "Date verified land disturbance stopped or completed",
    key: "dateVerified",
    className: "",
  },
  {
    label: "Time Limit for Ground Cover 7 days or 14 days",
    key: "timeLimit",
    className: "",
  },
  {
    label: "Ground Cover Sufficient to Restrain Erosion?(Y/N)",
    key: "groundCover",
    className: "",
  },
  { label: "Inspection Date", key: "inspectionDate", className: "" },
  { label: "Describe Actions Needed", key: "describeAction", className: "" },
  { label: "Date Corrections Verified", key: "dateCorrecion", className: "" },
];

export const observationColumns = [
  {
    label: "Stormwater Discharge Outfall Identification",
    key: "dischargeOutfall",
    className: "",
  },
  {
    label: "Proposed Dimensions (ft)",
    key: "proposedDimension",
    className: "",
  },
  { label: "Actual Dimensions (ft)", key: "actualDimension", className: "" },
  {
    label: "Significant deviation from plan?",
    key: "significantDeviation",
    className: "",
  },
  {
    label: "Any Increase in Stream Turbidity from Discharge?",
    key: "anyIncreaseInStream",
    className: "",
  },
  {
    label:
      "Any visible oil sheen, floating or suspended solids or discoloration?",
    key: "anyVisibleOil",
    className: "",
  },
  {
    label: "Any Visible Erosion below SDO?",
    key: "anyVisibleErosion",
    className: "",
  },
  {
    label:
      "Any Visible Sedimentation in Streams, Wetlands or Outside Site Limits?",
    key: "anyVisibleSedimentation",
    className: "",
  },
  {
    label: "Additional Recommendations",
    key: "recommendation",
    className: "",
  },
];

export const lotDisturbanceColumns = [
  { label: "Lot Number", key: "lotNumber", className: "" },
  {
    label: "Date the lot disturbance is verified",
    key: "disturbanceDate",
    className: "",
  },
  {
    label: "Date erosion control installations are verified",
    key: "erosionControlDate",
    className: "",
  },
  {
    label: "Date lot permanent stabilization verified",
    key: "stabilizationDate",
    className: "",
  },
];
export const getValueByKey = (key) => {
  const result = YES_NO?.find((item) => item.key === key);
  if (result) {
    return result.value === 0 || result.value === null || result.value === ""
      ? "N/A"
      : result.value;
  }
  return key;
};

export const transformSoilType = (soilTypeId) => {
  if (soilTypeId === 6) return "Wet";
  if (soilTypeId === 7) return "Dry";
  if (soilTypeId === 8) return "Frozen";
  return "";
};

export const transformSampleTaken = (isSampleTaken) => {
  if (isSampleTaken === null) return null;
  return isSampleTaken ? "3" : "1";
};

export const transformProjectStatus = (projectStatus) => {
  return projectStatus ? "1" : "2";
};

export const transformCategoryData = (categoryData) => {
  return categoryData.map((category) => {
    category.categoryId = category.key;
    category.catAns = category?.subcategoriesView?.some(
      (itemss) => itemss.isAnswerGiven
    );
    const perQuestionCat = 100 / category?.subcategoriesView?.length;
    const completedCat = category.subcategoriesView?.filter(
      (items) => items.isAnswerGiven
    );
    category.catPercent = perQuestionCat * completedCat?.length;
    category.howManyCat = completedCat?.length;

    category.listInspectionSubCategory = category.subcategoriesView.map(
      (subcategory) => {
        subcategory.subCategoryId = subcategory.key;
        subcategory.showBth = subcategory?.insQuesAnsReportView?.some(
          (findItem) => (findItem?.answerMstId === 4 ? false : true)
        );
        subcategory.listInspectionQuestionAnswer =
          subcategory.insQuesAnsReportView.map((questions) => {
            questions.questionSequenceNumber = questions.sequenceId;
            questions.key = questions.questionId;
            questions.value = questions.question;
            questions.radioSelectiovalue = questions.answerMstId;
            questions.textValueQuestion =
              questions.detailsReportView[0]?.details;
            questions.actualDimensionValue = questions.actualDimension;
            questions.proposedDimensionValue = questions.proposedDimension;
            questions.significantDeviationValue =
              questions.significantDeviation ?? 0;
            questions.notAcceptableList =
              questions.responseType === 1 || questions.responseType === 3
                ? questions.detailsReportView?.length > 0
                  ? questions.detailsReportView.map((notAcc) => {
                      notAcc.id = Date.now() + Math.floor(Math.random() * 100);
                      notAcc.itemFoundKey = notAcc.itemFoundId;
                      notAcc.itemFoundValue = notAcc.itemFound;
                      notAcc.itemDetails = notAcc.details;
                      notAcc.addLocatioList = notAcc.locationsReportView?.map(
                        (locationV) => {
                          locationV.textVaue = locationV.location;
                          locationV.id = locationV.locationId;
                          locationV.imgUrl = locationV.files[0]?.filePath;
                          locationV.addLocatioImgId =
                            locationV.files[0]?.fileId;
                          return locationV;
                        }
                      );
                      return notAcc;
                    })
                  : []
                : [];
            return questions;
          });
        const perQuestionSub = 100 / subcategory?.insQuesAnsReportView?.length;
        const completedSub = subcategory.insQuesAnsReportView.filter(
          (items) => items.answerMstId
        );
        subcategory.subPercent = perQuestionSub * completedSub?.length;
        subcategory.howManySub = completedSub?.length;
        return subcategory;
      }
    );
    return category;
  });
};

export const getQuestionIdsFromCategories = (categories) => {
  return categories
    ?.map((category) =>
      category?.subcategoriesView?.flatMap((subcategory) =>
        subcategory?.insQuesAnsReportView?.map(
          (question) => question.questionId
        )
      )
    )
    .flat()
    .filter((id) => id !== undefined);
};

export const getDayByKey = (key) => {
  const result = TIME_LIMIT_FOR_GROUND_COVER?.find((item) => item.key === key);
  if (result) {
    if (parseInt(result.value) === 1) {
      return "7 days";
    } else if (parseInt(result.value) === 2) {
      return "14 days";
    }
    return result.value === 0 || result.value === null || result.value === ""
      ? "N/A"
      : result.value;
  } else {
    return "N/A";
  }
};

export const getSignificantDeviation = (key) => {
  const result = SIGNIFICAT_DEVIATION?.find(
    (item) => parseInt(item.key) === parseInt(key)
  );
  if (result) {
    return result.value === 0 || result.value === null || result.value === ""
      ? "N/A"
      : result.value;
  }
  return key;
};

export const validateSwInspectionLotDisturbance = (lotDisturbance) => {
  return lotDisturbance.filter((item) => {
    return (
      item.lotNumber &&
      (item.disturbanceDate !== null ||
        item.erosionControlDate !== null ||
        item.stabilizationDate !== null)
    );
  });
};

export const validateSwInspectionObservation = (swInspectionObservation) => {
  return swInspectionObservation.filter((item) => {
    return (
      item.dischargeOutfall ||
      item.proposedDimension ||
      item.actualDimension ||
      item.significantDeviation !== null ||
      item.anyIncreaseInStream !== null ||
      item.anyVisibleOil !== null ||
      item.anyVisibleErosion !== null ||
      item.anyVisibleSedimentation !== null ||
      item.recommendation
    );
  });
};

export const validateSwInspectionGroundStabilization = (
  swInspectionGroundStabilization
) => {
  return swInspectionGroundStabilization?.filter((item) => {
    return (
      item.area?.trim() !== "" ||
      item.dateVerified?.trim() !== "" ||
      item.timeLimit !== null ||
      item.groundCover !== null ||
      item.inspectionDate?.trim() !== "" ||
      item.describeAction?.trim() !== "" ||
      item.dateCorrecion?.trim() !== ""
    );
  });
};
