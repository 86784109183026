import React, { useState, useEffect } from "react";
import InspectionAcknowledgment from "../component/inspection/inspectionAcknowledgment";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { VIEW_MAPPING_ACKNOWLEDGMENT } from "../../../constant/routeContant";
const InspectionAcknowledgmentContainer = () => {
  const dispatch = useDispatch();
  const [checkStatus, setCheckStatus] = useState(1);
  const navigate = useNavigate();
  
  const viewMapping = (data) => {
    navigate(VIEW_MAPPING_ACKNOWLEDGMENT, {
      state: { acknowledgment: data },
    });
  };
  const defaultSubData = {
    type: 5,
  };

  const getInspectionAcknowledgement = useSelector(
    (state) => state.getInspectionAcknowledgement
  );
  const getInspectionAcknowledgementData = (data) => {
    dispatch(incpectionAction.getInspectionAcknowledgement(data));
  };

  const updateInspectionAcknowledgement = useSelector(
    (state) => state.updateInspectionAcknowledgement
  );

  const [clearInspectionAcknowledgement, setClearInspectionAcknowledgement] =
    useState(false);

  const showLoader = useSelector(
    (state) =>
      state.getInspectionAcknowledgement?.isLoading ||
      state.updateInspectionAcknowledgement?.isLoading
  );

  const onSubmit = (data) => {
    setClearInspectionAcknowledgement(true);
    dispatch(incpectionAction.updateInspectionAcknowledgement(data));
  };

  useEffect(() => {
    getInspectionAcknowledgementData(defaultSubData);
  }, []);

  useEffect(() => {
    if (
      updateInspectionAcknowledgement?.status === "SUCCESS" &&
      updateInspectionAcknowledgement?.isLoading === false &&
      clearInspectionAcknowledgement &&
      checkStatus === 1
    ) {
      toast("Inspection Acknowledgement Added successfully");
      getInspectionAcknowledgementData(defaultSubData);
    }

    if (
      updateInspectionAcknowledgement?.status === "SUCCESS" &&
      updateInspectionAcknowledgement?.isLoading === false &&
      clearInspectionAcknowledgement &&
      checkStatus === 2
    ) {
      toast("Inspection Acknowledgement Updated successfully");
      getInspectionAcknowledgementData(defaultSubData);
    }
  }, [updateInspectionAcknowledgement]);

  return (
    <>
      {showLoader && <Loader />}

      <InspectionAcknowledgment
        getInspectionAcknowledgement={getInspectionAcknowledgement}
        onSubmit={onSubmit}
        updateInspectionAcknowledgement={updateInspectionAcknowledgement}
        setCheckStatus={setCheckStatus}
        viewMapping={viewMapping}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionAcknowledgmentContainer;
