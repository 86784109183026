import React, { useState } from "react";
import "./qrcodelanding.scss";
import SeqHeader from "./seqHeader";
import GridTable from "../../library/custom/gridTable/GridTable";
import NORow from "../../library/custom/noRow/noRow";
import { TableLoader } from "../../library/common/Loader";
import { Grid } from "@mui/material";
import "./seqHeader.scss";
import ReportRowManipulte from "../../feature/incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../feature/incidents/incidentList/SettingTable";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../constant/constant";
import SearchIcon from "@mui/icons-material/Search";
import QrBackBtn from "../../library/custom/qrBackBtn/qrBackBtn";
import { QR_LANDING_PAGE_INSPECTION_VIEW } from "../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const SequenceInspectionReport = ({
  projectNumber,
  projectName,
  clientName,
  QrInspectionListData,
  getSequenceLandingQrInpection,
  isLoading,
  projectId,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isTableLoading, setisTableLoading] = useState(false);

  const handleOnViewclick = (val) => {
    navigate(QR_LANDING_PAGE_INSPECTION_VIEW, {
      state: { inspectionData: val?.row },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(QR_LANDING_PAGE_INSPECTION_VIEW, {
        state: { inspectionData: rows?.row },
      });
    }
  };

  const columns = [
    { field: "reportId", headerName: "Report ID", flex: 2, minWidth: 120 },
    { field: "projectName", headerName: "Project", flex: 2.5, minWidth: 120 },
    {
      field: "customer",
      headerName: "Client",
      flex: 2.5,
      minWidth: 120,
    },
    {
      field: "dateOfEntry",
      headerName: "Inspection Date",
      flex: 2.5,
      minWidth: 120,
    },
    {
      field: "inspectionType",
      headerName: "Inspection Type",
      flex: 2.5,
      minWidth: 120,
    },
    { field: "frequency", headerName: "Frequency", flex: 2.5, minWidth: 120 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={QrInspectionListData?.recordsCount ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={QrInspectionListData?.inspectionReports ?? []}
          fileNameXL={"Inspection_Reports_List"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={QrInspectionListData}
            viewOnClick={() => handleOnViewclick(rows)}
            download={false}
            view={true}
            viewLabel="View Report"
          />
        );
      },
    },
  ];

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      projectId: projectId,
      page: page,
      pageSize: pageSize,
      search: searchText ? searchText : "",
    };
    getSequenceLandingQrInpection(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getSequenceLandingQrInpection({
      page: page,
      pageSize: newPazeSize,
      search: search ? search : "",
      projectId: projectId,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getSequenceLandingQrInpection({
      page: newPage + 1,
      pageSize: pageSize,
      search: search ? search : "",
      projectId: projectId,
    });
  };
  return (
    <>
      <SeqHeader
        clientName={clientName}
        ProjectName={projectName}
        ProjectNumber={projectNumber}
      />
      <Grid className="login_message_qtcode_container">
        <div className="login_message_qrcode">
          To view data older than 90 days,{" "}
          <a href="/login" className="login-link_qrcode">
            Login
          </a>{" "}
          to SequenceXT
        </div>
      </Grid>

      <section className="grid_main_sec">
        <div className="gridMainpermit">
          <Grid className="permitBox" lg={12} mb={2}>
            <Grid className="grid_main_header two_grid" lg={12}>
              <Grid lg={8} className="grid_heading_style" space={2}>
                Inspection Reports
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={4} xs={12} sm={12} md={6} mb={3}>
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search Inspection Report"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
            </Grid>
          </Grid>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r?.reportId}
                  rows={QrInspectionListData?.inspectionReports ?? []}
                  rowCount={QrInspectionListData?.recordsCount ?? 0}
                  columns={columns}
                  // loading={isLoading}
                  page={1}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  onClick={(rows) => onRowClick(rows)}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
          <Grid container>
            <QrBackBtn text={"Back"} />
          </Grid>
        </div>
      </section>
    </>
  );
};

export default SequenceInspectionReport;
